import React from "react";
import "./ProgramHeader.css";
import ImageWithObjectFit from "../ImageWrapperComponent";
import parse from "html-react-parser";
import { useOpenLink } from "../../utils/useOpenLink";

function ProgramHeader({ contents: contentsArray, colors: colorObj }) {
    const openLink = useOpenLink();

    return (
        <>
            <section className="programme-std-wrapper py-50">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 p-0 px-lg-3 px-md-3 order-lg-2 order-md-2">
                            <div className="job-img">
                                <ImageWithObjectFit src={contentsArray[0]?.image.src} alt=" " />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="jobTxt-hldr">
                                <div
                                    className="prod-dev"
                                    style={{ "--accent_color3": colorObj?.accent_color3 }}
                                >
                                    {contentsArray[0]?.title}
                                </div>
                                <div className="job-title">
                                    <div
                                        className="line"
                                        style={{ "--primary_color": colorObj?.primary_color }}
                                    ></div>
                                    <h2 style={{ "--accent_color1": colorObj?.accent_color1 }}>
                                        {contentsArray[0]?.short_title}
                                    </h2>
                                </div>
                                <p style={{ "--accent_color1": colorObj?.accent_color1 }}>
                                    {parse(contentsArray[0]?.introduction)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section
                className="prog-link-wrapper my-30"
                style={{
                    "--accent_color2": colorObj?.accent_color2,
                    "--primary_color": colorObj?.primary_color,
                }}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="desktop-std-menu">
                                <ul>
                                    {contentsArray[0]?.menu[0]?.data.map((item, i) => (
                                        <li
                                            className="nav-item cursor-pointer"
                                            key={i}
                                            onClick={() =>
                                                openLink(item.content_reference, item.type)
                                            }
                                        >
                                            <span>{item.heading}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProgramHeader;
