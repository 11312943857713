import ImageWithObjectFit from "../ImageWrapperComponent";
import { decodeHtmlEntities } from "../../utils/urlUtils";
import { articleType, calculateReadTime } from "../../utils/articleHelpers";
import { useOpenLink } from "../../utils/useOpenLink";
import useTranslation from "../../utils/useTranslation";

export const RelatedCard2 = ({ content, colorsObj }) => {
    const { image, title: Title, tags: Tags, path, nid, microsite, link, posterImage } = content;

    const tags = Tags?.split(",")[0];

    const openLink = useOpenLink();

    const handleClick = () => {
        openLink(path, articleType, nid);
    };

    const { t } = useTranslation();

    return (
        <div className="newsCard card">
            <div className="cardImg">
                <ImageWithObjectFit
                    className="cursor-pointer"
                    src={link === "" || link === null ? image?.src : posterImage}
                    onClick={handleClick}
                />
            </div>
            <div className="card-body d-flex flex-column">
                <div className="tag-heading mb-2" style={{ color: colorsObj.primary_color }}>
                    {microsite !== "" ? decodeHtmlEntities(microsite) : <>&nbsp;</>}
                </div>
                <div
                    className="title hover:underline cursor-pointer"
                    onClick={handleClick}
                    style={{ color: colorsObj.accent_color1 }}
                >
                    {Title}
                </div>
                <div className="d-flex mt-auto">
                    {tags && (
                        <div className="author me-1" style={{ color: colorsObj.accent_color1 }}>
                            {tags}
                        </div>
                    )}
                    <div className="date" style={{ color: colorsObj.accent_color3 }}>
                        {tags && calculateReadTime(content) ? " | " : ""}
                        {calculateReadTime(content)
                            ? `${calculateReadTime(content)} ${t("constantString.minRead")}`
                            : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};
