import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { clearAuthState } from "../../redux/authSlice";

const LogoutPage = () => {
    const dispatch = useDispatch();
    dispatch(clearAuthState());

    return <Navigate to="/" />;
};

export default LogoutPage;
