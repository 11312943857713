import React from "react";
import { useNavigate } from "react-router-dom";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { decodeHtmlEntities } from "../../utils/urlUtils";
import moment from "moment";
import parse from "html-react-parser";
import { calculateReadTime } from "../../utils/articleHelpers";
import { useTranslation } from "react-i18next";

function FeaturedCard({ content, colorsObj }) {
    const navigate = useNavigate();

    const {
        image,
        title: Title,
        date,
        tags: Tags,
        nid,
        microsite,
        summary,
        link,
        posterImage,
    } = content;

    const tags = Tags?.split(",")[0];

    const handleClick = () => navigate(`/articles/${nid}`);
    const { t } = useTranslation();

    return (
        <div className="newsCard card">
            <div className="cardImg">
                <ImageWithObjectFit
                    className="cursor-pointer"
                    src={link === "" || link === null ? image?.src : posterImage}
                    onClick={handleClick}
                />
            </div>
            <div className="card-body d-flex flex-column">
                {microsite ? (
                    <div
                        className="tag-heading mb-2"
                        style={{
                            "--accent_color1": colorsObj?.accent_color1,
                            "--primary_color": colorsObj?.primary_color,
                        }}
                    >
                        {microsite !== "" ? decodeHtmlEntities(microsite) : <>&nbsp;</>}
                    </div>
                ) : (
                    <div>&nbsp;</div>
                )}

                <h3
                    className="underline d-flex mt-auto"
                    onClick={handleClick}
                    style={{ color: colorsObj?.accent_color1 }}
                >
                    {Title}
                </h3>
                <div
                    className="time"
                    style={{
                        "--accent_color1": colorsObj?.accent_color1,
                        "--primary_color": colorsObj?.primary_color,
                    }}
                >
                    <span>
                        {calculateReadTime(summary)
                            ? `${calculateReadTime(summary)} ${t("constantString.minRead")} `
                            : ""}
                    </span>
                    <span
                        className="line"
                        style={{ "--primary_color": colorsObj?.primary_color }}
                    ></span>
                    <span>{moment(date).fromNow()}</span>
                </div>
                <div
                    className="summary mt-3"
                    style={{ "--accent_color3": colorsObj?.accent_color3 }}
                >
                    {summary && parse(summary)}
                </div>
                <div className="d-flex mt-auto">
                    {tags && (
                        <div
                            className="hashtag me-1"
                            style={{
                                "--primary_color": colorsObj?.primary_color,
                                "--accent_color2": colorsObj?.accent_color2,
                            }}
                        >
                            {tags}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FeaturedCard;
