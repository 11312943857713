import React, { useCallback, useEffect, useState } from "react";
import { useOpenLink } from "../../utils/useOpenLink";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { blockSourceString } from "../../utils/string";
import { articleType } from "../../utils/articleHelpers";
import { APP_COLORS } from "../../constants/styles";
import Pill from "./Pill";

export const TextLinks = ({
    heading,
    body,
    contents: contentsArray,
    colors: colorObj,
    block_source,
}) => {
    const openLink = useOpenLink();

    const [contentReferenceData, setContentReferenceData] = useState([]);

    const contentFromStore = useSelector((state) => state.layout.content);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (contentFromStore[contentKey]) {
                const respKeys = contentFromStore[contentKey];
                let resp = respKeys.map((k) => contentFromStore[k]);
                resp = JSON.parse(JSON.stringify(resp));
                if (resp.length) {
                    setContentReferenceData(resp);
                }
            }
        },
        [contentFromStore],
    );

    useEffect(() => {
        if (contentsArray.length > 0) {
            setCurrentContent(contentsArray[0].contentKey);
        }
    }, []);

    const handleClick = (path, nid) => {
        openLink(path, articleType, nid);
    };

    return (
        <div style={APP_COLORS(colorObj)} className="bg-background_color py-28 container">
            <div className="flex flex-col text-accent_color1 mb-3 items-center md:items-start">
                <h1 className="text-2xl font-medium">{heading}</h1>
                <p className="mt-2">{parse(body)}</p>
            </div>

            <ul className="flex flex-row flex-wrap gap-x-6 gap-y-4 justify-center md:justify-start">
                {block_source !== blockSourceString.article_details
                    ? contentsArray.map((value) => (
                          <Pill
                              key={value.heading}
                              onClick={() => openLink(value.contentKey)}
                              text={value.heading}
                          />
                      ))
                    : contentReferenceData.map((value) => (
                          <Pill
                              key={value.title}
                              onClick={() => handleClick(value.path, value.nid)}
                              text={value.title}
                          />
                      ))}
            </ul>
        </div>
    );
};
