import moment from "moment";
import React from "react";
import parse from "html-react-parser";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { useOpenLink } from "../../utils/useOpenLink";
import { decodeHtmlEntities } from "../../utils/urlUtils";
import { articleType } from "../../utils/articleHelpers";
import useTranslation from "../../utils/useTranslation";

function TitleCard({ content, colors }) {
    const openLink = useOpenLink();

    const { image, microsite, title, author, date, summary, path, nid, link, posterImage } =
        content;

    const handleClick = () => {
        openLink(path, articleType, nid);
    };

    const { t } = useTranslation();

    return (
        <>
            <div className="boxMain card" style={{ background: colors?.background_color }}>
                <div
                    className="TitleMain underline"
                    style={{ color: colors?.accent_color1 }}
                    onClick={handleClick}
                >
                    {title}
                </div>
                <div className="row tileCardTxt">
                    <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                        <div className="ImgMain">
                            <ImageWithObjectFit
                                className="cursor-pointer"
                                src={
                                    link === "" || link === null || !link ? image?.src : posterImage
                                }
                                onClick={handleClick}
                            />
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                        <div className="BodyTxt">
                            <div className="date" style={{ color: colors?.primary_color }}>
                                {microsite !== "" ? decodeHtmlEntities(microsite) : ""}
                                {microsite && author ? " | " : ""}
                                {author}
                                {author && date ? " | " : ""}
                                {date ? moment(date).format(`MMM DD${","} YYYY`) : ""}
                            </div>
                            <p style={{ color: colors?.accent_color1 }}>
                                {summary ? parse(summary) : ""}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="ViewResource cursor-pointer" onClick={handleClick}>
                    <div className="col-12">
                        <span className="btn btn-link" style={{ color: colors?.primary_color }}>
                            {t("btnText.ViewResource")}
                        </span>
                        <i className="float-end">
                            <i
                                className="bi bi-arrow-right viewIconArrowIcon"
                                style={{
                                    "--primary_color": colors?.primary_color,
                                    "--accent_color4": colors?.accent_color4,
                                }}
                            ></i>
                        </i>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TitleCard;
