import React, { useEffect, useState } from "react";
import "./ProgrammeTemplate.css";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { Button } from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchArticle } from "../../redux/layoutSlice";
import { parseWithRemoveBrTagsFromBody } from "../../utils/articleHelpers";
import { useOpenLink } from "../../utils/useOpenLink";
import parse from "html-react-parser";
import { Accordion, AccordionTab } from "primereact/accordion";

function ProgrammeTemplate({ contents, colors: colorObj }) {
    const { articleId: articleIdFromParams } = useParams();

    const id = contents?.[0]?.id || articleIdFromParams;

    const dispatch = useDispatch();

    const openLink = useOpenLink();

    const [isLoading, setIsLoading] = useState(!!id);

    const [didDispatch, setDidDispatch] = useState(false);

    const content = useSelector((state) => state.layout.content);

    useEffect(() => {
        if (isLoading && !didDispatch) {
            dispatch(fetchArticle({ id }));
            setDidDispatch(true);
        }
        if (isLoading && content[id]) {
            setIsLoading(false);
        }
    }, [isLoading, content, didDispatch]);

    const {
        title,
        body,
        detailImage,
        introduction,
        themes,
        menu,
        program_heading,
        program_description,
        program_question_heading,
        program_question_description,
        button_1_link,
        button_1_title,
        button_2_link,
        button_2_title,
    } = content[id] || {};

    const theme = themes && JSON.parse(themes);

    return (
        <>
            <section className="programme-std-wrapper py-50">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 p-0 px-lg-3 px-md-3 order-lg-2 order-md-2">
                            {detailImage?.src && (
                                <div className="job-img">
                                    <ImageWithObjectFit src={detailImage?.src} alt=" " />
                                </div>
                            )}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="jobTxt-hldr">
                                {theme?.map((t, i) => (
                                    <div
                                        className="prod-dev"
                                        key={i}
                                        style={{ "--accent_color3": colorObj?.accent_color3 }}
                                    >
                                        {t.theme}
                                    </div>
                                ))}
                                <div className="job-title">
                                    <div
                                        className="line"
                                        style={{ "--primary_color": colorObj?.primary_color }}
                                    ></div>
                                    <h2 style={{ "--accent_color1": colorObj?.accent_color1 }}>
                                        {title}
                                    </h2>
                                </div>
                                <p style={{ "--accent_color1": colorObj?.accent_color1 }}>
                                    {parseWithRemoveBrTagsFromBody(introduction || "")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section
                className="prog-link-wrapper my-30"
                style={{
                    "--accent_color2": colorObj?.accent_color2,
                    "--primary_color": colorObj?.primary_color,
                }}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="desktop-std-menu">
                                <ul>
                                    {menu &&
                                        menu[0]?.data.map((v, i) => (
                                            <li
                                                className="nav-item cursor-pointer"
                                                key={i}
                                                onClick={() =>
                                                    openLink(v.content_reference, v.type)
                                                }
                                            >
                                                <span>{v.heading}</span>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="programme-std-body-container py-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="programme-std-body-content current"
                                style={{ "--accent_color1": colorObj?.accent_color1 }}
                            >
                                {parseWithRemoveBrTagsFromBody(body || "")}

                                <div
                                    className="btnHldr mt-3"
                                    style={{
                                        "--accent_color4": colorObj?.accent_color4,
                                        "--primary_color": colorObj?.primary_color,
                                    }}
                                >
                                    {button_1_title !== "" && (
                                        <Button
                                            className="link"
                                            onClick={() => openLink(button_1_link)}
                                        >
                                            {button_1_title}
                                            <i
                                                className="fa fa-angle-right ms-2"
                                                aria-hidden="true"
                                            ></i>
                                        </Button>
                                    )}

                                    {button_2_title !== "" && (
                                        <Button
                                            className="link"
                                            onClick={() => openLink(button_2_link)}
                                        >
                                            {button_2_title}
                                            <i
                                                className="fa fa-angle-right ms-2"
                                                aria-hidden="true"
                                            ></i>
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="Get-touch-wrapper" style={{ background: colorObj?.background_color }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {program_heading !== "" && (
                                <h2 style={{ color: colorObj?.primary_color }}>
                                    {program_heading}
                                </h2>
                            )}
                            {program_description !== "" && (
                                <p style={{ color: colorObj?.accent_color1 }}>
                                    {program_description && parse(program_description)}
                                </p>
                            )}
                        </div>
                        {(program_question_heading !== "" ||
                            program_question_description !== "") && (
                            <div className="col-12 mt-2">
                                <Accordion>
                                    <AccordionTab
                                        header={program_question_heading}
                                        style={{
                                            "--accent_color2": colorObj?.accent_color2,
                                            "--primary_color": `2px solid ${colorObj?.primary_color}`,
                                            "--primary_color1": colorObj?.primary_color,
                                        }}
                                    >
                                        <div style={{ color: colorObj?.accent_color1 }}>
                                            {program_question_description &&
                                                parse(program_question_description)}
                                        </div>
                                    </AccordionTab>
                                </Accordion>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProgrammeTemplate;
