import React, { useEffect, useState } from "react";
import "./ProgrammeInfoPanel.css";
import ImageWithObjectFit from "../ImageWrapperComponent";
import parse from "html-react-parser";

function ProgrammeInfoPanel({ contents: contentToRender, heading, colors: colorObj }) {
    const [progInfoPanelDetails, setProgInfoPanelDetails] = useState({
        heading: "",
        body: "",
        image: "",
    });

    useEffect(() => {
        setProgInfoPanelDetails({
            ...progInfoPanelDetails,
            heading: contentToRender[0]?.heading,
            body: contentToRender[0]?.body,
            image: contentToRender[0]?.content_image,
        });
    }, []);

    return (
        <section
            className="programme-std-wrapper py-50"
            style={{ background: colorObj?.background_color }}
        >
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 p-0 px-lg-3 px-md-3 order-lg-2 order-md-2">
                        <div className="job-img">
                            <ImageWithObjectFit src={progInfoPanelDetails?.image.src} alt=" " />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="jobTxt-hldr">
                            <div className="prod-dev" style={{ color: colorObj?.accent_color1 }}>
                                {heading}
                            </div>
                            <div className="job-title">
                                <div
                                    className="line"
                                    style={{ background: colorObj?.primary_color }}
                                ></div>
                                <h2 style={{ color: colorObj?.accent_color1 }}>
                                    {progInfoPanelDetails?.heading}
                                </h2>
                            </div>
                            <p style={{ color: colorObj?.accent_color1 }}>
                                {progInfoPanelDetails?.body && parse(progInfoPanelDetails?.body)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProgrammeInfoPanel;
