import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import "./FeatureTabs.css";
import { PlaceHolder } from "../../assets";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { useOpenLink } from "../../utils/useOpenLink";
import { blockSourceString } from "../../utils/string";
import useTranslation from "../../utils/useTranslation";
import { articleType } from "../../utils/articleHelpers";

function FeatureTabs({ contents: contentsArray = [], block_source, colors: colorObj }) {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [iconListDetails, setIconListDetails] = useState([]);
    const content = useSelector((state) => state.layout.content);
    const { t } = useTranslation();

    useEffect(() => {
        if (block_source === blockSourceString.article_details) {
            if (contentsArray.length > 0) {
                const iterator = contentsArray[selectedIndex];
                if (iterator && content[iterator.contentKey]) {
                    const respKeys = content[iterator.contentKey];
                    if (respKeys && Array.isArray(respKeys)) {
                        const resp = respKeys.map((k) => content[k]).slice(0, 1);
                        setIconListDetails(resp);
                    }
                }
            }
        }
    }, [content, contentsArray, selectedIndex]);

    const handleClick = (path, nid) => {
        openLink(path, articleType, nid);
    };

    const openLink = useOpenLink();

    return (
        <div className="FeturesTabComponent py-50">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 ">
                        <TabView
                            activeIndex={selectedIndex}
                            onTabChange={(e) => setSelectedIndex(e.index)}
                            style={{
                                "--accent_color3": colorObj?.accent_color3,
                                "--primary_color": colorObj?.primary_color,
                            }}
                        >
                            {block_source === blockSourceString.article_details
                                ? contentsArray.map((tab, index) => (
                                      <TabPanel key={tab.heading} header={tab.heading}>
                                          <div className="col-12" id={tab.heading}>
                                              {selectedIndex === index &&
                                                  iconListDetails.map((v) => (
                                                      <div
                                                          className="Componenthldr fade-in"
                                                          key={v.title}
                                                      >
                                                          <div
                                                              className="carousel-caption"
                                                              style={{
                                                                  background:
                                                                      colorObj?.accent_color3,
                                                              }}
                                                          >
                                                              <h3
                                                                  style={{
                                                                      color: colorObj?.accent_color4,
                                                                  }}
                                                              >
                                                                  {v.title}
                                                              </h3>
                                                              <p
                                                                  style={{
                                                                      color: colorObj?.accent_color4,
                                                                  }}
                                                              >
                                                                  {v.summary && parse(v.summary)}
                                                              </p>
                                                              <div
                                                                  className="discoverMain cursor-pointer"
                                                                  onClick={() =>
                                                                      handleClick(v?.path, v?.nid)
                                                                  }
                                                              >
                                                                  <button
                                                                      type="button"
                                                                      className="discover-experiences-Btn"
                                                                      style={{
                                                                          background:
                                                                              colorObj?.primary_color,
                                                                          color: colorObj?.accent_color4,
                                                                      }}
                                                                  >
                                                                      {t("btnText.learnMore")}
                                                                  </button>
                                                              </div>
                                                          </div>
                                                          <img
                                                              src={
                                                                  v.image?.src
                                                                      ? v.image?.src
                                                                      : PlaceHolder
                                                              }
                                                          />
                                                      </div>
                                                  ))}
                                          </div>
                                      </TabPanel>
                                  ))
                                : contentsArray.map((tab) => (
                                      <TabPanel key={tab.heading} header={tab.heading}>
                                          <div className="col-12" id={tab.heading}>
                                              {tab.contents.map((v) => (
                                                  <div
                                                      className="Componenthldr fade-in container-fluid"
                                                      key={v.title}
                                                  >
                                                      <div className="row h-100">
                                                          <div className="col-12">
                                                              <div className="container h-100 additional-padding">
                                                                  <div
                                                                      className="carousel-caption"
                                                                      style={{
                                                                          background:
                                                                              colorObj?.accent_color3,
                                                                      }}
                                                                  >
                                                                      <h3
                                                                          style={{
                                                                              color: colorObj?.accent_color4,
                                                                          }}
                                                                      >
                                                                          {v.heading}
                                                                      </h3>
                                                                      <p
                                                                          style={{
                                                                              color: colorObj?.accent_color4,
                                                                          }}
                                                                      >
                                                                          {v.body && parse(v.body)}
                                                                      </p>
                                                                      {tab?.content_buttons.length >
                                                                          0 &&
                                                                          tab?.content_buttons.map(
                                                                              (v) => (
                                                                                  <div
                                                                                      className="discoverMain cursor-pointer"
                                                                                      key={v.label}
                                                                                      onClick={() =>
                                                                                          openLink(
                                                                                              v.action,
                                                                                          )
                                                                                      }
                                                                                  >
                                                                                      <button
                                                                                          type="button"
                                                                                          className="discover-experiences-Btn"
                                                                                          style={{
                                                                                              background:
                                                                                                  colorObj?.primary_color,
                                                                                              color: colorObj?.accent_color4,
                                                                                          }}
                                                                                      >
                                                                                          {v.label}
                                                                                      </button>
                                                                                  </div>
                                                                              ),
                                                                          )}
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>

                                                      <img
                                                          src={
                                                              tab.content_image?.src
                                                                  ? tab.content_image?.src
                                                                  : PlaceHolder
                                                          }
                                                      />
                                                  </div>
                                              ))}
                                          </div>
                                      </TabPanel>
                                  ))}
                        </TabView>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeatureTabs;
