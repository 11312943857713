import React from "react";
import "./SimpleBanner.css";
import parse from "html-react-parser";
import ImageWithObjectFit from "../ImageWrapperComponent";
import useCustomTranslation from "../CustomTranslation";
import { useOpenLink } from "../../utils/useOpenLink";

function SimpleBanner({ heading, body, section_image, colors: colorObj, block_buttons }) {
    const openLink = useOpenLink();

    const { dir, t } = useCustomTranslation();

    return (
        <>
            <section
                className="needHelpSecation my-30"
                style={{
                    background: colorObj?.background_color,
                    "--accent_color2": colorObj?.accent_color2,
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="HelpWrapper">
                                <div className="row">
                                    <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                                        <div className="TxtMain">
                                            <h2
                                                style={{
                                                    "--primary_color": colorObj?.primary_color,
                                                }}
                                            >
                                                {heading}
                                            </h2>
                                            <span
                                                className="helplineTxt"
                                                style={{
                                                    "--accent_color1": colorObj?.accent_color1,
                                                }}
                                            >
                                                {parse(body)}
                                            </span>
                                            {block_buttons.map((v) => (
                                                <span
                                                    className="btn btn-link"
                                                    key={v.label}
                                                    onClick={() => openLink(v.action)}
                                                    style={{
                                                        "--primary_color": colorObj?.primary_color,
                                                    }}
                                                >
                                                    {v.label}
                                                    <i
                                                        className={`${
                                                            dir === t("dirString.rtl")
                                                                ? "fa fa-angle-left btn-icon"
                                                                : "bi bi-arrow-right-short btn-icon"
                                                        }`}
                                                    ></i>
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 col-12 mobileImg">
                                        <ImageWithObjectFit src={section_image?.src} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SimpleBanner;
