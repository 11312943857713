import React from "react";
import "./TextLinks2.css";
import { useOpenLink } from "../../utils/useOpenLink";

function TextLinks2({ heading, contents: contentsArray, colors: colorObj }) {
    const openLink = useOpenLink();

    return (
        <>
            <div
                className="programmeServiceWrapper py-50"
                style={{ background: colorObj?.background_color }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="titleMain"
                                style={{ "--primary_color": colorObj?.primary_color }}
                            >
                                <h3>{heading}</h3>
                            </div>
                        </div>

                        <div className="col-12">
                            <div
                                className="programmesTagWrapper"
                                style={{
                                    "--primary_color": colorObj?.primary_color,
                                    "--accent_color1": colorObj?.accent_color1,
                                }}
                            >
                                <ul className="overflow-x-auto scrollbarStyles hidescrollbar">
                                    {contentsArray.map((value) => (
                                        <li
                                            onClick={() => openLink(value.contentKey, value.type)}
                                            key={value.heading}
                                        >
                                            {value.heading}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TextLinks2;
