import useTranslation from "../../utils/useTranslation";
import { useSelector } from "react-redux";

const useCustomTranslation = () => {
    const { t } = useTranslation();

    const { direction } = useSelector((state) => state.auth);

    return { direction, t };
};

export default useCustomTranslation;
