import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { navList } from "./mockData";
import { hash } from "../utils/hashFunctions";
import { convertStringToKabobCase } from "../utils/urlUtils";
import {
    ARTICLES_API_LINK,
    INITIAL_PAGE_NAME,
    PAGES_API_LINK,
    STATIC_TRANSLATE_DATA_API_LINK,
} from "../constants/appLinks";
import { getAuthToken } from "../utils/oauthHandler";

const SLICE_NAME = "layout";

export const fetchFromApi = createAsyncThunk(`${SLICE_NAME}/fetchFromApi`, async ({ link }) => {
    try {
        const res = await fetch(link);
        const data = await res.json();
        const { pager, planner, ...restContent } = data;

        const content = planner || restContent;
        const contentArray = Object.keys(content).map((k) => content[k]);
        return { key: link, data: contentArray, pager };
    } catch (err) {
        return false;
    }
});

export const fetchPages = createAsyncThunk(
    `${SLICE_NAME}/fetchPages`,
    async ({ pageKey, country, lang }) => {
        const token = getAuthToken();

        const header =
            token !== null
                ? {
                      authorization: `Bearer ${token}`,
                  }
                : {};
        // if article/xxx, then change the url params to: ?name=article&id=xxx
        // else (for example, programs-and-services/our-mission), ?name=programs-and-services%2four-mission

        const isAuthedCheck = token !== null ? 1 : 0;

        const link = pageKey
            ? `${PAGES_API_LINK}&authenticated=${isAuthedCheck}&country=${country}&lang=${lang}&name=${pageKey}`
            : `${PAGES_API_LINK}&authenticated=${isAuthedCheck}&country=${country}&lang=${lang}`;
        const res = await fetch(link, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                ...header,
            },
        });
        const data = await res.json();

        return { data, pageKey: pageKey || INITIAL_PAGE_NAME };
    },
);

export const fetchArticle = createAsyncThunk(
    `${SLICE_NAME}/fetchArticle`,
    async ({ id: articleId, lang }) => {
        const res = await fetch(`${ARTICLES_API_LINK}${articleId}${lang ? "&lang=" + lang : ""}`);
        const data = await res.json();

        return { id: articleId, ...data };
    },
);

export const fetchStaticTraslateData = createAsyncThunk(
    `${SLICE_NAME}/fetchStaticTraslateData`,
    async (lang) => {
        try {
            const res = await fetch(`${STATIC_TRANSLATE_DATA_API_LINK}?language=${lang}`);
            const data = await res.json();
            return data;
        } catch (error) {
            console.error(error);
        }
    },
);

const initialState = {
    content: {},
    nav: navList,
    pages: {},
    isLoading: true,
    isDropdownPickerExistes: false,
    metaTags: [],
    sections: {},
    staticStrings: {},
    isVisibleMenu: false,
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setShowDropDownPicker: (state, action) => {
            state.isDropdownPickerExistes = action.payload;
        },
        setIsVisibleMenu: (state, action) => {
            state.isVisibleMenu = action.payload;
        },
        // Example reducer:
        // fetchContentAndLayout: (state) => {
        //   const contentKeysMappedToLowercase = {}
        //   Object.keys(contentFromII.content).forEach(
        //     (k) => (contentKeysMappedToLowercase[k.toLowerCase()] = contentFromII.content[k]),
        //   )
        //   state.content = contentKeysMappedToLowercase
        //   state.layout = layoutFromII.layout
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFromApi.fulfilled, (state, action) => {
                const { key, data } = action.payload;
                if (Array.isArray(data)) {
                    const baseKey = hash(key);
                    const contentKeys = [];

                    data.forEach((d, i) => {
                        const keyForContent = d.nid ?? `${baseKey}${i}`;
                        contentKeys.push(keyForContent);

                        const dMappedToLowercase = {};
                        Object.keys(d).forEach((k) => (dMappedToLowercase[k.toLowerCase()] = d[k]));

                        state.content[keyForContent] = dMappedToLowercase;
                    });
                    state.content[baseKey] = contentKeys;
                } else {
                    console.error("Data is not an array or is undefined:", data);
                }
            })
            .addCase(fetchPages.fulfilled, (state, action) => {
                const { data, pageKey } = action.payload;

                state.metaTags = data.meta;
                state.sections = data.section;

                const combinedHeaderObj = {
                    layout_type: "header",
                };

                const hasHeader = data.section.some(
                    (cur) => cur.layout_type === "standard_menu_header",
                );
                // Process sections and combine header objects for standard_menu_header and sub_header

                if (hasHeader) {
                    const updatedSections = data.section.reduce(
                        (acc, cur) => {
                            const [headerObj, ...rest] = acc;

                            switch (cur.layout_type) {
                                case "standard_menu_header":
                                    headerObj.standardMenuHeader = cur;
                                    return [headerObj, ...rest];
                                case "sub_header":
                                    headerObj.subHeader = cur;
                                    return [headerObj, ...rest];
                                default:
                                    return [...acc, cur];
                            }
                        },
                        [combinedHeaderObj],
                    );

                    // Generate key for the page
                    const key = convertStringToKabobCase(pageKey !== "" ? pageKey : data.title);
                    const updatedDataObj = {
                        ...data,
                        section: updatedSections,
                        id: data.title || key,
                    };

                    // Update state for standard_menu_header and sub_header sections
                    if (key) state.pages[key] = updatedDataObj;
                }

                //standard_menu_header_2

                const combinedHeader2Obj = {
                    layout_type: "header2",
                };

                const hasHeader2 = data.section.some(
                    (cur) => cur.layout_type === "standard_menu_header_2",
                );
                // Process sections and combine header objects for standard_menu_header and sub_header

                if (hasHeader2) {
                    const updatedSections = data.section.reduce(
                        (acc, cur) => {
                            const [headerObj, ...rest] = acc;

                            switch (cur.layout_type) {
                                case "standard_menu_header_2":
                                    headerObj.standardMenuHeader2 = cur;
                                    return [headerObj, ...rest];
                                case "sub_header":
                                    headerObj.subHeader = cur;
                                    return [headerObj, ...rest];
                                default:
                                    return [...acc, cur];
                            }
                        },
                        [combinedHeader2Obj],
                    );

                    // Generate key for the page
                    const key = convertStringToKabobCase(pageKey !== "" ? pageKey : data.title);
                    const updatedDataObj = {
                        ...data,
                        section: updatedSections,
                        id: data.title || key,
                    };

                    // Update state for standard_menu_header_2 and sub_header sections
                    if (key) state.pages[key] = updatedDataObj;
                }

                // Check if there are any mediaheader sections
                const hasMediaHeader = data.section.some(
                    (cur) => cur.layout_type === "mediaheader",
                );

                if (hasMediaHeader) {
                    const combinedMediaHeaderObj = {
                        layout_type: "media_header",
                    };

                    // Process sections and combine header objects for mediaheader and mediasubheader
                    const updatedMediaSections = data.section.reduce(
                        (acc, cur) => {
                            const [headerObj, ...rest] = acc;
                            switch (cur.layout_type) {
                                case "mediaheader":
                                    headerObj.mediaheader = cur;
                                    return [headerObj, ...rest];
                                case "mediasubheader":
                                    headerObj.mediasubheader = cur;
                                    return [headerObj, ...rest];
                                default:
                                    return [...acc, cur];
                            }
                        },
                        [combinedMediaHeaderObj],
                    );

                    // Generate key for the page
                    const mediaKey = convertStringToKabobCase(
                        pageKey !== "" ? pageKey : data.title,
                    );
                    const updatedMediaDataObj = {
                        ...data,
                        section: updatedMediaSections,
                        id: data.title || mediaKey,
                    };

                    // Update state for mediaheader and mediasubheader sections
                    if (mediaKey) state.pages[mediaKey] = updatedMediaDataObj;
                }
            })
            .addCase(fetchArticle.fulfilled, (state, action) => {
                const { id, image, ...data } = action.payload;
                state.content[id] = { ...state.content[id], ...data, detailImage: image };
            })
            .addCase(fetchStaticTraslateData.fulfilled, (state, action) => {
                const data = action.payload;
                state.staticStrings = data;
            })
            .addMatcher(
                (action) =>
                    action.type.startsWith(`${SLICE_NAME}/fetchPages`) &&
                    action.type.endsWith("pending"),
                (state) => {
                    state.isLoading = true;
                },
            )
            .addMatcher(
                (action) =>
                    action.type.startsWith(`${SLICE_NAME}/fetchPages`) &&
                    action.type.endsWith("fulfilled"),
                (state) => {
                    state.isLoading = false;
                },
            )
            .addMatcher(
                (action) =>
                    action.type.startsWith(`${SLICE_NAME}/fetchPages`) &&
                    action.type.endsWith("rejected"),
                (state) => {
                    state.isLoading = false;
                },
            );
    },
});

export const showCountryDropdown = (state) => state[SLICE_NAME].isDropdownPickerExistes;
export const translateStaticStrings = (state) => state[SLICE_NAME].staticStrings;
export const { setShowDropDownPicker } = layoutSlice.actions;
export const { setIsVisibleMenu } = layoutSlice.actions;
export const isVisibleMenu = (state) => state[SLICE_NAME].isVisibleMenu;

// Action creators are generated for each case reducer function
// export const { fetchContentAndLayout } = layoutSlice.actions

export default layoutSlice.reducer;
