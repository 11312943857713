import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setLanguageInfo } from "../../redux/authSlice";
import { fetchStaticTraslateData } from "../../redux/layoutSlice";
import { useClickOutside } from "../../utils/useClickOutside";

const LanguagePicker = ({ selectedCountry, countriesData }) => {
    const dropdownRef = useRef(null);

    const initialSetRef = useRef(false);

    const [toggleSelectLanguage, setoggleSelectLanguage] = useState(false);

    const [availableLanguage, setAvailableLanguage] = useState([]);

    const [selectedLanguage, setSelectedLanguage] = useState("");

    const dispatch = useDispatch();

    const languageNameFromLocalStorage = localStorage.getItem("selectedLanguage");

    useEffect(() => {
        const filter = countriesData.filter((e) => e.country === selectedCountry);
        if (filter.length > 0 && filter[0].language) {
            const selectedLang = filter[0].language.find(
                (item) => item.code === languageNameFromLocalStorage,
            );

            if (selectedLang && selectedLang.label !== selectedLanguage) {
                setSelectedLanguage(selectedLang.label);
            }
        }
    }, [countriesData, selectedCountry, languageNameFromLocalStorage, selectedLanguage]);

    useClickOutside(dropdownRef, () => setoggleSelectLanguage(false));

    const changeLanguage = (language, dir) => {
        setoggleSelectLanguage(false);
        dispatch(setLanguageInfo({ languageName: language, direction: dir }));
        dispatch(fetchStaticTraslateData(language));
    };

    useEffect(() => {
        if (selectedCountry && countriesData.length > 0) {
            const filter = countriesData.filter((e) => e.country === selectedCountry);

            if (filter.length > 0 && filter[0].language) {
                const arr = filter[0].language.map((iterator) => ({
                    displayName: iterator.label,
                    dir: iterator.direction,
                    code: iterator.code,
                }));

                if (JSON.stringify(arr) !== JSON.stringify(availableLanguage)) {
                    setAvailableLanguage(arr);
                }

                if (arr.length === 1 && !initialSetRef.current) {
                    initialSetRef.current = true;
                    if (arr[0]?.code !== selectedLanguage) {
                        changeLanguage(arr[0]?.code, arr[0]?.dir);
                    }
                }
            } else {
                if (availableLanguage.length !== 0) {
                    setAvailableLanguage([]);
                }
            }
        }
    }, [selectedCountry, countriesData, availableLanguage, selectedLanguage, changeLanguage]);

    return (
        <div className="relative">
            <div
                className={"hidden md:block cursor-pointer"}
                onClick={() =>
                    toggleSelectLanguage
                        ? setoggleSelectLanguage(false)
                        : setoggleSelectLanguage(true)
                }
            >
                {selectedLanguage}
            </div>
            <img
                className="block md:hidden translate-icon cursor-pointer w-6 h-6 object-cover"
                src="/assets/images/translate.svg"
                onClick={() => setoggleSelectLanguage(!toggleSelectLanguage)}
                alt="Translate Icon"
            />
            {toggleSelectLanguage && (
                <div
                    className="absolute mt-2 right-0 min-w-64 bg-white shadow-lg pt-3 rounded-md text-base text-accent_color1"
                    ref={dropdownRef}
                >
                    <h3 className="pb-3 border-b border-solid border-b-accent_color3 px-4">
                        Select Language
                    </h3>
                    <div className="py-2">
                        {availableLanguage.map((item, index) => (
                            <div
                                className="cursor-pointer px-4 py-2 hover:bg-primary_color hover:text-accent_color4"
                                key={index}
                                onClick={() => {
                                    changeLanguage(item.code, item.dir);
                                }}
                            >
                                {item.displayName}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default LanguagePicker;
