import React, { useContext, useEffect, useState } from "react";
import "./MediaHeader.css";
import { WindowContext } from "../WindowResize/resize";
import { useOpenLink } from "../../utils/useOpenLink";
import { useNavigate } from "react-router-dom";

function MediaHeader({ mediasubheader: sub = {}, mediaheader: standard = {} }) {
    const [subMenuHeader, setSubMenuHeader] = useState(sub.contents || []);

    const standaradMenuHeader = standard.contents || [];

    const { isDesktop } = useContext(WindowContext);

    useEffect(() => {
        if (sub.contents) {
            setSubMenuHeader(sub.contents);
        }
    }, [standaradMenuHeader]);

    const openLink = useOpenLink();

    const navigate = useNavigate();

    return (
        <>
            <section className="mediaHeader sticky-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="navbar navbar-expand-lg-md p-0">
                                <div className="col-6 mainHeader">
                                    <div className="logoMain cursor-pointer">
                                        <img
                                            src="/assets/images/media_logo.svg"
                                            onClick={() => navigate("/")}
                                        />
                                    </div>
                                </div>
                                <div className="col-6 mainHeader">
                                    {standaradMenuHeader &&
                                        standaradMenuHeader.map((v) => (
                                            <div className="headerLink" key={v.heading}>
                                                {v.heading}
                                            </div>
                                        ))}
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#topMenu"
                                        aria-controls="topMenu"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                    >
                                        <span
                                            className="hamburger-menu-icon"
                                            style={{ display: isDesktop ? "none" : "block" }}
                                        >
                                            <img src="/assets/images/hamburger-menu.svg" />
                                        </span>
                                    </button>
                                </div>

                                <div className="hide navbar-collapse" id="topMenu">
                                    <ul className="navbar-nav me-auto mb-2 mb-0">
                                        {subMenuHeader.map((v) => (
                                            <li className="nav-item cursor-pointer" key={v.heading}>
                                                <span
                                                    className="nav-link"
                                                    onClick={() => openLink(v.content_reference)}
                                                >
                                                    {v.heading}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mediaSubHeader">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="navbar navbar-expand-sm p-0">
                                <div
                                    className="hide navbar-collapse justify-content-md-end"
                                    id="MainNav"
                                >
                                    <ul className="navbar-nav">
                                        {subMenuHeader.map((v) => (
                                            <li
                                                className="nav-item cursor-pointer nav-link"
                                                key={v.heading}
                                            >
                                                <div>
                                                    {v.content_reference.startsWith("mailto") ? (
                                                        <a
                                                            className="form-check-label country"
                                                            href={v.content_reference}
                                                        >
                                                            {v.heading}
                                                        </a>
                                                    ) : (
                                                        <label
                                                            className="form-check-label country"
                                                            onClick={() =>
                                                                openLink(v.content_reference)
                                                            }
                                                        >
                                                            {v.heading}
                                                        </label>
                                                    )}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default MediaHeader;
