import React from "react";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { useOpenLink } from "../../utils/useOpenLink";
import { articleType } from "../../utils/articleHelpers";

function ImageCard({ content }) {
    const { image, title, nid, link, posterImage, content_source, type, path, contentLink } =
        content;

    const openLink = useOpenLink();

    const handleClick = () => {
        if (content_source === "Content") {
            openLink(contentLink, type);
        } else {
            openLink(path, articleType, nid);
        }
    };

    return (
        <div className="card">
            <div className="cardImg">
                <ImageWithObjectFit
                    className="cursor-pointer"
                    src={link ? posterImage : image?.src}
                />
            </div>
            <div className="card-body">
                <h3 className="cursor-pointer underline" onClick={() => handleClick()}>
                    {title}
                </h3>
            </div>
        </div>
    );
}

export default ImageCard;
