import React from "react";
import ImageWithObjectFit from "../ImageWrapperComponent";
import moment from "moment";
import { defaultEventImage } from "../../utils/theme";
import { useNavigate } from "react-router-dom";

function FeaturedEventCard({ content, colors }) {
    const { image, title, start } = content;

    const navigate = useNavigate();

    return (
        <>
            <div className="newsCard card">
                <div className="cardImg">
                    <ImageWithObjectFit
                        className="cursor-pointer"
                        src={image.src ? image.src : defaultEventImage}
                    />
                </div>
                <div className="card-body d-flex flex-column">
                    <div className="day mb-2" style={{ color: colors?.accent_color1 }}>
                        {moment(start).format("dddd")}
                    </div>
                    <h3
                        className="underline"
                        onClick={() =>
                            navigate(`/event-detail`, {
                                state: { eventDetails: content, deafultImage: defaultEventImage },
                            })
                        }
                        style={{ color: colors?.accent_color1 }}
                    >
                        {title}
                    </h3>

                    <div className="eventDate mt-auto" style={{ color: colors?.accent_color3 }}>
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                        <span className="ms-2 date"></span>
                        {moment(start).format("MMM DD")}
                    </div>
                </div>
            </div>
        </>
    );
}

export default FeaturedEventCard;
