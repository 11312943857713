import React from "react";
import "./MediaFooter.css";
import { useOpenLink } from "../../utils/useOpenLink";
import useTranslation from "../../utils/useTranslation";

function MediaFooter({ contents: contentsToRender = [] }) {
    const openLink = useOpenLink();

    const { t } = useTranslation();

    return (
        <>
            <div className="mediaFooter">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-6 col-12">
                            <div className="col-12 d-flex justify-content-center">
                                <div className="mediaLogoAppHldr">
                                    <div className="logo">
                                        <img
                                            className="cursor-pointer"
                                            src={
                                                location.origin +
                                                "/assets/images/media-footer-logo.svg"
                                            }
                                            onClick={() => openLink("/")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-12">
                            <div className="mediaEmail my-4">
                                <i className="fa fa-envelope-o me-2" aria-hidden="true"></i>
                                {t("mediaFooterString.media_email")}
                                <a className="ms-2" href={`mailto:${t("mediaFooterString.email")}`}>
                                    {t("mediaFooterString.email")}
                                </a>
                            </div>

                            <div className="row">
                                {contentsToRender.map((value) => (
                                    <div
                                        className="col-lg-4 col-md-4 col-sm-12 col-12 linkMain"
                                        key={value.heading}
                                    >
                                        <h6 onClick={() => openLink(value.contentKey, value.type)}>
                                            {value.heading}
                                        </h6>
                                        {value.contents && (
                                            <>
                                                <ul>
                                                    {value.contents.map((v) => (
                                                        <li key={v.heading}>
                                                            <span
                                                                onClick={() =>
                                                                    openLink(
                                                                        v.content_reference,
                                                                        v.type,
                                                                    )
                                                                }
                                                            >
                                                                {v.heading}
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="copyright">
                                &#169; {new Date().getFullYear()}{" "}
                                {t("mediaFooterString.copy_right")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MediaFooter;
