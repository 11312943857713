import React, { useEffect, useState } from "react";
import "./MessagesList.css";
import { Paginator } from "primereact/paginator";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteMessageByIds,
    fetchMessageById,
    fetchMessages,
    readMessageByIds,
    unReadMessageByIds,
} from "../../redux/messagesSlice";
import parse from "html-react-parser";
import { MESSAGES_API_LINK } from "../../constants/appLinks";
import useTranslation from "../../utils/useTranslation";

function MessagesList({ colors: colorObj }) {
    const dispatch = useDispatch();

    const [selectedMessageId, setSelectedMessageId] = useState("");

    const messages = useSelector((state) => state.messages.messages);
    const messageDetailsById = useSelector((state) => state.messages.messageDetails);
    const readMessageStatus = useSelector((state) => state.messages.readMessageStatus);
    const unreadMessageStatus = useSelector((state) => state.messages.unreadMessageStatus);
    const deleteMessageStatus = useSelector((state) => state.messages.deleteMessageStatus);

    const [actualMessagesData, setActualMessagesData] = useState([]);

    const [readUnreadMessageCount, setReadUnreadMessageCount] = useState(0);

    const [currentPage, setCurrentPage] = useState(0);

    const [deleteMessageCount, setDeleteMessageCount] = useState(0);

    const [bindMessagesDetails, setBindMessagesDetails] = useState([]);

    const [messagesDetails, setMessagesDetails] = useState({});

    const [totalPageCount, setTotalPageCount] = useState(0);

    const [showMessage, setShowMessage] = useState(false);

    const [selectedReadMessageIds, setSelectedReadMessageIds] = useState([]);

    const [selectedUneadMessageIds, setSelectedUnReadMessageIds] = useState([]);

    const [selectedDeleteMessageIds, setSelectedDeleteMessageIds] = useState([]);

    const [isCheckAll, setIsCheckAll] = useState(false);

    const [showDeleteButton, setIsshowDeleteButton] = useState(false);

    const { t } = useTranslation();

    const messages_api = localStorage.getItem("message_api");

    const apiLink = messages_api ? JSON.parse(messages_api) : MESSAGES_API_LINK;

    const [filterReq, setFilterReq] = useState({
        pageSize: 10,
        pageNumber: 0,
    });

    useEffect(() => {
        if (selectedMessageId === "") {
            getAllMessages();
        }
    }, []);

    const getAllMessages = () => {
        setSelectedDeleteMessageIds([]);
        dispatch(fetchMessages(apiLink));
    };

    useEffect(() => {
        if (selectedMessageId === "" && messages) {
            let readMessageIds = [];
            let unreadMessageIds = [];
            let deleteMessageIds = [];
            setActualMessagesData([]);
            setTotalPageCount(0);

            let data = [...messages];
            let count = 0;
            if (data) {
                count += data.length;
                data = data.map((v) => ({ ...v, isChecked: false }));

                const arr = [...bindMessagesDetails];

                for (const iterator of data) {
                    const findIndex = arr.findIndex((e) => e.id === iterator.id);

                    if (findIndex !== -1) {
                        iterator.isChecked = arr[findIndex].isChecked ? true : false;
                    }
                    readMessageIds = data.flatMap((e) =>
                        e.isChecked && e.status === t("constantString.STATUS_READ")
                            ? e.id.toString()
                            : [],
                    );
                    unreadMessageIds = data.flatMap((e) =>
                        e.isChecked && e.status === t("constantString.STATUS_UNREAD")
                            ? e.id.toString()
                            : [],
                    );
                    deleteMessageIds = arr.flatMap((e) => (e.isChecked ? e.id.toString() : []));
                }
                setActualMessagesData(data);
                setSelectedReadMessageIds(readMessageIds);
                setSelectedUnReadMessageIds(unreadMessageIds);
                setSelectedDeleteMessageIds(deleteMessageIds);
                setTotalPageCount(count);
            }
        }
    }, [messages, selectedMessageId]);

    useEffect(() => {
        pagination();
    }, [filterReq]);

    useEffect(() => {
        if (actualMessagesData.length > 0) {
            pagination();
        }
    }, [actualMessagesData]);

    const pagination = () => {
        const pageSize = filterReq.pageSize;
        const page = filterReq.pageNumber + 1;
        const data = actualMessagesData.slice(pageSize * (page - 1), pageSize * page);
        setIsCheckAll(false);
        setBindMessagesDetails(data);
    };

    const onPageChange = (event) => {
        setCurrentPage(event.page);
        setFilterReq({ ...filterReq, pageSize: event.rows, pageNumber: event.page });
    };

    const clickOnShowMessage = (id) => {
        setShowMessage(true);
        setSelectedMessageId(id);
        dispatch(readMessageByIds({ apiLink, messages_id: [id] }));
    };

    useEffect(() => {
        if (selectedMessageId !== "") {
            dispatch(fetchMessageById({ apiLink, id: selectedMessageId }));
        }
    }, [selectedMessageId]);

    useEffect(() => {
        if (selectedMessageId !== "") {
            const data = { ...messageDetailsById };
            if (data) {
                setMessagesDetails(data);
            }
        }
    }, [messageDetailsById]);

    const handleClick = (checked, selectedMessageId, j) => {
        const index = j + filterReq.pageNumber * filterReq.pageSize;
        let readMessageIds = [];
        let unreadMessageIds = [];
        let deleteMessageIds = [];

        let arr = [...bindMessagesDetails];

        if (arr[index].id === selectedMessageId) {
            arr[index].isChecked = checked;
        } else {
            arr[index].isChecked = false;
        }

        readMessageIds = arr.flatMap((e) =>
            e.isChecked && e.status === t("constantString.STATUS_READ") ? e.id.toString() : [],
        );
        unreadMessageIds = arr.flatMap((e) =>
            e.isChecked && e.status === t("constantString.STATUS_UNREAD") ? e.id.toString() : [],
        );
        deleteMessageIds = arr.flatMap((e) => (e.isChecked ? e.id.toString() : []));

        setSelectedReadMessageIds(readMessageIds);
        setSelectedUnReadMessageIds(unreadMessageIds);
        setSelectedDeleteMessageIds(deleteMessageIds);
        setBindMessagesDetails(arr);

        if (filterReq.pageNumber === currentPage) {
            setIsCheckAll(deleteMessageIds.length === bindMessagesDetails.length ? true : false);
        } else {
            setIsCheckAll(false);
        }

        setIsshowDeleteButton(deleteMessageIds.length > 0);
    };

    const handleSelectAll = (checked) => {
        setIsshowDeleteButton(true);
        let readMessageIds = [];
        let unreadMessageIds = [];
        let deleteMessageIds = [];

        setIsCheckAll(!isCheckAll);
        let arr = [...bindMessagesDetails];
        arr = arr.map((v) => ({ ...v, isChecked: checked ? true : false }));
        readMessageIds = arr.flatMap((e) =>
            e.isChecked && e.status === t("constantString.STATUS_READ") ? e.id.toString() : [],
        );
        unreadMessageIds = arr.flatMap((e) =>
            e.isChecked && e.status === t("constantString.STATUS_UNREAD") ? e.id.toString() : [],
        );
        deleteMessageIds = arr.flatMap((e) => (e.isChecked ? e.id.toString() : []));
        setSelectedReadMessageIds(readMessageIds);
        setSelectedUnReadMessageIds(unreadMessageIds);
        setSelectedDeleteMessageIds(deleteMessageIds);
        setBindMessagesDetails(arr);

        setIsshowDeleteButton(deleteMessageIds.length > 0);
    };

    useEffect(() => {
        if (readUnreadMessageCount !== 0 && selectedUneadMessageIds.length > 0) {
            dispatch(readMessageByIds({ apiLink, messages_id: selectedUneadMessageIds }));
        } else {
            dispatch(unReadMessageByIds({ apiLink, messages_id: selectedReadMessageIds }));
        }
    }, [readUnreadMessageCount]);

    useEffect(() => {
        if (readMessageStatus?.success || unreadMessageStatus?.success) {
            getAllMessages();
        }
    }, [readMessageStatus, unreadMessageStatus]);

    useEffect(() => {
        if (deleteMessageCount !== 0 && selectedDeleteMessageIds.length > 0) {
            dispatch(deleteMessageByIds({ apiLink, messages_id: selectedDeleteMessageIds }));
        }
    }, [deleteMessageCount]);

    useEffect(() => {
        if (deleteMessageStatus?.success) {
            setIsshowDeleteButton(false);
            setSelectedDeleteMessageIds([]);
            getAllMessages();
        }
    }, [deleteMessageStatus]);

    return (
        <>
            <section
                className="mailingList padTop padBottom"
                style={{ background: colorObj?.background }}
            >
                <div className="container">
                    <div className="row">
                        {!showMessage && (
                            <div className="col-12 mb-4 text-end btnMain">
                                {(selectedReadMessageIds.length > 0 ||
                                    selectedUneadMessageIds.length > 0) && (
                                    <span
                                        className="btn btn-orange-line me-3"
                                        style={{
                                            "--primary_color": colorObj?.primary_color,
                                            "--accent_color4": colorObj?.accent_color4,
                                        }}
                                        onClick={() =>
                                            setReadUnreadMessageCount((prev) => prev + 1)
                                        }
                                    >
                                        <i
                                            className={
                                                selectedUneadMessageIds.length > 0
                                                    ? "fa fa-envelope-open me-2"
                                                    : "fa fa-envelope me-2"
                                            }
                                        ></i>
                                        {selectedUneadMessageIds.length > 0
                                            ? t("constantString.MARK_AS_READ")
                                            : t("constantString.MARK_AS_UNREAD")}
                                    </span>
                                )}

                                {showDeleteButton && (
                                    <span
                                        className="btn btn-orange-line"
                                        style={{
                                            "--primary_color": colorObj?.primary_color,
                                            "--accent_color4": colorObj?.accent_color4,
                                        }}
                                        onClick={() => setDeleteMessageCount((prev) => prev + 1)}
                                    >
                                        <i className="fa fa-trash me-2"></i>
                                        {t("btnText.deleteMessage")}
                                    </span>
                                )}
                            </div>
                        )}

                        {!showMessage && bindMessagesDetails.length > 0 && (
                            <>
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="commonTable packageTable">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "60px" }}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                handleSelectAll(e.target.checked);
                                                            }}
                                                            checked={isCheckAll}
                                                            style={{
                                                                "--primary_color":
                                                                    colorObj?.primary_color,
                                                            }}
                                                        />
                                                    </th>
                                                    <th style={{ width: "180px" }}>
                                                        {t("constantString.date")}
                                                    </th>
                                                    <th style={{ width: "280px" }}>
                                                        {t("constantString.sender")}
                                                    </th>
                                                    <th>{t("constantString.subject")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bindMessagesDetails.map((v, index) => (
                                                    <tr
                                                        key={v.id}
                                                        className={`${
                                                            v.status === 0 ? "unread" : "read"
                                                        }`}
                                                        style={{
                                                            "--accent_color1":
                                                                colorObj?.accent_color1,
                                                        }}
                                                    >
                                                        <td>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={v.isChecked}
                                                                onChange={(e) => {
                                                                    handleClick(
                                                                        e.target.checked,
                                                                        v.id,
                                                                        index,
                                                                    );
                                                                }}
                                                                style={{
                                                                    "--primary_color":
                                                                        colorObj?.primary_color,
                                                                }}
                                                            />
                                                        </td>
                                                        <td>{v.message_date}</td>
                                                        <td>{v.sender}</td>
                                                        <td
                                                            className="cursor-pointer msg"
                                                            onClick={() => {
                                                                clickOnShowMessage(v.id);
                                                            }}
                                                        >
                                                            {v.subject}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                {bindMessagesDetails.length > 0 && (
                                    <div className="col-12 mt-3">
                                        <div className="table-pagination">
                                            <Paginator
                                                first={filterReq.pageNumber * filterReq.pageSize}
                                                rows={filterReq.pageSize}
                                                totalRecords={totalPageCount}
                                                onPageChange={onPageChange}
                                                pageLinkSize={5}
                                                style={{
                                                    "--primary_color": colorObj?.primary_color,
                                                    "--accent_color4": colorObj?.accent_color4,
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        {!showMessage && actualMessagesData && bindMessagesDetails.length === 0 && (
                            <div>
                                <h3 className="text">{t("constantString.noMessage")}</h3>
                            </div>
                        )}

                        {showMessage && (
                            <div className="col-md-12">
                                <div className="boxWrapper">
                                    <h3
                                        className="title mb-3"
                                        style={{ color: colorObj?.accent_color1 }}
                                    >
                                        {messagesDetails.sender}
                                    </h3>
                                    <hr
                                        style={{
                                            borderTop: `1px solid ${colorObj?.accent_color3}`,
                                        }}
                                    />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3
                                                className="titleText mb-3"
                                                style={{ color: colorObj?.accent_color1 }}
                                            >
                                                {messagesDetails.subject &&
                                                    parse(messagesDetails.subject)}
                                            </h3>
                                        </div>
                                        <div className="col-md-6">
                                            <h3
                                                className="date mb-3"
                                                style={{ color: colorObj?.accent_color1 }}
                                            >
                                                {messagesDetails.message_date}
                                            </h3>
                                        </div>
                                    </div>
                                    <p
                                        className="mb-3 detail-message"
                                        style={{ color: colorObj?.accent_color1 }}
                                    >
                                        {messagesDetails.message && parse(messagesDetails.message)}
                                    </p>
                                </div>
                                <span
                                    className="btn btn-orange-line mt-3"
                                    style={{
                                        "--primary_color": colorObj?.primary_color,
                                        "--accent_color4": colorObj?.accent_color4,
                                    }}
                                    onClick={() => {
                                        setShowMessage(false);
                                        setSelectedMessageId("");
                                    }}
                                >
                                    {t("btnText.back")}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default MessagesList;
