import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const getUrlParams = () => {
    return window.location.search
        .substring(1)
        .split("&")
        .reduce(function (initial, item) {
            if (item) {
                var parts = item.split("=");
                initial[parts[0]] = decodeURIComponent(parts[1]);
            }
            return initial;
        }, {});
};

export const removeParamsFromUrl = () => {
    window.history.pushState("", document.title, window.location.pathname);
};

export const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return null;
};

export const convertStringToKabobCase = (input) => {
    try {
        const regex = /[^\w\s]/g;
        return input.toLowerCase().replaceAll(regex, "").replace(" ", "-");
    } catch {
        return null;
    }
};

export const useSetTitle = (pageToRender) => {
    useEffect(() => {
        document.title = `${pageToRender?.title || ""}${pageToRender?.title ? " - " : ""}${
            pageToRender?.sitename || ""
        }`;
    }, [pageToRender]);
};

export const decodeHtmlEntities = (text) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
};

export const checkValidURL = (url) => {
    let validURL = false;

    try {
        new URL(url);
        validURL = true;
    } catch (e) {
        validURL = false;
    }

    return validURL;
};
