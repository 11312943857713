import React from "react";
import { useGetLink } from "../../utils/useOpenLink";
import { Link } from "react-router-dom";

function FullWidthCard({ content, colorObj }) {
    const { image, title, path, posterImage, link } = content;

    const { getLink } = useGetLink();

    return (
        <>
            <div
                className="sliderBox"
                style={{
                    background: `url(${link === "" || link === null ? image?.src : posterImage})`,
                }}
            >
                <div className="container h-screen">
                    <Link to={getLink(path)}>
                        <div className="textMain">
                            <h3
                                className="hover:underline cursor-pointer"
                                style={{ color: colorObj?.accent_color4 }}
                            >
                                {title}
                            </h3>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default FullWidthCard;
