import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const SLICE_NAME = "jobSpot";

export const jobSpotListing = createAsyncThunk(
    `${SLICE_NAME}/jobSpotListing`,
    async ({ reqObj, apiLink }) => {
        let query = "";

        for (const [key, value] of Object.entries(reqObj)) {
            query = query.concat(`&${key}=${value}`);
        }

        const INITIAL_REQ_OBJ = {
            type: "job_list",
        };

        try {
            const response = await axios.post(`${apiLink}?type=${INITIAL_REQ_OBJ.type}${query}`);
            return response.data;
        } catch (err) {
            console.error(err);
        }
    },
);

export const savejobApplication = createAsyncThunk(
    `${SLICE_NAME}/savejobApplication`,
    async ({ formData, apiLink }) => {
        const INITIAL_REQ_OBJ = {
            type: "job_applications",
        };

        const response = await axios.post(`${apiLink}?type=${INITIAL_REQ_OBJ.type}`, formData);
        return response.data;
    },
);

const initialState = {
    jobList: {},
    dynamicFilters: {},
    isLoading: true,
    successMessage: {},
};

export const jobSpotSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        // Example reducer:
        // fetchContentAndLayout: (state) => {
        //   const contentKeysMappedToLowercase = {}
        //   Object.keys(contentFromII.content).forEach(
        //     (k) => (contentKeysMappedToLowercase[k.toLowerCase()] = contentFromII.content[k]),
        //   )
        //   state.content = contentKeysMappedToLowercase
        //   state.layout = layoutFromII.layout
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(jobSpotListing.fulfilled, (state, action) => {
                const data = action.payload;
                state.jobList = data;
                let dynamicFilters = {};
                for (const filterKey in data.filters) {
                    if (Object.prototype.hasOwnProperty.call(data.filters, filterKey)) {
                        let filter = data.filters[filterKey];

                        if (filter.Items) {
                            dynamicFilters[filterKey] = {
                                Label: filter.Label,
                                Items: filter.Items.map((item) => ({
                                    itemName: item.value || item.location,
                                })),
                            };
                        }
                    }
                }

                state.dynamicFilters = dynamicFilters;
                state.isLoading = false;
            })
            .addCase(savejobApplication.fulfilled, (state, action) => {
                state.successMessage = action.payload;
            })
            .addMatcher(
                (action) => action.type.startsWith(SLICE_NAME) && action.type.endsWith("pending"),
                (state) => {
                    state.isLoading = true;
                },
            )
            .addMatcher(
                (action) => action.type.startsWith(SLICE_NAME) && action.type.endsWith("fulfilled"),
                (state) => {
                    state.isLoading = false;
                },
            )
            .addMatcher(
                (action) => action.type.startsWith(SLICE_NAME) && action.type.endsWith("rejected"),
                (state) => {
                    state.isLoading = false;
                },
            );
    },
});

export default jobSpotSlice.reducer;
