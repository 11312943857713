import ImageWithObjectFit from "../ImageWrapperComponent";
import "./HeroSectionLeft.css";
import parse from "html-react-parser";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOpenLink } from "../../utils/useOpenLink";
import useTranslation from "../../utils/useTranslation";
import { blockSourceString } from "../../utils/string";
import { articleType } from "../../utils/articleHelpers";
import * as posterImage from "../../utils/usePosterImage";
import { APP_COLORS } from "../../constants/styles";

function HeroSectionLeft({
    contents: contentToRender,
    heading,
    body,
    section_image,
    colors: colorObj,
    block_buttons,
    block_source,
}) {
    const openLink = useOpenLink();

    const { t } = useTranslation();

    const [contentReferenceData, setContentReferenceData] = useState([]);

    const contentFromStore = useSelector((state) => state.layout.content);

    const [heroSectionLeftDetails, setHeroSectionLeftDetails] = useState({
        heading: "",
        body: "",
        path: "",
        image: "",
        nid: "",
    });

    useEffect(() => {
        if (block_source === blockSourceString.block_details) {
            setHeroSectionLeftDetails({
                ...heroSectionLeftDetails,
                heading: heading,
                body: body,
                image: section_image?.src,
            });
        }

        if (block_source === blockSourceString.article_details) {
            setHeroSectionLeftDetails({
                ...heroSectionLeftDetails,
                heading: contentReferenceData[0]?.title,
                body: contentReferenceData[0]?.summary,
                path: contentReferenceData[0]?.path,
                image:
                    contentReferenceData[0]?.link === "" || contentReferenceData[0]?.link === null
                        ? contentReferenceData[0]?.image?.src
                        : contentReferenceData[0]?.posterImage,
                nid: contentReferenceData[0]?.nid,
            });
        }
    }, [contentReferenceData]);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (contentFromStore[contentKey]) {
                const respKeys = contentFromStore[contentKey];
                let resp = respKeys.map((k) => contentFromStore[k]);
                resp = JSON.parse(JSON.stringify(resp));
                for await (const element of resp) {
                    if (element && element.link) {
                        element.posterImage = await posterImage.usePosterImage(
                            element?.link,
                            element?.image,
                        );
                    }
                }
                if (resp.length) {
                    setContentReferenceData(resp);
                }
            }
        },
        [contentFromStore],
    );

    useEffect(() => {
        if (contentToRender.length > 0) {
            setCurrentContent(contentToRender[0].contentKey);
        }
    }, []);

    const handleClick = (path, nid) => {
        openLink(path, articleType, nid);
    };

    return (
        <>
            <div className="heroSectionLeftCardWrapper py-30" style={APP_COLORS(colorObj)}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="newsCardBig">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 pe-lg-0 pe-md-0 card-order d-flex align-items-center">
                                        <div className="card-body">
                                            <div
                                                className="heading"
                                                style={{ color: colorObj?.primary_color }}
                                            >
                                                {heroSectionLeftDetails.heading}
                                            </div>
                                            <div
                                                className="summaryText"
                                                style={{ color: colorObj?.accent_color1 }}
                                            >
                                                {heroSectionLeftDetails.body &&
                                                    parse(heroSectionLeftDetails.body)}
                                            </div>
                                            <>
                                                {block_source ===
                                                t("blockSourceString.block_details") ? (
                                                    block_buttons?.length > 0 && (
                                                        <div className="mt-auto">
                                                            {block_buttons.map((v) => (
                                                                <div
                                                                    className="btn btn-link button me-3"
                                                                    key={v.label}
                                                                    style={{
                                                                        "--primary_color":
                                                                            colorObj?.primary_color,
                                                                        "--background_color":
                                                                            colorObj?.background_color,
                                                                    }}
                                                                    onClick={() =>
                                                                        openLink(v.action)
                                                                    }
                                                                >
                                                                    {v.label}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )
                                                ) : (
                                                    <div
                                                        className="btn btn-link button me-3"
                                                        style={{
                                                            "--primary_color":
                                                                colorObj?.primary_color,
                                                            "--background_color":
                                                                colorObj?.background_color,
                                                        }}
                                                        onClick={() =>
                                                            handleClick(
                                                                heroSectionLeftDetails?.path,
                                                                heroSectionLeftDetails?.nid,
                                                            )
                                                        }
                                                    >
                                                        {t("btnText.learnMore")}
                                                    </div>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 pe-lg-0 pe-md-0">
                                        <div className="NewsImg">
                                            <ImageWithObjectFit
                                                className="cursor-pointer"
                                                src={heroSectionLeftDetails.image}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroSectionLeft;
