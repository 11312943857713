import React from "react";
import { Link } from "react-router-dom";
import { APP_COLORS } from "../../constants/styles";
import { useGetLink } from "../../utils/useOpenLink";

function InstantSearchModalArticleDetails({ hit, setSelectedArticle, colorObj }) {
    const { getLink } = useGetLink();

    return (
        <div
            className="card-main search-card cursor-pointer"
            onMouseEnter={() => setSelectedArticle(hit)}
            style={APP_COLORS(colorObj)}
        >
            <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                    <div className="img-card">
                        <img src={hit.image} />
                    </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-9 col-8">
                    <div className="card-body">
                        <h3>{hit.title}</h3>
                        <div className="tagsName">{hit.tags}</div>
                        <Link to={getLink(hit.path)}>
                            <div
                                className="arrow-icon-main"
                                onClick={() => {
                                    document.body.classList.remove("p-overflow-hidden");
                                }}
                            >
                                <i className="bi bi-chevron-right"></i>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InstantSearchModalArticleDetails;
