import React, { useCallback, useEffect, useState } from "react";
import "./ParagraphBlock.css";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { blockSourceString } from "../../utils/string";

function ParagraphBlock({
    contents: contentToRender = [],
    body,
    heading,
    block_source,
    colors: colorObj,
}) {
    const [paragraphSimpleDetails, setParagraphSimpleDetailsDetails] = useState({
        heading: "",
        body: "",
        image: "",
    });

    const [contentReferenceData, setContentReferenceData] = useState([]);

    const contentFromStore = useSelector((state) => state.layout.content);

    useEffect(() => {
        if (block_source === blockSourceString.block_details) {
            setParagraphSimpleDetailsDetails({
                ...paragraphSimpleDetails,
                body: body,
                heading: heading,
            });
        }

        if (block_source === blockSourceString.article_details) {
            setParagraphSimpleDetailsDetails({
                ...paragraphSimpleDetails,
                body: contentReferenceData[0]?.body,
                heading: contentReferenceData[0]?.title,
            });
        }
    }, [contentReferenceData]);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (contentFromStore[contentKey]) {
                const respKeys = contentFromStore[contentKey];
                const resp = respKeys.map((k) => contentFromStore[k]);

                if (resp.length) {
                    setContentReferenceData(resp);
                }
            }
        },
        [contentFromStore],
    );

    useEffect(() => {
        if (contentToRender.length > 0) {
            setCurrentContent(contentToRender[0].contentKey);
        }
    }, []);

    return (
        <>
            <div
                className="paragraphBlockSection py-50"
                style={{ background: colorObj?.background_color }}
            >
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            {paragraphSimpleDetails?.heading !== "" && (
                                <div className="col-12">
                                    <h3
                                        className="heading"
                                        style={{ color: colorObj?.accent_color1 }}
                                    >
                                        {paragraphSimpleDetails?.heading}
                                    </h3>
                                </div>
                            )}

                            {paragraphSimpleDetails?.body !== "" && (
                                <div className="col-12">
                                    <p
                                        className="description"
                                        style={{ color: colorObj?.accent_color1 }}
                                    >
                                        {paragraphSimpleDetails?.body &&
                                            parse(paragraphSimpleDetails?.body)}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ParagraphBlock;
