import React from "react";
import moment from "moment";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { decodeHtmlEntities } from "../../utils/urlUtils";
import { useGetLink } from "../../utils/useOpenLink";
import { getTextToDisplay } from "../../utils/getTextToDisplay";
import { Link } from "react-router-dom";

export const TrendingNowCard = ({ content, colorsObj }) => {
    const { image, date, title, microsite, path, link, posterImage, model_type, themes } = content;

    const { getLink } = useGetLink();

    const textToDisplay = getTextToDisplay(model_type, themes);

    return (
        <>
            <div className="card h-100">
                <Link to={getLink(path)}>
                    <div className="cardImg">
                        <ImageWithObjectFit
                            className="cursor-pointer"
                            src={link === "" || link === null ? image?.src : posterImage}
                        />
                    </div>
                </Link>
                <div className="card-body d-flex flex-column">
                    <div className="tag-heading" style={{ color: colorsObj.primary_color }}>
                        {microsite !== "" ? decodeHtmlEntities(microsite) : <>&nbsp;</>}
                    </div>
                    <Link to={getLink(path)}>
                        <h3
                            className="hover:underline mb-4 cursor-pointer"
                            style={{ color: colorsObj.accent_color1 }}
                        >
                            {title}
                        </h3>
                    </Link>
                    <div className="authorDateMain mt-auto">
                        <span className="author me-1" style={{ color: colorsObj.primary_color }}>
                            {textToDisplay}
                        </span>
                        <span className="date" style={{ color: colorsObj.accent_color3 }}>
                            {textToDisplay && date ? " | " : ""}
                            {moment(date).fromNow()}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};
