export const serverConfig = {
    apiKey: "xyz",
    nodes: [
        {
            host: "search.ncsect.org",
            port: "443",
            protocol: "https",
        },
    ],
};

export const QUERY_PARAMS = ["title"];
export const FACET_PARAMS = ["author", "microsite", "type"];
