import React from "react";
import "./Footer2.css";
import { useOpenLink } from "../../utils/useOpenLink";
import SocialLinks from "../SocialLinks";
import useTranslation from "../../utils/useTranslation";

function FooterComponent2({ contents: contentsToRender = [] }) {
    const openLink = useOpenLink();

    const { t } = useTranslation();

    return (
        <>
            <footer>
                <div className="logoAppWrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="logoAppHldr">
                                    <div className="logo">
                                        <img src="assets/images/footer_logo.svg" />
                                    </div>
                                    <div className="CommunityHldr">{t("footerText.aboutTxt")}</div>
                                    <div className="txtAppIcon">
                                        <div className="txtMian">
                                            {t("footerText.experience")}
                                            <br />
                                            <b>{t("footerText.ismailiApp")}</b>
                                            <br />
                                            {t("footerText.anyDevice")}
                                        </div>
                                        <div className="iconMain">
                                            <a href="#">
                                                <img src="assets/images/AppStore-icon.svg" />
                                            </a>
                                            <a href="#">
                                                <img
                                                    className="mb-0"
                                                    src="assets/images/googlePlay-icon.svg"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <SocialLinks />
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-12 linkMain">
                            <div className="row">
                                {contentsToRender.map((value) => (
                                    <div
                                        className="col-lg-2 col-md-4 col-sm-6 col-12"
                                        key={value.heading}
                                    >
                                        <h6>
                                            <span onClick={() => openLink(value.contentKey)}>
                                                {value.heading}
                                            </span>
                                        </h6>
                                        <ul>
                                            {value.contents &&
                                                value.contents.map((v) => (
                                                    <li key={v.heading}>
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                openLink(v.content_reference)
                                                            }
                                                        >
                                                            {v.heading}
                                                        </span>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copyRight">
                    <div className="mb-2">{t("footerText.copyRight")}</div>
                    <div className="terms">
                        <a className="me-3" href="#">
                            {t("footerText.privacyPolicy")}
                        </a>{" "}
                        <a href="#">{t("footerText.TermsAndCondition")}</a>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default FooterComponent2;
