import { Outlet } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { WindowContextProvider } from "./components/WindowResize/resize";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkLanguageInfo, checkToken } from "./redux/authSlice";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import useLoadLanguage from "./utils/useLoadLanguage";
import "swiper/css/grid";
import AuthModal from "./components/AuthModal";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "react-photo-view/dist/react-photo-view.css";
import MetaTags from "./utils/metaTagsFunction";
import { HelmetProvider } from "react-helmet-async";
import { getAuthToken } from "./utils/oauthHandler";
import { fetchStaticTraslateData } from "./redux/layoutSlice";

const App = () => {
    const dispatch = useDispatch();

    const metaTags = useSelector((state) => state.layout.metaTags);

    const { languageName } = useSelector((state) => state.auth);

    // check for a locally saved token and validate if it's active
    useEffect(() => {
        const token = getAuthToken();
        const details = localStorage.getItem("oauthServersDetails");
        const oauthDetails = JSON.parse(details);
        if (token !== null) {
            dispatch(checkToken(oauthDetails));
        }
    }, [dispatch]);

    useEffect(() => {
        if (languageName !== null) {
            dispatch(fetchStaticTraslateData(languageName));
        }
        if (languageName === null) dispatch(checkLanguageInfo());
    }, [dispatch, languageName]);

    useLoadLanguage();

    return (
        <div className="flex flex-col h-full">
            <WindowContextProvider>
                <HelmetProvider>
                    <MetaTags metaTags={metaTags} />
                </HelmetProvider>
                <Outlet />
                <AuthModal />
            </WindowContextProvider>
        </div>
    );
};

export default App;
