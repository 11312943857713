import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../utils/networkRequests";

const SLICE_NAME = "gallery";

export const fetchGalleryData = createAsyncThunk(
    `${SLICE_NAME}/fetchGalleryData`,
    async ({ reqObj, apiLink }) => {
        let query = "";

        for (const [key, value] of Object.entries(reqObj)) {
            query = query.concat(`&${key}=${value}`);
        }

        try {
            const response = await axiosInstance.get(`${apiLink}${query}`);
            return response.data;
        } catch (err) {
            console.error(err);
        }
    },
);

const initialState = {
    tags: [],
    languages: [],
    filters: {},
    contents: {},
    isLoading: true,
    dynamicFilters: {},
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const gallerySlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        // Example reducer:
        // fetchContentAndLayout: (state) => {
        //   const contentKeysMappedToLowercase = {}
        //   Object.keys(contentFromII.content).forEach(
        //     (k) => (contentKeysMappedToLowercase[k.toLowerCase()] = contentFromII.content[k]),
        //   )
        //   state.content = contentKeysMappedToLowercase
        //   state.layout = layoutFromII.layout
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGalleryData.fulfilled, (state, action) => {
                const data = action.payload;
                const contents = data;
                let tag = [];
                for (const iterator of data.filters.tags.Items) {
                    tag.push({
                        tagName: iterator,
                    });
                }

                let languages = [];
                for (const iterator of data.filters.content_language.Items) {
                    languages.push({
                        languageName: iterator,
                    });
                }
                let dynamicFilters = {};
                for (const filterKey in data.filters) {
                    if (Object.prototype.hasOwnProperty.call(data.filters, filterKey)) {
                        let filter = data.filters[filterKey];

                        if (filter.Items) {
                            dynamicFilters[filterKey] = {
                                Label: filter.Label,
                                Items: filter.Items.map((item) => ({ itemName: item })),
                            };
                        }
                    }
                }
                state.dynamicFilters = dynamicFilters;
                state.tags = tag;
                state.languages = languages;
                state.contents = contents;
                state.isLoading = false;
                state.filters = contents.filters;
            })
            .addMatcher(
                (action) => action.type.startsWith(SLICE_NAME) && action.type.endsWith("pending"),
                (state) => {
                    state.isLoading = true;
                },
            )
            .addMatcher(
                (action) => action.type.startsWith(SLICE_NAME) && action.type.endsWith("fulfilled"),
                (state) => {
                    state.isLoading = false;
                },
            )
            .addMatcher(
                (action) => action.type.startsWith(SLICE_NAME) && action.type.endsWith("rejected"),
                (state) => {
                    state.isLoading = false;
                },
            );
    },
});

export default gallerySlice.reducer;
