import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOpenLink } from "../../utils/useOpenLink";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { fetchApplicationTileLinks } from "../../redux/ImageApplicationsTileLinksSlice";
import "./ImageTileLinks.css";

function ImageTileLinks({ contents: contentsArray = [], colors: colorObj }) {
    const dispatch = useDispatch();
    const contentsData = useSelector(
        (state) => state.applicationImageTileLinks.applicationsData || [],
    );
    const openLink = useOpenLink();

    useEffect(() => {
        if (contentsArray.length > 0) {
            const apiLink = contentsArray[0].contentKey;
            dispatch(fetchApplicationTileLinks(apiLink));
        }
    }, [dispatch, contentsArray]);

    return (
        <section
            className="updateSecation py-50"
            style={{ background: colorObj?.background_color }}
        >
            <div className="container">
                <div className="row row-flex" style={{ justifyContent: "center" }}>
                    {contentsData.map((value, i) => (
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12" key={i}>
                            <div
                                className="post-card d-flex flex-column"
                                style={{
                                    "--accent_color4": colorObj?.accent_color4,
                                    "--primary_color": colorObj?.primary_color,
                                }}
                            >
                                <div className="iconMain">
                                    <ImageWithObjectFit
                                        className="cursor-pointer"
                                        src={value?.image?.src}
                                    />
                                </div>
                                <div
                                    className="cardTitle mt-3"
                                    style={{ "--primary_color": colorObj?.primary_color }}
                                >
                                    {value?.title}
                                </div>
                                <h3 style={{ "--accent_color1": colorObj?.accent_color1 }}>
                                    {value?.summary}
                                </h3>
                                <p style={{ "--accent_color3": colorObj?.accent_color3 }}>
                                    {value?.body}
                                </p>
                                <span
                                    className="btn btn-main"
                                    style={{
                                        "--accent_color4": colorObj?.accent_color4,
                                        "--primary_color": colorObj?.primary_color,
                                    }}
                                    onClick={() => openLink(value?.content_reference, value?.type)}
                                >
                                    {value?.link_text}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ImageTileLinks;
