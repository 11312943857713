import React from "react";
import { useSortBy } from "react-instantsearch";
import { Dropdown } from "primereact/dropdown";

const SortByComponent = ({ items, color }) => {
    const { currentRefinement, refine } = useSortBy({
        items,
    });

    return (
        <Dropdown
            onChange={(event) => refine(event.target.value)}
            value={currentRefinement}
            options={items}
            optionLabel="label"
            placeholder="Select"
            className="w-full md:w-14rem"
            style={{ "--accent_color1": color?.accent_color1 }}
        />
    );
};

export default SortByComponent;
