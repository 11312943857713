import React from "react";
import "./ProgrammeStdContent.css";
import parse from "html-react-parser";
import { Accordion, AccordionTab } from "primereact/accordion";

function ProgrammeStdContent({ body, heading, contents: contentToRender = [], colors: colorObj }) {
    return (
        <section
            className="Get-touch-wrapper py-50"
            style={{ background: colorObj?.background_color }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {heading !== "" && (
                            <h2 style={{ color: colorObj?.primary_color }}>{heading}</h2>
                        )}
                        {body !== "" && (
                            <p style={{ color: colorObj?.accent_color1 }}>{parse(body)}</p>
                        )}
                    </div>
                    <div className="col-12 mt-2">
                        <Accordion>
                            {contentToRender &&
                                contentToRender.map((items, index) => (
                                    <AccordionTab
                                        header={items.heading}
                                        key={index}
                                        style={{
                                            "--accent_color2": colorObj?.accent_color2,
                                            "--primary_color": `2px solid ${colorObj?.primary_color}`,
                                            "--primary_color1": colorObj?.primary_color,
                                        }}
                                    >
                                        <div style={{ color: colorObj?.accent_color1 }}>
                                            {items.body && parse(items.body)}
                                        </div>
                                    </AccordionTab>
                                ))}
                        </Accordion>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProgrammeStdContent;
