import React, { useEffect, useState } from "react";
import "./SpeechDetails.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchArticle } from "../../redux/layoutSlice";
import moment from "moment";
import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import ImageWithObjectFit from "../ImageWrapperComponent";
import BreadCrumbs from "../BreadCrumbs";
import useTranslation from "../../utils/useTranslation";

function SpeechDetails({ contents, colors: colorObj }) {
    const [didDispatch, setDidDispatch] = useState(false);

    const { state } = useLocation();

    const id = contents?.[0]?.id || state?.id;

    const dispatch = useDispatch();

    const content = useSelector((state) => state.layout.content);

    const { t } = useTranslation();

    useEffect(() => {
        if (!didDispatch) {
            dispatch(fetchArticle({ id }));
            setDidDispatch(true);
        }
    }, [didDispatch, content]);

    const {
        title,
        author,
        date,
        body,
        microsite,
        detailImage,
        speech_location,
        related_links,
        speech_speaker,
        model_type,
        themes,
    } = content[id] || {};

    return (
        <>
            <section className="speechDetailsSection padTop padBottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <BreadCrumbs model_type={model_type} themes={themes} />
                        </div>
                        <div className="col-12">
                            <h3
                                className="heading mt-3"
                                style={{ "--accent_color1": colorObj?.accent_color1 }}
                            >
                                {title}
                            </h3>
                        </div>
                        <div className="col-12 mt-3 d-flex p-0">
                            {author !== "" && (
                                <div className="col-md-6">
                                    <div
                                        className="author"
                                        style={{ "--accent_color3": colorObj?.accent_color3 }}
                                    >
                                        <span className="labelName me-1">
                                            {t("constantString.author:")}
                                        </span>
                                        {author}
                                    </div>
                                </div>
                            )}
                            <div className="col-md-12">
                                <div
                                    className="date"
                                    style={{ "--accent_color3": colorObj?.accent_color3 }}
                                >
                                    {date ? moment(date).format(`DD MMM YYYY`) : ""}
                                    {date && microsite ? " | " : ""}
                                    {microsite}
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            <div className="only-img">
                                <ImageWithObjectFit
                                    className="cursor-pointer"
                                    src={detailImage?.src}
                                />
                            </div>
                        </div>
                        {(speech_speaker !== "" ||
                            speech_location !== "" ||
                            related_links !== "") && (
                            <div className="col-12 mt-3">
                                <div
                                    className="card-box p-4"
                                    style={{ "--accent_color2": colorObj?.accent_color2 }}
                                >
                                    <div className="row">
                                        {speech_speaker !== "" && (
                                            <div
                                                className="col-md-4 col-12 mb-md-0 mb-sm-0 mb-3"
                                                style={{
                                                    "--accent_color1": colorObj?.accent_color1,
                                                    "--primary_color": colorObj?.primary_color,
                                                    "--accent_color2": colorObj?.accent_color3,
                                                }}
                                            >
                                                <div
                                                    className={
                                                        speech_location !== "" ||
                                                        related_links !== ""
                                                            ? "detailsMain"
                                                            : ""
                                                    }
                                                >
                                                    <h3 className="heading">
                                                        {t("constantString.speechDeliveredBy")}
                                                    </h3>
                                                    <div className="sub-heading">
                                                        {speech_speaker}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {speech_location !== "" && (
                                            <div
                                                className="col-md-4 col-12 mb-md-0 mb-sm-0 mb-3"
                                                style={{
                                                    "--accent_color1": colorObj?.accent_color1,
                                                    "--primary_color": colorObj?.primary_color,
                                                    "--accent_color3": colorObj?.accent_color3,
                                                }}
                                            >
                                                <div
                                                    className={
                                                        related_links !== "" ? "detailsMain" : ""
                                                    }
                                                >
                                                    <h3 className="heading">
                                                        {t("constantString.location")}
                                                    </h3>
                                                    <div className="sub-heading">
                                                        {speech_location}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {related_links !== "" && (
                                            <div
                                                className="col-md-4 col-12"
                                                style={{
                                                    "--accent_color1": colorObj?.accent_color1,
                                                    "--primary_color": colorObj?.primary_color,
                                                }}
                                            >
                                                <h3 className="heading">
                                                    {t("constantString.relatedLinks")}
                                                </h3>
                                                <div className="sub-heading1">
                                                    {parse(related_links ? related_links : "")}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="col-12 mt-3">
                            <div className="text">{parse(body ? body : "")}</div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SpeechDetails;
