import React, { useCallback, useEffect, useState } from "react";
import "./ProgrammeStdBody.css";
import parse from "html-react-parser";
import { Button } from "../Button";
import { useOpenLink } from "../../utils/useOpenLink";
import { useSelector } from "react-redux";
import { blockSourceString } from "../../utils/string";
import { articleType } from "../../utils/articleHelpers";
import useTranslation from "../../utils/useTranslation";

function ProgrammeStdBody({
    contents: contentToRender = [],
    body,
    block_buttons,
    block_source,
    colors: colorObj,
}) {
    const [programmeStdBodyDetails, setProgrammeStdBodyDetails] = useState({
        body: "",
        summary: "",
        path: "",
        nid: "",
    });

    const openLink = useOpenLink();

    const { t } = useTranslation();

    const [contentReferenceData, setContentReferenceData] = useState([]);

    const contentFromStore = useSelector((state) => state.layout.content);

    useEffect(() => {
        if (block_source === blockSourceString.block_details) {
            setProgrammeStdBodyDetails({ ...programmeStdBodyDetails, body: body });
        }

        if (block_source === blockSourceString.article_details) {
            setProgrammeStdBodyDetails({
                ...programmeStdBodyDetails,
                body: contentReferenceData[0]?.body,
                path: contentReferenceData[0]?.path,
                nid: contentReferenceData[0]?.nid,
            });
        }
    }, [contentReferenceData]);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (contentFromStore[contentKey]) {
                const respKeys = contentFromStore[contentKey];
                const resp = respKeys.map((k) => contentFromStore[k]);

                if (resp.length) {
                    setContentReferenceData(resp);
                }
            }
        },
        [contentFromStore],
    );

    useEffect(() => {
        if (contentToRender.length > 0) {
            setCurrentContent(contentToRender[0].contentKey);
        }
    }, []);

    const handleClick = (path, nid) => {
        openLink(path, articleType, nid);
    };

    return (
        <div className="programme-std-body-container py-50">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="programme-std-body-content current">
                            <p style={{ color: colorObj?.accent_color1 }}>
                                {programmeStdBodyDetails?.body &&
                                    parse(programmeStdBodyDetails?.body)}
                            </p>
                            <>
                                {block_source === blockSourceString.block_details ? (
                                    block_buttons?.length > 0 && (
                                        <div className="btnHldr">
                                            {block_buttons.map((v) => (
                                                <Button
                                                    style={{
                                                        border: `1px solid ${colorObj?.primary_color}`,
                                                        color: colorObj?.accent_color1,
                                                    }}
                                                    key={v.label}
                                                    className="link"
                                                    onClick={() => openLink(v.action)}
                                                >
                                                    {v.label}
                                                    <i
                                                        className="fa fa-angle-right ms-2"
                                                        aria-hidden="true"
                                                    ></i>
                                                </Button>
                                            ))}
                                        </div>
                                    )
                                ) : (
                                    <div className="btnHldr">
                                        <Button
                                            style={{
                                                border: `1px solid ${colorObj?.primary_color}`,
                                                color: colorObj?.accent_color1,
                                            }}
                                            className="link"
                                            onClick={() =>
                                                handleClick(
                                                    programmeStdBodyDetails?.path,
                                                    programmeStdBodyDetails?.nid,
                                                )
                                            }
                                        >
                                            {t("btnText.ReadMore")}
                                            <i
                                                className="fa fa-angle-right ms-2"
                                                aria-hidden="true"
                                            ></i>
                                        </Button>
                                    </div>
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProgrammeStdBody;
