import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectShowModal, setOpenDropdown } from "../../redux/authSlice";
import { Button } from "../Button";
import "./AuthModal.css";
import { Dialog } from "primereact/dialog";
import useTranslation from "../../utils/useTranslation";

function AuthModal() {
    const [shouldShowModal, setShouldShowModal] = useState(false);

    const showModal = useSelector(selectShowModal);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    useEffect(() => {
        if (showModal) {
            setTimeout(() => {
                setShouldShowModal(true);
            }, 200);
        } else {
            setShouldShowModal(false);
        }
    }, [showModal]);

    const clickOnLogin = () => {
        setShouldShowModal(false);
        dispatch(setOpenDropdown(true));
    };

    return (
        <>
            {shouldShowModal && (
                <Dialog
                    visible={shouldShowModal}
                    onHide={() => {
                        if (!shouldShowModal) return;
                        setShouldShowModal(false);
                    }}
                    style={{ width: "30vw" }}
                    draggable={false}
                >
                    <div className="loginModal">
                        <div className="modal-header">
                            <img
                                src="assets/images/not-loggedIn-user.png"
                                alt="Not Logged In"
                            ></img>
                        </div>
                        <div className="modal-body">
                            <h3>{t("unAuthorizedUserString.unAuthorizedText")}</h3>
                            <div className="mt-3 mb-3">
                                <Button className="button" onClick={() => clickOnLogin()}>
                                    {t("unAuthorizedUserString.login")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
        </>
    );
}

export default AuthModal;
