import React, { useContext, useEffect, useState } from "react";
import "./GallerySection2.css";
import { MultiSelect } from "primereact/multiselect";
import { useDispatch, useSelector } from "react-redux";
import { fetchGalleryData } from "../../redux/gallerySlice";
import Loader from "../Loader/loader";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { ScrollTop } from "../../utils/scrollTopFunction";
import { WindowContext } from "../WindowResize/resize";
import { Button } from "../Button";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import parse from "html-react-parser";
import useTranslation from "../../utils/useTranslation";
import { useGetLink } from "../../utils/useOpenLink";
import * as posterImage from "../../utils/usePosterImage";
import { getTextToDisplay } from "../../utils/getTextToDisplay";

function GallerySection2({ heading, body, contents: contentsArray, colors: colorObj }) {
    const { pathname, search } = useLocation();

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const { getLink } = useGetLink();

    const { t } = useTranslation();

    const { isMobile } = useContext(WindowContext);

    const dynamicFilters = useSelector((state) => state.gallery.dynamicFilters);

    const isLoading = useSelector((state) => state.gallery.isLoading);

    const [totalPageCount, setTotalPageCount] = useState(0);

    const [articleDetails, setArticleDetails] = useState([]);

    const [searchKeyword, setSearchKeyword] = useState("");

    const content = useSelector((state) => state.gallery.contents);

    const [visible, setVisible] = useState(false);

    const [selectedFilters, setSelectedFilters] = useState({});

    const [prevParams, setPrevParams] = useState({});

    const [paramsReq, setParamsReq] = useState({
        page: 0,
        page_size: 12,
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(search);

        const req = { ...paramsReq };
        const selectedFilters = {};

        queryParams.forEach((value, key) => {
            if (key.startsWith("search_") && !key.startsWith("search_text")) {
                const filterKey = key.slice(7);
                const filterValues = value ? value.split(",") : [];

                if (filterValues.length > 0) {
                    let saveItems = [];
                    filterValues.forEach((item) => {
                        if (item !== "") {
                            saveItems.push({ itemName: decodeURIComponent(item) });
                        }
                    });

                    selectedFilters[filterKey] = saveItems;
                    req[`search_${filterKey}`] = filterValues;
                }
            } else if (key === "search_text") {
                setSearchKeyword(value);
                req.search_text = value;
            } else {
                req[key] = value;
            }
        });

        setSelectedFilters(selectedFilters);
        setParamsReq(req);
    }, [search]);

    useEffect(() => {
        if (
            Object.keys(paramsReq).length > 0 &&
            JSON.stringify(paramsReq) !== JSON.stringify(prevParams)
        ) {
            setPrevParams(paramsReq);
            let reqObj = { ...paramsReq };
            reqObj.page = reqObj.page + 1;
            const apiLink = contentsArray[0].contentKey.slice(0, -1);
            dispatch(fetchGalleryData({ reqObj, apiLink }));
        }
    }, [paramsReq, dispatch, prevParams]);

    useEffect(() => {
        const queryParams = [];

        for (const key in paramsReq) {
            if (
                paramsReq[key] &&
                key.startsWith("search_") &&
                !key.startsWith("search_text") &&
                paramsReq[key].length > 0
            ) {
                queryParams.push(`${key}=${paramsReq[key].map(encodeURIComponent).join(",")}`);
            }
        }

        if (searchKeyword !== "") {
            queryParams.push(`search_text=${encodeURIComponent(searchKeyword)}`);
        }

        navigate(`/${pathname.slice(1)}?${queryParams.join("&")}`);
    }, [paramsReq, navigate, searchKeyword, pathname]);

    const fetchGalleryDetails = async () => {
        setArticleDetails([]);
        setTotalPageCount(0);

        if (content) {
            let articleArr = [];
            articleArr = articleArr.concat(content.articles);
            articleArr = JSON.parse(JSON.stringify(articleArr));
            for await (const element of articleArr) {
                if (element && element.link) {
                    element.posterImage = await posterImage.usePosterImage(element?.link);
                }
            }
            setArticleDetails(articleArr);
            setTotalPageCount(content.total);
        }
    };

    useEffect(() => {
        const getData = async () => {
            await fetchGalleryDetails();
        };
        getData();
    }, [content, isLoading]);

    const onPageChange = (event) => {
        setParamsReq({ ...paramsReq, page_size: event.rows, page: event.page });
        ScrollTop();
    };

    const onChangeSelectFilter = (value, filterName) => {
        let arr = [];
        let arrWithItemName = [];

        for (const iterator of value) {
            let str = encodeURIComponent(iterator.itemName);
            arr.push(str);
            arrWithItemName.push({ itemName: iterator.itemName });
        }

        const key = `search_${filterName.toLowerCase()}`;

        setParamsReq((prevState) => ({
            ...prevState,
            [key]: arr,
            page: 0,
        }));
        setSelectedFilters((prev) => ({
            ...prev,
            [filterName]: arrWithItemName,
        }));
    };

    const clearAllFilter = () => {
        setParamsReq({
            page: 0,
            page_size: 12,
        });
        setSelectedFilters({});
        navigate("/", { replace: true });

        setTimeout(() => {
            const cleanUrl = `/${pathname.slice(1)}`;
            navigate(cleanUrl, { replace: true });
        }, 0);
    };

    const clearSearch = () => {
        setParamsReq({
            ...paramsReq,
            search_text: "",
        });
        setSearchKeyword("");
    };

    const applyMobileFilters = () => {
        setVisible(false);
    };

    return (
        <>
            <Loader isLoading={isLoading} />

            <section className="gallerySection2 padTopBottom" style={{ background: "#F8F8F8" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="heading" style={{ color: colorObj?.accent_color1 }}>
                                {heading}
                            </div>
                            <div className="body" style={{ color: colorObj?.accent_color1 }}>
                                {body && parse(body)}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="gallerySection2 mb-50 pt-0 pb-0">
                <div className="container">
                    <div className="row">
                        <div className="filtersHldr">
                            <div className="col-12">
                                <div
                                    className="filters-main"
                                    style={{
                                        borderTop: `1px solid ${colorObj?.accent_color2}`,
                                        borderBottom: `1px solid ${colorObj?.accent_color2}`,
                                    }}
                                >
                                    <div className="col-lg-9 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                        <div
                                            className="filter-label"
                                            style={{
                                                color: colorObj?.accent_color3,
                                            }}
                                        >
                                            {t("btnText.filters:")}
                                        </div>

                                        {Object.keys(dynamicFilters).map(
                                            (filterKey) =>
                                                dynamicFilters[filterKey].Items &&
                                                dynamicFilters[filterKey].Items.length > 0 && (
                                                    <div
                                                        className="tag-dropdown-main"
                                                        key={filterKey}
                                                    >
                                                        <MultiSelect
                                                            placeholder={
                                                                dynamicFilters[filterKey].Label
                                                            }
                                                            value={selectedFilters[filterKey]}
                                                            onChange={(e) =>
                                                                onChangeSelectFilter(
                                                                    e.value,
                                                                    filterKey,
                                                                )
                                                            }
                                                            options={
                                                                dynamicFilters[filterKey].Items ||
                                                                []
                                                            }
                                                            optionLabel="itemName"
                                                            selectedItemsLabel={`${selectedFilters[
                                                                filterKey
                                                            ]
                                                                ?.slice(0, 2)
                                                                ?.map((i) => i.itemName)
                                                                ?.join(", ")}${
                                                                selectedFilters[filterKey]?.length >
                                                                2
                                                                    ? " ..."
                                                                    : ""
                                                            }`}
                                                            maxSelectedLabels={0}
                                                            filter
                                                            style={{
                                                                "--accent_color1":
                                                                    colorObj?.accent_color1,
                                                            }}
                                                        />
                                                    </div>
                                                ),
                                        )}

                                        {Object.keys(selectedFilters).length > 0 && (
                                            <div
                                                className="clear-all-txt"
                                                style={{ color: colorObj?.primary_color }}
                                                onClick={() => clearAllFilter()}
                                            >
                                                {t("btnText.clearAll")}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                        <div className="search-hldr">
                                            <i className="fa fa-search"></i>
                                            {searchKeyword !== "" && (
                                                <span
                                                    className="cursor-pointer"
                                                    onClick={clearSearch}
                                                >
                                                    <i className="search1 fa fa-close"></i>
                                                </span>
                                            )}
                                            <input
                                                type="text"
                                                className="form-control ml-2"
                                                placeholder={t("placeHolder.search")}
                                                value={searchKeyword}
                                                onChange={(e) => {
                                                    setSearchKeyword(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mobileFiltersHldr">
                            <div className="col-12">
                                <div className="search-hldr">
                                    <i className="fa fa-search"></i>
                                    {searchKeyword !== "" && (
                                        <span className="cursor-pointer" onClick={clearSearch}>
                                            <i className="search1 fa fa-close"></i>
                                        </span>
                                    )}
                                    <input
                                        type="text"
                                        className="form-control ml-2"
                                        placeholder={t("placeHolder.search")}
                                        value={searchKeyword}
                                        onChange={(e) => {
                                            setSearchKeyword(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="filter-btn" onClick={() => setVisible(true)}>
                                    <Button className="button">
                                        <i className="bi bi-sliders me-2"></i>
                                        {t("btnText.filters")}
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 my-4">
                            <div className="row">
                                {articleDetails.length > 0 && (
                                    <>
                                        {articleDetails.map((value, index) => (
                                            <div className="col-md-4 mb-4" key={index}>
                                                {value && (
                                                    <div className="newsCard card">
                                                        <div className="cardImg">
                                                            {value.link !== null && (
                                                                <div
                                                                    className="playIconHldr"
                                                                    style={{
                                                                        "--accent_color4":
                                                                            colorObj.accent_color4,
                                                                    }}
                                                                >
                                                                    <i className="bi bi-play-circle"></i>
                                                                </div>
                                                            )}
                                                            <Link
                                                                to={getLink(value.path)}
                                                                className="cursor-pointer"
                                                            >
                                                                <ImageWithObjectFit
                                                                    className="cursor-pointer"
                                                                    src={
                                                                        value?.link === "" ||
                                                                        value?.link === null
                                                                            ? value?.image?.src
                                                                            : value?.posterImage
                                                                    }
                                                                />
                                                            </Link>
                                                        </div>
                                                        <div className="card-body d-flex flex-column">
                                                            {value.primary_theme && (
                                                                <div
                                                                    className="tagName"
                                                                    style={{
                                                                        color: colorObj?.primary_color,
                                                                    }}
                                                                >
                                                                    {value.primary_theme}
                                                                </div>
                                                            )}
                                                            <Link
                                                                to={getLink(value.path)}
                                                                className="hover:underline cursor-pointer title"
                                                                style={{
                                                                    color: colorObj?.accent_color1,
                                                                }}
                                                            >
                                                                {value && value.title
                                                                    ? value.title
                                                                    : ""}
                                                            </Link>

                                                            <div className="authorDateMain mt-auto">
                                                                <span
                                                                    className="author me-1"
                                                                    style={{
                                                                        "--accent_color1":
                                                                            colorObj.accent_color1,
                                                                    }}
                                                                >
                                                                    {getTextToDisplay(
                                                                        value?.model_type,
                                                                        value?.themes,
                                                                    )}
                                                                </span>
                                                                <span
                                                                    className="date"
                                                                    style={{
                                                                        "--accent_color3":
                                                                            colorObj.accent_color3,
                                                                    }}
                                                                >
                                                                    {getTextToDisplay(
                                                                        value?.model_type,
                                                                        value?.themes,
                                                                    ) && value.date
                                                                        ? " | "
                                                                        : ""}
                                                                    {moment(value.date).fromNow()}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                        {articleDetails.length > 0 && (
                            <div
                                style={{
                                    "--primary_color": colorObj?.primary_color,
                                    "--accent_color4": colorObj?.accent_color4,
                                }}
                            >
                                <Paginator
                                    first={paramsReq.page * paramsReq.page_size}
                                    rows={paramsReq.page_size}
                                    totalRecords={totalPageCount}
                                    onPageChange={onPageChange}
                                    pageLinkSize={isMobile ? 1 : 5}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div className="filters-modal">
                    <Dialog
                        visible={visible}
                        onHide={() => {
                            if (!visible) return;
                            setVisible(false);
                        }}
                        maximized
                    >
                        <div className="filters-modal-header d-flex">
                            <div className="title" style={{ color: colorObj?.accent_color1 }}>
                                {t("btnText.filterBy")}
                            </div>
                            <div
                                className="clear-all-txt"
                                onClick={clearAllFilter}
                                style={{
                                    color: colorObj?.accent_color2,
                                    borderBottom: `1px solid ${colorObj?.accent_color2}`,
                                }}
                            >
                                {t("btnText.clearAll")}
                            </div>
                        </div>

                        {Object.keys(dynamicFilters).map(
                            (filterKey) =>
                                dynamicFilters[filterKey].Items &&
                                dynamicFilters[filterKey].Items.length > 0 && (
                                    <div className="tag-filters-main" key={filterKey}>
                                        <MultiSelect
                                            placeholder={dynamicFilters[filterKey].Label}
                                            value={selectedFilters[filterKey]}
                                            onChange={(e) => {
                                                onChangeSelectFilter(e.value, filterKey);
                                            }}
                                            options={dynamicFilters[filterKey].Items || []}
                                            optionLabel="itemName"
                                            maxSelectedLabels={
                                                dynamicFilters[filterKey].Items
                                                    ? dynamicFilters[filterKey].Items.length
                                                    : 3
                                            }
                                            filter
                                            style={{ "--accent_color1": colorObj?.accent_color1 }}
                                        />
                                    </div>
                                ),
                        )}

                        <div className="col-12">
                            <div className="filter-apply-btn">
                                <Button className="button" onClick={applyMobileFilters}>
                                    {t("btnText.apply")}
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </section>
        </>
    );
}

export default GallerySection2;
