import React, { useContext, useEffect, useState } from "react";
import "./TrendingNow.css";
import { TrendingNowCard } from "../Card/TrendingNowCard";
import { useSelector } from "react-redux";
import parse from "html-react-parser";

import { Swiper, SwiperSlide } from "swiper/react";
import { swiperCarousel } from "../../utils/carouselFunction";
import useCustomTranslation from "../CustomTranslation";
import { Button } from "../Button";
import { Dropdown } from "primereact/dropdown";
import { WindowContext } from "../WindowResize/resize";
import { useOpenLink } from "../../utils/useOpenLink";
import * as posterImage from "../../utils/usePosterImage";

export const TrendingNowCarousel = ({
    contents: contentsArray,
    body,
    heading,
    colors: colorObj,
}) => {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const [selectedIndex, setSelectedIndex] = useState(0);

    const [selectedTag, setSelectedTag] = useState(null);

    const content = useSelector((state) => state.layout.content);

    const { isMobile } = useContext(WindowContext);

    const [trendingNowTags, setTrendingNowTags] = useState([]);

    const [contentButtonArr, setContentButtonArr] = useState([]);

    const openLink = useOpenLink();

    useEffect(() => {
        const fetchDefaultData = async () => {
            let tags = [];
            for (const iterator of contentsArray) {
                const respKeys = content[iterator.contentKey];
                let resp = (respKeys || []).map((k) => content[k]).slice(0, 3);
                resp = JSON.parse(JSON.stringify(resp));
                for await (const element of resp) {
                    if (element && element.link) {
                        element.posterImage = await posterImage.usePosterImage(element?.link);
                    }
                }
                setContentReferenceData(resp);
                if (resp.length > 0) {
                    tags.push({
                        tagName: iterator.heading,
                        detailsArray: resp,
                        button: iterator.content_buttons,
                    });
                }
            }
            setTrendingNowTags(tags);
        };
        fetchDefaultData();
    }, [content, contentsArray]);

    useEffect(() => {
        if (trendingNowTags.length > 0) {
            setContentReferenceData(trendingNowTags[0]?.detailsArray);
            setContentButtonArr(trendingNowTags[0]?.button);
        }
        setSelectedTag(trendingNowTags[0]?.tagName);
    }, [trendingNowTags]);

    const { direction, t } = useCustomTranslation();

    const handleDropdownChange = (selectedOption) => {
        const selectedHeading = selectedOption.value;
        setSelectedTag(selectedHeading);

        const selectedContent = trendingNowTags.find(
            (content) => content.tagName === selectedHeading,
        );

        const contentReference = selectedContent.detailsArray;

        setContentReferenceData(contentReference);
    };

    return (
        <>
            <div
                className="trendingWrapper py-50"
                style={{ background: colorObj?.background_color }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1
                                className="text-center mb-3"
                                style={{ color: colorObj?.primary_color }}
                            >
                                {heading}
                                <div
                                    className="line"
                                    style={{ background: colorObj?.primary_color }}
                                ></div>
                            </h1>
                            <p
                                className="header-body-text"
                                style={{ color: colorObj?.accent_color1 }}
                            >
                                {body ? parse(body) : ""}
                            </p>
                        </div>

                        <div className="col-12">
                            {contentReferenceData.length > 0 && (
                                <div className="TrendingtabWrapper">
                                    {!isMobile ? (
                                        <ul
                                            className="nav nav-pills justify-content-center TrendingtabHldr"
                                            id="pills-tab"
                                        >
                                            {trendingNowTags.map((value, index) => (
                                                <li
                                                    className="nav-item"
                                                    role="presentation"
                                                    key={value.tagName}
                                                    onClick={() => {
                                                        setSelectedIndex(index);
                                                        setContentReferenceData(value.detailsArray);
                                                        setSelectedTag(value.tagName);
                                                        setContentButtonArr(value.button);
                                                    }}
                                                >
                                                    <button
                                                        className={`nav-link ${
                                                            selectedIndex === index ? "active" : ""
                                                        } `}
                                                        id="tab1"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#Trending1"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="Trending1"
                                                        aria-selected="true"
                                                        style={{
                                                            background:
                                                                selectedIndex === index
                                                                    ? colorObj?.primary_color
                                                                    : colorObj?.accent_color2,
                                                            color:
                                                                selectedIndex === index
                                                                    ? colorObj?.accent_color4
                                                                    : colorObj?.accent_color1,
                                                        }}
                                                    >
                                                        {value.tagName}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <Dropdown
                                            value={selectedTag}
                                            options={trendingNowTags.map((tags) => tags.tagName)}
                                            onChange={handleDropdownChange}
                                            placeholder={t("placeHolder.selectTag")}
                                            className="customDropdown"
                                            style={{
                                                border: `1px solid ${colorObj?.primary_color}`,
                                                "--accent_color1": colorObj?.accent_color1,
                                            }}
                                            valueTemplate={(option, props) =>
                                                option ? (
                                                    <div style={{ color: colorObj?.accent_color1 }}>
                                                        {option}
                                                    </div>
                                                ) : (
                                                    <span
                                                        style={{ color: colorObj?.accent_color1 }}
                                                    >
                                                        {props.placeholder}
                                                    </span>
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            )}
                        </div>

                        {contentReferenceData?.length > 0 ? (
                            <>
                                <div className="col-12">
                                    <Swiper
                                        dir={direction}
                                        key={direction}
                                        {...swiperCarousel}
                                        style={{ "--primary_color": colorObj?.primary_color }}
                                    >
                                        {contentReferenceData.map((card) => (
                                            <SwiperSlide key={card.title}>
                                                <TrendingNowCard
                                                    content={card}
                                                    colorsObj={colorObj}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>

                                {contentButtonArr?.length > 0 && (
                                    <div className="col-12 d-flex justify-content-center">
                                        {contentButtonArr.map((v) => (
                                            <Button
                                                className="button me-3"
                                                style={{ color: colorObj?.accent_color3 }}
                                                onClick={() => openLink(v.action)}
                                                key={v.label}
                                            >
                                                {v.label}
                                                <i
                                                    className="bi bi-arrow-right btn-icon"
                                                    aria-hidden="true"
                                                ></i>
                                            </Button>
                                        ))}
                                    </div>
                                )}
                            </>
                        ) : (
                            <h3 className="norecords mt-4 mb-4">{t("constantString.noRecords")}</h3>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
