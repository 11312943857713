import React from "react";
import "./EventDetails2.css";
import { useLocation } from "react-router-dom";
import { useOpenLink } from "../../utils/useOpenLink";
import parse from "html-react-parser";
import moment from "moment";
import ImageWithObjectFit from "../ImageWrapperComponent";
import BreadCrumbs from "../BreadCrumbs";
import ShareOnSocialLinks from "../ShareOnSocialLinks";
import useTranslation from "../../utils/useTranslation";

function EventDetails2({ colors: colorObj }) {
    const l = useLocation();

    const { image, title, start, description, venue, tags, cost, register, calender_link } =
        l.state.eventDetails;

    const openLink = useOpenLink();

    const { t } = useTranslation();

    return (
        <>
            <section className="eventDetWrapper" style={{ background: colorObj?.accent_color2 }}>
                <div className="container">
                    <div className="row eventDet-hldr">
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <BreadCrumbs lableName="Events" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 card-order">
                            <div className="eventDet-text">
                                <div className="tagMain" style={{ color: colorObj?.primary_color }}>
                                    {tags}
                                </div>
                                <h3 style={{ color: colorObj?.accent_color1 }}>{title}</h3>
                                <ShareOnSocialLinks />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 eventDet-img-hldr">
                            <div className="eventDet-img">
                                <ImageWithObjectFit
                                    src={
                                        image.src
                                            ? image.src
                                            : location.origin + "/assets/images/event-default.png"
                                    }
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="eventDet-TextSecation">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12 card-order ">
                            <div
                                className="eventDet-textHldr"
                                style={{ color: colorObj?.accent_color1 }}
                            >
                                {parse(description)}
                                {register && (
                                    <div
                                        className="RegisterBtn mt-5 cursor-pointer"
                                        style={{
                                            background: colorObj?.primary_color,
                                            color: colorObj?.accent_color4,
                                        }}
                                        onClick={() => openLink(register)}
                                    >
                                        {t("btnText.register")}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="dateTime-hldr">
                                {register && (
                                    <div
                                        className="RegisterBtn mb-5 cursor-pointer"
                                        style={{
                                            background: colorObj?.primary_color,
                                            color: colorObj?.accent_color4,
                                        }}
                                        onClick={() => openLink(register)}
                                    >
                                        {t("btnText.register")}
                                    </div>
                                )}

                                {start && (
                                    <>
                                        <div className="dateTime-main">
                                            <div
                                                className="title"
                                                style={{ color: colorObj?.accent_color3 }}
                                            >
                                                {t("constantString.date")}
                                            </div>
                                            <div
                                                className="subTxt"
                                                style={{ color: colorObj?.accent_color1 }}
                                            >
                                                <p>{moment(start).format(`MMM DD${","} YYYY`)}</p>
                                            </div>
                                        </div>

                                        <div className="dateTime-main">
                                            <div
                                                className="title"
                                                style={{ color: colorObj?.accent_color3 }}
                                            >
                                                {t("constantString.time")}
                                            </div>
                                            <div
                                                className="subTxt"
                                                style={{ color: colorObj?.accent_color1 }}
                                            >
                                                {moment(start, "HH:mm:ss").format("hh:mm A")}
                                            </div>
                                            <div
                                                className="link cursor-pointer"
                                                onClick={() => openLink(calender_link)}
                                                style={{ color: colorObj?.primary_color }}
                                            >
                                                {t("constantString.addToCalendar")}
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className="dateTime-main">
                                    {cost && (
                                        <>
                                            <div
                                                className="title"
                                                style={{ color: colorObj?.accent_color3 }}
                                            >
                                                {t("constantString.cost")}
                                            </div>
                                            <div
                                                className="subTxt"
                                                style={{ color: colorObj?.accent_color1 }}
                                            >
                                                {cost}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="dateTime-main">
                                    {venue && (
                                        <>
                                            <div
                                                className="title"
                                                style={{ color: colorObj?.accent_color3 }}
                                            >
                                                {t("constantString.location")}
                                            </div>
                                            <div
                                                className="subTxt"
                                                style={{ color: colorObj?.accent_color1 }}
                                            >
                                                {venue}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="dateTime-main">
                                    <div
                                        className="title"
                                        style={{ color: colorObj?.accent_color3 }}
                                    >
                                        {t("constantString.region")}
                                    </div>
                                    <div
                                        className="subTxt"
                                        style={{ color: colorObj?.accent_color1 }}
                                    >
                                        {t("constantString.region")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default EventDetails2;
