import React, { useCallback, useEffect, useState } from "react";
import "./FeatureCarousel3.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import { Navigation, Pagination } from "swiper/modules";
import useCustomTranslation from "../CustomTranslation";
import parse from "html-react-parser";
import { useOpenLink } from "../../utils/useOpenLink";
import { articleType } from "../../utils/articleHelpers";
import useTranslation from "../../utils/useTranslation";
import { blockSourceString } from "../../utils/string";
import * as posterImage from "../../utils/usePosterImage";
import ImageWithObjectFit from "../ImageWrapperComponent";

function FeatureCarousel3({ contents: contentToRender = [], colors: colorObj, block_source }) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const { direction } = useCustomTranslation();

    const { t } = useTranslation();

    const openLink = useOpenLink();

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (block_source === blockSourceString.article_details) {
                if (contentKey && content[contentKey]) {
                    const respKeys = content[contentKey];
                    let resp = respKeys.map((k) => content[k]);
                    resp = JSON.parse(JSON.stringify(resp));
                    for await (const element of resp) {
                        if (element && element.link) {
                            element.posterImage = await posterImage.usePosterImage(
                                element?.link,
                                element?.image,
                            );
                        }
                    }
                    if (resp.length) {
                        setContentReferenceData(resp);
                    }
                }
            }
        },
        [content],
    );

    useEffect(() => {
        if (contentToRender.length > 0 && block_source === blockSourceString.article_details) {
            setCurrentContent(contentToRender[0].contentKey);
        } else {
            const arr = contentToRender.map((c) => {
                return {
                    image: c.content_image,
                    title: c.heading,
                    summary: c.body,
                    nid: "",
                    content_buttons: c.content_buttons,
                };
            });
            setContentReferenceData(arr);
        }
    }, []);

    const settingsSwiper = {
        spaceBetween: 0,
        slidesPerView: 1,
        modules: [Pagination, Navigation],
        pagination: { clickable: true, dynamicBullets: true, dynamicMainBullets: 7 },
        navigation: true,
        dir: direction,
        key: direction,
    };

    const handleClick = (path, nid) => {
        openLink(path, articleType, nid);
    };

    return (
        <>
            <div className="FeatureCarouselWrapper2 py-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Swiper
                                {...settingsSwiper}
                                style={{
                                    "--accent_color4": colorObj?.accent_color4,
                                    "--accent_color2": colorObj?.accent_color2,
                                }}
                            >
                                {contentReferenceData.map((card) => {
                                    return (
                                        <SwiperSlide key={card.title}>
                                            <div className="featureImgHldr">
                                                <ImageWithObjectFit
                                                    className="cursor-pointer"
                                                    src={
                                                        card?.link
                                                            ? card?.posterImage
                                                            : card?.image?.src
                                                    }
                                                />
                                                <div className="txtBoxHldr2">
                                                    <h2
                                                        style={{
                                                            "--primary_color":
                                                                colorObj?.primary_color,
                                                        }}
                                                    >
                                                        {card.title}
                                                    </h2>
                                                    <p
                                                        style={{
                                                            "--accent_color1":
                                                                colorObj?.accent_color1,
                                                        }}
                                                    >
                                                        {card?.summary && parse(card?.summary)}
                                                    </p>
                                                    {block_source ===
                                                    blockSourceString.article_details ? (
                                                        <span
                                                            className="readingLink cursor-pointer"
                                                            style={{
                                                                "--accent_color4":
                                                                    colorObj?.accent_color4,
                                                                "--primary_color":
                                                                    colorObj?.primary_color,
                                                            }}
                                                            onClick={() =>
                                                                handleClick(card.path, card.nid)
                                                            }
                                                        >
                                                            {t("btnText.ReadMore")}
                                                            <i className="bi bi-chevron-right ms-2"></i>
                                                        </span>
                                                    ) : (
                                                        card?.content_buttons?.length > 0 &&
                                                        card?.content_buttons.map((v) => (
                                                            <span
                                                                className="readingLink cursor-pointer"
                                                                style={{
                                                                    "--accent_color4":
                                                                        colorObj?.accent_color4,
                                                                    "--primary_color":
                                                                        colorObj?.primary_color,
                                                                }}
                                                                key={v.label}
                                                                onClick={() => openLink(v.action)}
                                                            >
                                                                {v.label}
                                                                <i className="bi bi-chevron-right ms-2"></i>
                                                            </span>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FeatureCarousel3;
