import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import "./InstantSearch.css";
import "instantsearch.css/themes/satellite.css";
import { ClearRefinements, Configure, InstantSearch } from "react-instantsearch";
import "./InstantSearch.css";
import { Button } from "../Button";
import { FACET_PARAMS, QUERY_PARAMS, serverConfig } from "../../utils/instantSearchConfig";
import InstantSearchArticleDetails from "./InstantSearchArticleDetails";
import { useContext, useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import { instantSearchString } from "../../utils/string";
import CustomSearchBox from "./InstantCustomSearchBox";
import SortByComponent from "./InstantSearchSort";
import TotalRecords from "./TotalRecords";
import { useLocation, useNavigate } from "react-router-dom";
import GenericMicrositeFilter from "../InstantSearchFilters/GenericMicroSiteFilter";
import GenericAuthorFilter from "../InstantSearchFilters/GenericAuthorFilter";
import GenericTypeFilter from "../InstantSearchFilters/GenericTypesFilter";
import { WindowContext } from "../WindowResize/resize";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import useTranslation from "../../utils/useTranslation";

const InstantSearchCustomApp = ({ colors: colorObj, heading }) => {
    const { pathname } = useLocation();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const [minDate, setMinDate] = useState(null);

    const [maxDate, setMaxDate] = useState(null);

    const [customDate, setCustomDate] = useState(false);

    const [clearFilter, setClearFilter] = useState(false);

    const [validationError, setValidationError] = useState();

    const [emptyValidationError, setemptyValidationError] = useState({
        minDate: "",
        maxDate: "",
    });

    const [isGridView, setIsGridView] = useState(false);

    const [hitsPerPage, setHitsPerPage] = useState(12);

    const [visible, setVisible] = useState(false);

    const { isMobile } = useContext(WindowContext);

    const [inputValue, setInputValue] = useState("");

    const [dropdownValue, setDropdownValue] = useState(null);

    const queryParams = new URLSearchParams(window.location.search);
    const searchParams = queryParams.get("q");
    const authorParams = queryParams.get("author");
    const micrositeParams = queryParams.get("microsite");
    const typeParams = queryParams.get("type");
    const dateParams = queryParams.get("date") || "";
    const minDateParams = queryParams.get("from_date") || "";
    const maxDateParams = queryParams.get("to_date") || "";

    const [filterQuery, setFilterQuery] = useState(dateParams);

    const [dateOptions, setDateOptions] = useState([
        { label: t("instantSearchString.past30Days"), value: 30 },
        { label: t("instantSearchString.past90Days"), value: 90 },
        { label: t("instantSearchString.pastYear"), value: 365 },
        { label: t("instantSearchString.custom"), value: "custom" },
    ]);

    const [typesenseAdapter, setTypesenseAdapter] = useState(
        new TypesenseInstantSearchAdapter({
            server: serverConfig,
            additionalSearchParameters: {
                query_by: QUERY_PARAMS.join(","),
                facet_by: FACET_PARAMS.join(","),
                filter_by: filterQuery,
            },
        }),
    );

    useEffect(() => {
        const newAdapter = new TypesenseInstantSearchAdapter({
            server: serverConfig,
            additionalSearchParameters: {
                query_by: QUERY_PARAMS.join(","),
                facet_by: FACET_PARAMS.join(","),
                filter_by: filterQuery,
            },
        });
        setTypesenseAdapter(newAdapter);
    }, [filterQuery]);

    useEffect(() => {
        if (clearFilter) {
            setMinDate(null);
            setMaxDate(null);
            setFilterQuery("");
            setClearFilter(false);
        }
    }, [clearFilter]);

    useEffect(() => {
        if (minDateParams && maxDateParams) {
            setMinDate(moment(minDateParams, "YYYY-MM-DD").toDate());
            setMaxDate(moment(maxDateParams, "YYYY-MM-DD").toDate());
            setCustomDate(true);
            customDateFilters();
        }
    }, [minDateParams, maxDateParams]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const minDateParams = queryParams.get("from_date") || "";
        const maxDateParams = queryParams.get("to_date") || "";

        if (minDateParams && maxDateParams) {
            const filteredOptions = dateOptions.filter((option) => option.value !== "customDate");

            const newCustomDateOption = {
                label: `${moment(minDateParams).format("YYYY-MM-DD")} to ${moment(
                    maxDateParams,
                ).format("YYYY-MM-DD")}`,
                value: "customDate",
            };

            setDateOptions([...filteredOptions, newCustomDateOption]);
            setDropdownValue("customDate");
        } else {
            setDateOptions([
                { label: t("instantSearchString.past30Days"), value: 30 },
                { label: t("instantSearchString.past90Days"), value: 90 },
                { label: t("instantSearchString.pastYear"), value: 365 },
                { label: t("instantSearchString.custom"), value: "custom" },
            ]);

            setCustomDate(false);
        }
    }, [filterQuery]);

    const validateDates = () => {
        if (!minDate || !maxDate) {
            setemptyValidationError({
                minDate: "Please select From Date",
                maxDate: "Please select To Date",
            });
            return false;
        }

        if (minDate && maxDate && minDate > maxDate) {
            setValidationError(t("validationString.date"));
            return false;
        }
        clearValidationError();
        return true;
    };

    const clearValidationError = () => {
        setValidationError("");
    };

    const onMinDateChange = (newMinDate) => {
        setMinDate(newMinDate);
        clearValidationError(); // Clear the previous validation error

        // Re-validate the dates
        if (newMinDate && maxDate && newMinDate > maxDate) {
            setValidationError(t("validationString.date"));
        } else {
            clearValidationError();
        }
    };

    const onMaxDateChange = (newMaxDate) => {
        setMaxDate(newMaxDate);
        clearValidationError(); // Clear the previous validation error

        // Re-validate the dates
        if (minDate && newMaxDate && minDate > newMaxDate) {
            setValidationError(t("validationString.date"));
        } else {
            clearValidationError();
        }
    };

    const handleSearch = (days) => {
        const currentDate = new Date();
        const filterDate = new Date(currentDate.setDate(currentDate.getDate() - days));
        const unixTimestampInSeconds = Math.floor(filterDate.getTime() / 1000);
        setFilterQuery(`date_timestamp:<${unixTimestampInSeconds}`);
        setDropdownValue(days);
    };

    const customDateFilters = () => {
        if (validateDates()) {
            const minUnixTimestampInSeconds = minDate
                ? Math.floor(new Date(minDate).getTime() / 1000)
                : null;
            const maxUnixTimestampInSeconds = maxDate
                ? Math.floor(new Date(maxDate).getTime() / 1000)
                : null;

            let newFilterQuery = "";
            if (minUnixTimestampInSeconds && maxUnixTimestampInSeconds) {
                newFilterQuery = `date_timestamp:>=${minUnixTimestampInSeconds} && date_timestamp:<=${maxUnixTimestampInSeconds}`;
            } else if (minUnixTimestampInSeconds) {
                newFilterQuery = `date_timestamp:>=${minUnixTimestampInSeconds}`;
            } else if (maxUnixTimestampInSeconds) {
                newFilterQuery = `date_timestamp:<=${maxUnixTimestampInSeconds}`;
            }

            const queryParams = new URLSearchParams(window.location.search);
            if (minUnixTimestampInSeconds !== null) {
                queryParams.set(
                    "from_date",
                    moment.unix(minUnixTimestampInSeconds).format("YYYY-MM-DD"),
                );
            } else {
                queryParams.delete("from_date");
            }

            if (maxUnixTimestampInSeconds !== null) {
                queryParams.set(
                    "to_date",
                    moment.unix(maxUnixTimestampInSeconds).format("YYYY-MM-DD"),
                );
            } else {
                queryParams.delete("to_date");
            }

            navigate(`/${pathname.slice(1)}?${queryParams.toString()}`);
            setFilterQuery(newFilterQuery);
            setCustomDate(false);
            setDropdownValue("custom");
            setemptyValidationError({
                minDate: "",
                maxDate: "",
            });
        }
    };

    const clearAllFilters = () => {
        setClearFilter(true);
        document.querySelector(".ais-ClearRefinements-button").click();
        navigate(`/${pathname.slice(1)}`);
        setDropdownValue(null);
    };

    const sortByItems = [
        {
            label: t("instantSearchString.popular"),
            value: "app_ismaili_articles/sort/popularity:desc",
        },
        {
            label: t("instantSearchString.relevant"),
            value: "app_ismaili_articles/sort/_text_match:desc,body:desc",
        },
        { label: t("instantSearchString.dateASC"), value: "app_ismaili_articles/sort/date:asc" },
        { label: t("instantSearchString.dateDESC"), value: "app_ismaili_articles/sort/date:desc" },
    ];

    const handleShowMore = () => {
        setHitsPerPage(hitsPerPage + 12);
    };

    const handleShowLess = () => {
        setHitsPerPage(12);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        if (inputValue !== "") {
            queryParams.set("q", inputValue);
        } else {
            queryParams.delete("q");
        }
        navigate(`/${pathname.slice(1)}?${queryParams.toString()}`);
    }, [inputValue, filterQuery]);

    const selectedCBValues = (attribute, selectedValues) => {
        const queryParams = new URLSearchParams(window.location.search);

        if (selectedValues.length > 0) {
            queryParams.set(attribute, selectedValues.join("||"));
        } else {
            queryParams.delete(attribute);
        }

        navigate(`/${pathname.slice(1)}?${queryParams.toString()}`);
    };

    const handleDateChange = (e) => {
        const value = e.value;
        setDropdownValue(value);
        if (value === "custom") {
            setCustomDate(true);
        } else {
            setCustomDate(false);
            handleSearch(value);
        }
    };

    return (
        <InstantSearch
            indexName="app_ismaili_articles"
            searchClient={typesenseAdapter.searchClient}
        >
            <Configure hitsPerPage={hitsPerPage} />

            <div className="search-container">
                <div className="instantSearch">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className={`search-main ${
                                        isGridView ? "grid-search-main" : ""
                                    }`}
                                >
                                    <div className="title">{heading}</div>
                                    <div className="search-box d-flex">
                                        <CustomSearchBox
                                            initialQuery={searchParams || ""}
                                            setInputCBValue={setInputValue}
                                            color={colorObj}
                                        />
                                    </div>
                                </div>
                            </div>

                            {!isGridView && (
                                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className="row filters-wrapper">
                                        <div className="col-12">
                                            <div className="filters-main">
                                                <div className="filters-heading-main">
                                                    <div
                                                        className="heading"
                                                        style={{ color: colorObj?.accent_color3 }}
                                                    >
                                                        {t("btnText.filtersWithColon")}
                                                    </div>
                                                    <div
                                                        className="clear-filter cursor-pointer"
                                                        onClick={() => clearAllFilters()}
                                                    >
                                                        <ClearRefinements />
                                                        {t("btnText.clearAll")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            {!isMobile && (
                                                <div className="filters-items-main">
                                                    <div className="filters-item">
                                                        <div className="mt-3">
                                                            <GenericAuthorFilter
                                                                attribute={
                                                                    instantSearchString.author
                                                                }
                                                                placeholder={t(
                                                                    "placeHolder.selectAuthor",
                                                                )}
                                                                clearFilters={clearFilter}
                                                                setSelectedCBValues={
                                                                    selectedCBValues
                                                                }
                                                                passQueryParams={
                                                                    authorParams || null
                                                                }
                                                            />
                                                        </div>
                                                        <div className="mt-3">
                                                            <GenericMicrositeFilter
                                                                attribute={
                                                                    instantSearchString.microsite
                                                                }
                                                                placeholder={t(
                                                                    "placeHolder.selectMicroSite",
                                                                )}
                                                                clearFilters={clearFilter}
                                                                setSelectedMicroSiteCBValues={
                                                                    selectedCBValues
                                                                }
                                                                passQueryParams={
                                                                    micrositeParams || null
                                                                }
                                                            />
                                                        </div>
                                                        <div className="mt-3">
                                                            <GenericTypeFilter
                                                                attribute={instantSearchString.type}
                                                                placeholder={t(
                                                                    "placeHolder.selectType",
                                                                )}
                                                                clearFilters={clearFilter}
                                                                setSelectedTypeCBValues={
                                                                    selectedCBValues
                                                                }
                                                                passQueryParams={typeParams || null}
                                                            />
                                                        </div>

                                                        <div className="mt-3">
                                                            <Dropdown
                                                                value={dropdownValue}
                                                                options={dateOptions}
                                                                onChange={handleDateChange}
                                                                placeholder="Select Date"
                                                                className="w-100"
                                                                itemTemplate={(option) => {
                                                                    if (
                                                                        option.value ===
                                                                        "customDate"
                                                                    ) {
                                                                        return (
                                                                            <span
                                                                                style={{
                                                                                    color: "gray",
                                                                                }}
                                                                            >
                                                                                {option.label}
                                                                            </span>
                                                                        );
                                                                    }
                                                                    return option.label;
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {isMobile && (
                                <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-4">
                                    <div className="filters-hldr">
                                        <div className="filters-wrapper-mobile">
                                            <Button
                                                className="filter-btn"
                                                onClick={() => {
                                                    setVisible(true);
                                                }}
                                            >
                                                <i className="bi bi-sliders me-2"></i>
                                                {t("btnText.filters")}
                                            </Button>
                                        </div>
                                        <div className="sort-title">
                                            {t("btnText.sort")}
                                            <SortByComponent items={sortByItems} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!isMobile && isGridView && (
                                <div className="col-12 mt-5">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="grid-filters-main">
                                                <div
                                                    className="grid-filter-label"
                                                    style={{
                                                        color: colorObj?.accent_color3,
                                                    }}
                                                >
                                                    {t("btnText.filtersWithColon")}
                                                </div>
                                                <div className="filters-items-main grid-filters-items-main">
                                                    <div className="filters-item d-flex">
                                                        <GenericAuthorFilter
                                                            attribute={instantSearchString.author}
                                                            placeholder={t(
                                                                "placeHolder.selectAuthor",
                                                            )}
                                                            clearFilters={clearFilter}
                                                            setSelectedCBValues={selectedCBValues}
                                                            passQueryParams={authorParams || null}
                                                        />

                                                        <GenericMicrositeFilter
                                                            attribute={
                                                                instantSearchString.microsite
                                                            }
                                                            placeholder={t(
                                                                "placeHolder.selectMicroSite",
                                                            )}
                                                            clearFilters={clearFilter}
                                                            setSelectedMicroSiteCBValues={
                                                                selectedCBValues
                                                            }
                                                            passQueryParams={
                                                                micrositeParams || null
                                                            }
                                                        />

                                                        <GenericTypeFilter
                                                            attribute={instantSearchString.type}
                                                            placeholder={t(
                                                                "placeHolder.selectType",
                                                            )}
                                                            clearFilters={clearFilter}
                                                            setSelectedTypeCBValues={
                                                                selectedCBValues
                                                            }
                                                            passQueryParams={typeParams || null}
                                                        />

                                                        <Dropdown
                                                            value={dropdownValue}
                                                            options={dateOptions}
                                                            onChange={handleDateChange}
                                                            placeholder="Select Date"
                                                            className="w-100"
                                                            itemTemplate={(option) => {
                                                                if (option.value === "customDate") {
                                                                    return (
                                                                        <span
                                                                            style={{
                                                                                color: "gray",
                                                                            }}
                                                                        >
                                                                            {option.label}
                                                                        </span>
                                                                    );
                                                                }
                                                                return option.label;
                                                            }}
                                                        />

                                                        <span
                                                            className="clear-filter cursor-pointer"
                                                            onClick={() => clearAllFilters()}
                                                        >
                                                            <ClearRefinements />
                                                            {t("btnText.clearAll")}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="grid-sort">
                                                    <div className="grid-list-view">
                                                        <i
                                                            className={`bi bi-grid ${
                                                                isGridView
                                                                    ? "grid-icon active"
                                                                    : "grid-icon"
                                                            } cursor-pointer`}
                                                            onClick={() => setIsGridView(true)}
                                                            style={{
                                                                "--primary_color":
                                                                    colorObj?.primary_color,
                                                            }}
                                                        ></i>
                                                        <i
                                                            className={`fa fa-align-left ${
                                                                isGridView
                                                                    ? "list-icon"
                                                                    : "list-icon active"
                                                            } list-icon cursor-pointer ms-3`}
                                                            onClick={() => setIsGridView(false)}
                                                            style={{
                                                                "--primary_color":
                                                                    colorObj?.primary_color,
                                                            }}
                                                        ></i>
                                                    </div>
                                                    <div className="sorting-main">
                                                        <div className="sort-title">
                                                            {t("btnText.sort")}
                                                            <SortByComponent items={sortByItems} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div
                                className={
                                    isGridView
                                        ? "col-lg-12 col-md-12 col-sm-12 col-12"
                                        : "col-lg-8 col-md-8 col-sm-12 col-12"
                                }
                            >
                                <div
                                    className={`details-main ${isGridView ? "grid-list-main" : ""}`}
                                >
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 col-sm-6 col-6">
                                            <TotalRecords />
                                        </div>

                                        <div className="col-md-6 col-lg-6 col-sm-6 col-6 clear-filter-mobile">
                                            <div
                                                className="clear-filter-mobile cursor-pointer"
                                                style={{
                                                    "--primary_color": colorObj?.primary_color,
                                                }}
                                                onClick={() => clearAllFilters()}
                                            >
                                                <ClearRefinements />
                                                {t("btnText.clearAll")}
                                            </div>
                                        </div>
                                        {!isGridView && (
                                            <>
                                                <div className="col-md-3 col-lg-3 col-12">
                                                    <div className="grid-list-view">
                                                        <i
                                                            className={`bi bi-grid ${
                                                                isGridView
                                                                    ? "grid-icon active"
                                                                    : "grid-icon"
                                                            } cursor-pointer`}
                                                            onClick={() => setIsGridView(true)}
                                                            style={{
                                                                "--primary_color":
                                                                    colorObj?.primary_color,
                                                            }}
                                                        ></i>
                                                        <i
                                                            className={`fa fa-align-left ${
                                                                isGridView
                                                                    ? "list-icon"
                                                                    : "list-icon active"
                                                            } list-icon cursor-pointer ms-3`}
                                                            onClick={() => setIsGridView(false)}
                                                            style={{
                                                                "--primary_color":
                                                                    colorObj?.primary_color,
                                                            }}
                                                        ></i>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-lg-3 col-12">
                                                    <div className="sorting-main">
                                                        <div className="sort-title d-flex">
                                                            {t("btnText.sort")}
                                                            <SortByComponent
                                                                items={sortByItems}
                                                                color={colorObj}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <InstantSearchArticleDetails
                                        onShowMore={handleShowMore}
                                        onShowLess={handleShowLess}
                                        color={colorObj}
                                        gridView={isGridView}
                                    />
                                </div>
                            </div>

                            <div className={`contPopup ${visible ? "visible" : "d-none"}`}>
                                <div className="filterBox">
                                    <div className="filterHeading">{t("btnText.filterBy")}</div>

                                    <i
                                        className="bi bi-x-lg ms-3 close-icon"
                                        onClick={() => {
                                            setVisible(false);
                                        }}
                                    ></i>
                                </div>
                                {isMobile && (
                                    <>
                                        <div className="tag-filters-main">
                                            <GenericAuthorFilter
                                                attribute={instantSearchString.author}
                                                placeholder={t("placeHolder.selectAuthor")}
                                                clearFilters={clearFilter}
                                                setSelectedCBValues={selectedCBValues}
                                                passQueryParams={authorParams || null}
                                            />
                                        </div>
                                        <div className="tag-filters-main">
                                            <GenericMicrositeFilter
                                                attribute={instantSearchString.microsite}
                                                placeholder={t("placeHolder.selectMicroSite")}
                                                clearFilters={clearFilter}
                                                setSelectedMicroSiteCBValues={selectedCBValues}
                                                passQueryParams={micrositeParams || null}
                                            />
                                        </div>
                                        <div className="tag-filters-main">
                                            <GenericTypeFilter
                                                attribute={instantSearchString.type}
                                                placeholder={t("placeHolder.selectType")}
                                                clearFilters={clearFilter}
                                                setSelectedTypeCBValues={selectedCBValues}
                                                passQueryParams={typeParams || null}
                                            />
                                        </div>

                                        <div className="m-3">
                                            <div className="d-flex w-100">
                                                <Button
                                                    className="date-button w-100"
                                                    onClick={() => {
                                                        handleSearch(30);
                                                        setCustomDate(false);
                                                    }}
                                                >
                                                    {t("instantSearchString.past30Days")}
                                                </Button>
                                                <Button
                                                    className="ms-3 date-button w-100"
                                                    onClick={() => {
                                                        handleSearch(90);
                                                        setCustomDate(false);
                                                    }}
                                                >
                                                    {t("instantSearchString.past90Days")}
                                                </Button>
                                            </div>
                                        </div>

                                        <div className="m-3">
                                            <div className="d-flex w-100">
                                                <Button
                                                    className="date-button w-100"
                                                    onClick={() => {
                                                        handleSearch(365);
                                                        setCustomDate(false);
                                                    }}
                                                >
                                                    {t("instantSearchString.pastYear")}
                                                </Button>
                                                <Button
                                                    className="ms-3 date-button w-100"
                                                    onClick={() => setCustomDate(!customDate)}
                                                >
                                                    {t("btnText.Custom")}
                                                </Button>
                                            </div>
                                        </div>

                                        {customDate && (
                                            <>
                                                <div className="m-3 d-flex">
                                                    <div className="w-100">
                                                        <div>{t("instantSearchString.from")}</div>
                                                        <Calendar
                                                            value={minDate}
                                                            onChange={(e) => setMinDate(e.value)}
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="ms-3 w-100">
                                                        <div>{t("instantSearchString.to")}</div>
                                                        <Calendar
                                                            value={maxDate}
                                                            onChange={(e) => setMaxDate(e.value)}
                                                            className="w-100"
                                                        />
                                                    </div>
                                                </div>
                                                {validationError && (
                                                    <div className="error-message ms-3">
                                                        {validationError}
                                                    </div>
                                                )}
                                                <div className="m-3">
                                                    <Button
                                                        className="date-button"
                                                        onClick={() => customDateFilters()}
                                                    >
                                                        {t("instantSearchString.applyFilter")}
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}

                                <div className="col-12">
                                    <div
                                        className="filter-apply-btn"
                                        onClick={() => {
                                            setVisible(false);
                                        }}
                                    >
                                        <Button className="button">
                                            {t("instantSearchString.apply")}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <Dialog
                                header="Date"
                                visible={customDate}
                                style={{ width: "30vw" }}
                                onHide={() => {
                                    if (!customDate) return;
                                    {
                                        setCustomDate(false);
                                        setDropdownValue(null);
                                        setValidationError("");
                                        setemptyValidationError("");
                                        setMaxDate(null);
                                        setMinDate(null);
                                    }
                                }}
                            >
                                <div className="m-3 d-flex">
                                    <div className="w-100">
                                        <div>{t("instantSearchString.from")}</div>
                                        <Calendar
                                            value={minDate}
                                            onChange={(e) => onMinDateChange(e.value)}
                                            className="w-100"
                                        />
                                        {emptyValidationError.minDate && (
                                            <div className="error-message ms-3">
                                                {emptyValidationError.minDate}
                                            </div>
                                        )}
                                    </div>
                                    <div className="ms-3 w-100">
                                        <div>{t("instantSearchString.to")}</div>
                                        <Calendar
                                            value={maxDate}
                                            onChange={(e) => onMaxDateChange(e.value)}
                                            className="w-100"
                                        />
                                        {emptyValidationError.maxDate && (
                                            <div className="error-message ms-3">
                                                {emptyValidationError.maxDate}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {validationError && (
                                    <div className="error-message ms-3">{validationError}</div>
                                )}
                                <div className="m-3">
                                    <Button
                                        className="date-button"
                                        style={{ "--primary_color": colorObj?.primary_color }}
                                        onClick={() => {
                                            customDateFilters();
                                        }}
                                    >
                                        {t("instantSearchString.applyFilter")}
                                    </Button>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </div>
        </InstantSearch>
    );
};

export default InstantSearchCustomApp;
