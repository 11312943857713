import parse from "html-react-parser";
import React from "react";
import { useGetLink } from "../../utils/useOpenLink";
import { Link } from "react-router-dom";
import useTranslation from "../../utils/useTranslation";

function FeaturedItem({ heading, data }) {
    const { getLink } = useGetLink();
    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    return (
        <div className="md:w-[400px] flex-shrink-0 bg-accent_color2 py-12 px-10">
            <div className="text-primary_color uppercase font-bold mb-2">{heading}</div>
            <Link to={getLink(data.path)}>
                <img
                    className="rounded-xl cursor-pointer mb-2 w-full object-cover max-h-56"
                    src={data.image.src}
                />
            </Link>
            <div className="text-accent_color1 text-sm mb-2">
                {data.summary && parse(data.summary)}
            </div>

            <Link to={getLink(data.path)}>
                <div className="text-accent_color1 cursor-pointer font-semibold text-sm">
                    {t("btnText.ReadMore")}
                    <i className="bi bi-arrow-right mx-2 text-accent_color1" aria-hidden="true"></i>
                </div>
            </Link>
        </div>
    );
}
export default FeaturedItem;
