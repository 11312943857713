import React from "react";

function ImageWithObjectFit({ src, ...rest }) {
    const img = new Image();
    img.src = src;

    if (!img.src || img.src === "") return null;

    return <img src={img.src} {...rest} />;
}

export default ImageWithObjectFit;
