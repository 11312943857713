import React, { useContext, useEffect, useRef, useState } from "react";
import "./IconTextLinks.css";
import { WindowContext } from "../WindowResize/resize";
import { Dropdown } from "primereact/dropdown";
import { TabPanel, TabView } from "primereact/tabview";
import { useOpenLink } from "../../utils/useOpenLink";
import useTranslation from "../../utils/useTranslation";
import parse from "html-react-parser";

function IconTextLinks({ contents: contentsArray = [], heading, colors: colorObj, body }) {
    const [iconListDetails, setIconListDetails] = useState([]);

    const [selectedHeading, setSelectedHeading] = useState(contentsArray[0].heading);

    const [selectedIndex, setSelectedIndex] = useState(0);

    const { isDesktop } = useContext(WindowContext);

    const { t } = useTranslation();

    const [isScrollable, setIsScrollable] = useState(false);
    const tabViewRef = useRef(null);

    const openLink = useOpenLink();

    useEffect(() => {
        const checkScrollable = () => {
            const screenWidth = window.innerWidth;
            const isMobile = screenWidth <= 576; // Mobile breakpoint (e.g., iPhone)
            const isTablet = screenWidth > 576 && screenWidth <= 768; // Tablet breakpoint (e.g., iPad)
            const isLaptop = screenWidth > 768 && screenWidth <= 1024; // Laptop breakpoint (e.g., small laptops)
            const isDesktop = screenWidth > 1024; // Desktop breakpoint (e.g., large monitors)

            if (isMobile) {
                setIsScrollable(true);
            } else if (isTablet) {
                setIsScrollable(contentsArray.length > 3);
            } else if (isLaptop) {
                setIsScrollable(contentsArray.length > 4);
            } else if (isDesktop) {
                setIsScrollable(contentsArray.length > 6);
            }
        };

        checkScrollable();
        window.addEventListener("resize", checkScrollable);

        return () => window.removeEventListener("resize", checkScrollable);
    }, [contentsArray]);

    useEffect(() => {
        if (contentsArray[selectedIndex].contents) {
            setIconListDetails(contentsArray[selectedIndex].contents);
        } else {
            setIconListDetails([]);
        }
    }, [selectedIndex, contentsArray]);

    const handleDropdownChange = (selectedOption) => {
        const selectedHeading = selectedOption.value;
        setSelectedHeading(selectedHeading);

        const index = contentsArray.findIndex((e) => e.heading === selectedHeading);
        if (contentsArray[index].contents) {
            setIconListDetails(contentsArray[index].contents);
        } else {
            setIconListDetails([]);
        }
    };

    return (
        <>
            <div
                className="iconTextLinksWrapper py-50"
                style={{ background: colorObj?.background_color }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="titleMain">
                                <div
                                    className="icon-line"
                                    style={{ background: colorObj?.primary_color }}
                                ></div>

                                <h3>{heading}</h3>
                            </div>
                            {body ? (
                                <div
                                    className="sub-title"
                                    style={{ color: colorObj?.accent_color1 }}
                                >
                                    {parse(body)}
                                </div>
                            ) : null}
                            <div className="line" style={{ color: colorObj?.accent_color2 }}>
                                <hr />
                            </div>
                        </div>

                        <div className="col-12" ref={tabViewRef}>
                            {isDesktop ? (
                                contentsArray.length > 1 ? (
                                    <TabView
                                        scrollable={isScrollable}
                                        activeIndex={selectedIndex}
                                        onTabChange={(e) => setSelectedIndex(e.index)}
                                        style={{
                                            "--accent_color1": colorObj?.accent_color1,
                                            "--primary_color": colorObj?.primary_color,
                                            "--background_color": colorObj?.background_color,
                                        }}
                                    >
                                        {contentsArray.map((tab) => (
                                            <TabPanel key={tab.heading} header={tab.heading}>
                                                <div className="col-12" id={tab.heading}>
                                                    {iconListDetails.length > 0 ? (
                                                        <ul
                                                            className="linkListHldr"
                                                            style={{
                                                                "--primary_color":
                                                                    colorObj?.primary_color,
                                                            }}
                                                        >
                                                            {iconListDetails.map((v) => (
                                                                <>
                                                                    <li
                                                                        className="cursor-pointer"
                                                                        key={v.heading}
                                                                        onClick={() =>
                                                                            openLink(
                                                                                v.content_reference,
                                                                                v.type,
                                                                            )
                                                                        }
                                                                    >
                                                                        <i>
                                                                            <img
                                                                                src={
                                                                                    v
                                                                                        ?.sub_content_image
                                                                                        .src
                                                                                }
                                                                            />
                                                                        </i>
                                                                        <p
                                                                            style={{
                                                                                "--accent_color1":
                                                                                    colorObj?.accent_color1,
                                                                            }}
                                                                        >
                                                                            {v.heading}
                                                                        </p>
                                                                    </li>
                                                                </>
                                                            ))}
                                                            {tab?.content_buttons.length > 0 &&
                                                                tab?.content_buttons.map((v) => (
                                                                    <li
                                                                        className="cursor-pointer"
                                                                        key={v.label}
                                                                        onClick={() =>
                                                                            openLink(v.action)
                                                                        }
                                                                    >
                                                                        <i className="bi bi-arrow-right more-icon"></i>
                                                                        <p>{v.label}</p>
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    ) : (
                                                        <h3 className="text">
                                                            {t("constantString.noRecords")}
                                                        </h3>
                                                    )}
                                                </div>
                                            </TabPanel>
                                        ))}
                                    </TabView>
                                ) : (
                                    contentsArray.length === 1 && (
                                        <div className="col-12" id={contentsArray[0].heading}>
                                            {iconListDetails.length > 0 ? (
                                                <ul
                                                    className="linkListHldr"
                                                    style={{
                                                        "--primary_color": colorObj?.primary_color,
                                                    }}
                                                >
                                                    {iconListDetails.map((v) => (
                                                        <li
                                                            className="cursor-pointer"
                                                            key={v.heading}
                                                            onClick={() =>
                                                                openLink(v.content_reference)
                                                            }
                                                        >
                                                            <i>
                                                                <img
                                                                    src={v?.sub_content_image.src}
                                                                    alt={v.heading}
                                                                />
                                                            </i>
                                                            <p
                                                                style={{
                                                                    "--accent_color1":
                                                                        colorObj?.accent_color1,
                                                                }}
                                                            >
                                                                {v.heading}
                                                            </p>
                                                        </li>
                                                    ))}
                                                    {contentsArray[0]?.content_buttons.length > 0 &&
                                                        contentsArray[0].content_buttons.map(
                                                            (v) => (
                                                                <li
                                                                    className="cursor-pointer"
                                                                    key={v.label}
                                                                    onClick={() =>
                                                                        openLink(v.action)
                                                                    }
                                                                >
                                                                    <i className="bi bi-arrow-right more-icon"></i>
                                                                    <p>{v.label}</p>
                                                                </li>
                                                            ),
                                                        )}
                                                </ul>
                                            ) : (
                                                <h3 className="text">
                                                    {t("constantString.noRecords")}
                                                </h3>
                                            )}
                                        </div>
                                    )
                                )
                            ) : (
                                <>
                                    <Dropdown
                                        value={selectedHeading}
                                        options={contentsArray.map((content) => content.heading)}
                                        onChange={handleDropdownChange}
                                        placeholder={t("placeHolder.selectTag")}
                                        className="customDropdown"
                                        panelStyle={{
                                            "--accent_color2": colorObj?.accent_color2,
                                            "--primary_color": colorObj?.primary_color,
                                        }}
                                        style={{
                                            border: `1px solid ${colorObj?.primary_color}`,
                                            "--accent_color1": colorObj?.accent_color1,
                                        }}
                                        valueTemplate={(option, props) =>
                                            option ? (
                                                <div style={{ color: colorObj?.accent_color1 }}>
                                                    {option}
                                                </div>
                                            ) : (
                                                <span style={{ color: colorObj?.accent_color1 }}>
                                                    {props.placeholder}
                                                </span>
                                            )
                                        }
                                    />

                                    {iconListDetails.length > 0 ? (
                                        <ul className="linkListHldr">
                                            {iconListDetails.map((v) => (
                                                <>
                                                    <li
                                                        key={v.heading}
                                                        onClick={() =>
                                                            openLink(v.content_reference)
                                                        }
                                                    >
                                                        <i>
                                                            <img src={v?.sub_content_image.src} />
                                                        </i>
                                                        <p
                                                            style={{
                                                                "--accent_color1":
                                                                    colorObj?.accent_color1,
                                                            }}
                                                        >
                                                            {v.heading}
                                                        </p>
                                                    </li>
                                                </>
                                            ))}
                                        </ul>
                                    ) : (
                                        <h3 className="text">{t("constantString.noRecords")}</h3>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IconTextLinks;
