import React from "react";
import "./HeroStatic2.css";
import parse from "html-react-parser";

function HeroStatic2({ heading, body, section_image, colors: colorObj }) {
    return (
        <>
            <div className="row mb-15">
                <div className="col-12">
                    <div
                        className="HeroStaticWrapper"
                        style={{ "--accent_color1": colorObj?.accent_color1 }}
                    >
                        <img src={section_image?.src}></img>
                        <div className="contMain container">
                            <h2>{heading}</h2>
                            <p>{body && parse(body)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroStatic2;
