import ImageWithObjectFit from "../ImageWrapperComponent";
import { decodeHtmlEntities } from "../../utils/urlUtils";
import { calculateReadTime } from "../../utils/articleHelpers";
import useTranslation from "../../utils/useTranslation";
import { useGetLink } from "../../utils/useOpenLink";
import { Link } from "react-router-dom";
import { getTextToDisplay } from "../../utils/getTextToDisplay";

export const RelatedCard1 = ({ content, colorsObj }) => {
    const { image, title: Title, path, microsite, link, posterImage, model_type, themes } = content;

    const { t } = useTranslation();

    const { getLink } = useGetLink();

    const textToDisplay = getTextToDisplay(model_type, themes);

    return (
        <div className="newsCard card">
            <Link to={getLink(path)}>
                <div className="cardImg">
                    <ImageWithObjectFit
                        className="cursor-pointer"
                        src={link === "" || link === null ? image?.src : posterImage}
                    />
                </div>
            </Link>
            <div className="card-body d-flex flex-column">
                <div className="tag-heading mb-2" style={{ color: colorsObj.primary_color }}>
                    {microsite !== "" ? decodeHtmlEntities(microsite) : <>&nbsp;</>}
                </div>
                <Link to={getLink(path)}>
                    <h3
                        className="hover:underline cursor-pointer"
                        style={{ color: colorsObj.accent_color1 }}
                    >
                        {Title}
                    </h3>
                </Link>
                <div className="d-flex mt-auto">
                    {textToDisplay && (
                        <div className="author me-1" style={{ color: colorsObj.accent_color1 }}>
                            {textToDisplay}
                        </div>
                    )}
                    <div className="date" style={{ color: colorsObj.accent_color3 }}>
                        {textToDisplay && calculateReadTime(content) ? " | " : ""}
                        {calculateReadTime(content)
                            ? `${calculateReadTime(content)} ${t("constantString.minRead")}`
                            : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};
