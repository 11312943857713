import parse from "html-react-parser";

export const calculateReadTime = (content) => {
    let minutes = "";
    const { title = "", summary = "", body = "" } = content || {};
    const text = [title, summary, body].join(" ");
    const words = text ? text.split(/\s+/).length : 0;
    minutes = Math.ceil(words / 200);

    return minutes;
};

export const shareOnWhatsapp = () => {
    window.open(`https://api.whatsapp.com/send?text=${window.location.href}`, "_blank");
};

export const articleType = "article";

export const speechType = "speech";

export const parseWithRemoveBrTagsFromClass = (bodyContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(bodyContent, "text/html");

    const elements = doc.querySelectorAll(".media.media-element-container.media-media_full_width");

    elements.forEach((element) => {
        const brTags = element.querySelectorAll("br");
        brTags.forEach((br) => br.remove());
    });

    return parse(doc.body.innerHTML);
};

export const parseWithRemoveBrTagsFromBody = (bodyContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(bodyContent, "text/html");

    const brTags = doc.querySelectorAll("br");

    brTags.forEach((br) => br.remove());

    return parse(doc.body.innerHTML);
};
