import React, { useEffect, useState } from "react";
import "./EventCarousel.css";
import EventCard from "../Card/EventCard";
import { useSelector } from "react-redux";
import Loader from "../Loader/loader";
import { Swiper, SwiperSlide } from "swiper/react";
import { swiperCarousel } from "../../utils/carouselFunction";
import useCustomTranslation from "../CustomTranslation";
import { Button } from "../Button";
import { useOpenLink } from "../../utils/useOpenLink";
import parse from "html-react-parser";

function EventCarousel({
    contents: contentToRender = [],
    heading,
    body,
    block_buttons,
    colors: colorObj,
}) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const [keysArr, setKeysArr] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const content = useSelector((state) => state.layout.content);

    const { direction } = useCustomTranslation();

    const openLink = useOpenLink();

    useEffect(() => {
        const fetchDefaultData = () => {
            setIsLoading(true);
            const resp = (content[contentToRender[0]?.contentKey] || []).map((k) => content[k]);

            if (resp.length) {
                setKeysArr(Object.keys(resp));
                setContentReferenceData(resp);
            }

            setIsLoading(false);
        };
        fetchDefaultData();
    }, []);

    return (
        <>
            <Loader isLoading={isLoading} />

            <div className="eventWrapper py-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="titleMain">
                                <h3 style={{ color: colorObj?.accent_color1 }}>{heading}</h3>
                            </div>
                            <div className="sub-title" style={{ color: colorObj?.accent_color1 }}>
                                {body ? parse(body) : ""}
                            </div>
                            <Swiper dir={direction} key={direction} {...swiperCarousel}>
                                {keysArr.map((value) => (
                                    <SwiperSlide key={value}>
                                        <EventCard
                                            content={contentReferenceData[value]}
                                            key={value}
                                            colorsObj={colorObj}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>

                        {block_buttons?.length > 0 && (
                            <div className="col-12 d-flex justify-content-center">
                                {block_buttons.map((v) => (
                                    <Button
                                        key={v.label}
                                        onClick={() => openLink(v.action)}
                                        className="morebtn"
                                        style={{ "--accent_color3": colorObj?.accent_color3 }}
                                    >
                                        {v.label}
                                        <i
                                            className="bi bi-arrow-right mt-1"
                                            aria-hidden="true"
                                        ></i>
                                    </Button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default EventCarousel;
