import React, { useCallback, useEffect, useState } from "react";
import "./RelatedCardCarousel.css";
import { useSelector } from "react-redux";
import useCustomTranslation from "../CustomTranslation";
import { Swiper, SwiperSlide } from "swiper/react";
import { RelatedCard1 } from "../Card/RelatedCard1";
import { Grid, Navigation, Pagination } from "swiper/modules";
import * as posterImage from "../../utils/usePosterImage";

function RelatedCardCarousel({
    contents: contentToRender = [],
    heading,
    colors: colorObj,
    number_of_rows,
}) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            const respKeys = content[contentKey];
            let resp = respKeys.map((k) => content[k]);
            resp = JSON.parse(JSON.stringify(resp));
            for await (const element of resp) {
                if (element && element.link) {
                    element.posterImage = await posterImage.usePosterImage(
                        element?.link,
                        element?.image,
                    );
                }
            }
            if (resp.length) {
                setContentReferenceData(resp);
            }
        },
        [content],
    );

    useEffect(() => {
        setCurrentContent(contentToRender[0].contentKey);
    }, []);

    const { direction } = useCustomTranslation();

    const swiperCarousel = {
        slidesPerView: 3,
        navigation: true,
        grid: {
            rows: 1,
            fill: "row",
        },
        spaceBetween: 30,
        pagination: {
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 7,
        },
        modules: [Grid, Pagination, Navigation],
        breakpoints: {
            345: {
                grid: {
                    rows: number_of_rows,
                    fill: "row",
                },
                slidesPerView: 1,
                spaceBetween: 20,
            },
            576: {
                grid: {
                    rows: number_of_rows,
                    fill: "row",
                },
                slidesPerView: 1.1,
                spaceBetween: 20,
            },
            676: {
                grid: {
                    rows: number_of_rows,
                    fill: "row",
                },
                slidesPerView: 1.5,
                spaceBetween: 20,
            },
            768: {
                grid: {
                    rows: number_of_rows,
                    fill: "row",
                },
                slidesPerView: 2,
            },
            1024: {
                grid: {
                    rows: number_of_rows,
                    fill: "row",
                },
                spaceBetween: 20,
                slidesPerView: 3,
            },
            1280: {
                grid: {
                    rows: number_of_rows,
                    fill: "row",
                },
                spaceBetween: 20,
                slidesPerView: 3,
            },
        },
    };

    return (
        <div
            className="relaredCard1Wrapper py-50"
            style={{ background: colorObj?.background_color }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="titleMain">
                            <h3 style={{ color: colorObj?.primary_color }}>{heading}</h3>
                            <div className="line"></div>
                        </div>
                        <Swiper
                            dir={direction}
                            key={direction}
                            {...swiperCarousel}
                            style={{ "--primary_color": colorObj?.primary_color }}
                        >
                            {contentReferenceData.map((card) => (
                                <SwiperSlide key={card.title}>
                                    <RelatedCard1 content={card} colorsObj={colorObj} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RelatedCardCarousel;
