import React, { useEffect, useState } from "react";
import "./ArticlesDetails2.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchArticle } from "../../redux/layoutSlice";
import ImageWithObjectFit from "../../components/ImageWrapperComponent";
import { calculateReadTime, parseWithRemoveBrTagsFromBody } from "../../utils/articleHelpers";
import moment from "moment";
import PrintSvg from "../../assets/PrintSvg";
import PDFSvg from "../../assets/PDFSvg";
import BreadCrumbs from "../../components/BreadCrumbs";
import ShareOnSocialLinks from "../../components/ShareOnSocialLinks";
import ReactPlayer from "react-player";
import useTranslation from "../../utils/useTranslation";
import { Dropdown } from "primereact/dropdown";

function Articles2({ contents, colors: colorObj }) {
    const { articleId: articleIdFromParams } = useParams();

    const id = contents?.[0]?.id || articleIdFromParams;

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(!!id);

    const [didDispatch, setDidDispatch] = useState(false);

    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const content = useSelector((state) => state.layout.content);

    const { t } = useTranslation();

    const { countryName } = useSelector((state) => state.auth);

    const {
        title,
        date,
        body,
        microsite,
        detailImage,
        region,
        article_pdf,
        model_type,
        link,
        translate_languages,
    } = content[id] || {};

    useEffect(() => {
        if (isLoading && !didDispatch) {
            dispatch(fetchArticle({ id }));
            setDidDispatch(true);
        }
        if (isLoading && content[id]) {
            setIsLoading(false);
        }
    }, [isLoading, content, didDispatch]);

    useEffect(() => {
        if (translate_languages) {
            const index = translate_languages.findIndex((e) => e.path === id);
            if (index !== -1) {
                setSelectedLanguage(translate_languages[index]);
            }
        }
    }, [id]);

    useEffect(() => {
        if (selectedLanguage) {
            const newPath = `/articles${selectedLanguage.path}`;
            navigate(newPath);

            dispatch(fetchArticle({ id: selectedLanguage.path, lang: selectedLanguage.lang_code }));
        }
    }, [selectedLanguage]);

    const microsites = microsite && microsite.split(",")[0] === "Global" ? "Global" : countryName;

    return (
        <>
            <section className="news-annou-wrapper">
                <div className="container">
                    <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                        <BreadCrumbs lableName="Article" />
                    </div>
                    <div className="row news-annou-hldr">
                        <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                            <div className="news-annou-txtMain">
                                <div className="tagMain" style={{ color: colorObj?.primary_color }}>
                                    {model_type}
                                </div>
                                <h3 style={{ color: colorObj?.accent_color1 }}>{title}</h3>
                            </div>
                            <div className="cont-social-hldr">
                                <div className="cont-main">
                                    <h4 style={{ color: colorObj?.accent_color1 }}>{microsites}</h4>
                                    <div className="dateTime">
                                        <span style={{ color: colorObj?.accent_color3 }}>
                                            {region ? `${region} • ` : ""}
                                            {calculateReadTime(content[id])
                                                ? `${calculateReadTime(content[id])} ${t(
                                                      "constantString.minRead",
                                                  )} • `
                                                : ""}
                                            {moment(date).format("DD MMMM YYYY")}
                                        </span>
                                    </div>
                                </div>

                                <ShareOnSocialLinks />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12 col-12 mobile-view">
                            <div className="news-annou-img">
                                {link === "" || link === null ? (
                                    <ImageWithObjectFit src={detailImage?.src} />
                                ) : (
                                    <ReactPlayer
                                        url={link}
                                        controls
                                        width={"100%"}
                                        height={"100vh"}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="article2-TextSecation">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="textHldr">
                                <div className="row">
                                    <div className="col-lg-9 col-md-9 col-sm-12 col-12 card-order">
                                        <div style={{ color: colorObj?.accent_color1 }}>
                                            {parseWithRemoveBrTagsFromBody(body || "")}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                        <div
                                            className="iconHldr"
                                            style={{ "--accent_color3": colorObj?.accent_color3 }}
                                        >
                                            {translate_languages?.length > 0 && (
                                                <Dropdown
                                                    options={translate_languages}
                                                    optionLabel="language"
                                                    value={selectedLanguage}
                                                    onChange={(e) => setSelectedLanguage(e.value)}
                                                    placeholder="language"
                                                    className="w-full md:w-14rem"
                                                />
                                            )}
                                            <ul>
                                                <li
                                                    className="cursor-pointer"
                                                    onClick={() => window.print()}
                                                >
                                                    <PrintSvg />
                                                </li>
                                                <li
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        window.open(article_pdf, "_blank")
                                                    }
                                                >
                                                    <PDFSvg />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Articles2;
