import { default as React, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    jurisdictionList,
    selectCountriesLanguage,
    selectIsAuthed,
    selectUserInfo,
    setjurisdictionNameRedux,
    setOauthData,
} from "../../redux/authSlice";
import { setShowDropDownPicker } from "../../redux/layoutSlice";
import { useCountryInfoWithLocalStorage } from "../../utils/getCountryInfoFunction";
import { handleLogout } from "../../utils/oauthHandler";
import { useClickOutside } from "../../utils/useClickOutside";
import useCustomTranslation from "../CustomTranslation";
import LanguagePicker from "./LanguagePicker";

export const CountryLanguagePicker = () => {
    const countriesLanguageData = useSelector(selectCountriesLanguage);

    const jurisdictions = useSelector(jurisdictionList);

    const isAuthed = useSelector(selectIsAuthed);

    const dispatch = useDispatch();

    const { idToken } = useSelector(selectUserInfo) || {};

    const [selectCountryName, setSelectCountryName] = useState("");

    const {
        selectedCountry,
        selectedCountryCode,
        handleSelect,
        toggleLogin,
        setToggleLogin,
        jurisdictionName,
    } = useCountryInfoWithLocalStorage(countriesLanguageData, jurisdictions);

    const [searchKeyword, setSearchKeyword] = useState("");

    const dropdownRef = useRef(null);

    const [filteredItems, setFilteredItems] = useState([]);

    const previousCountryRef = useRef();

    useEffect(() => {
        dispatch(setShowDropDownPicker(true));
    }, []);

    useEffect(() => {
        if (countriesLanguageData.length > 0) {
            const filteredList = countriesLanguageData.filter((o) =>
                Object.keys(o).some(() =>
                    o.country.toLowerCase().includes(searchKeyword.toLowerCase()),
                ),
            );
            setFilteredItems(filteredList);
        }
    }, [countriesLanguageData, searchKeyword, jurisdictionName, selectedCountry]);

    const { t } = useCustomTranslation();

    useClickOutside(dropdownRef, () => {
        setToggleLogin(false);
    });

    const handleSelectCountry = (country) => {
        setSelectCountryName(country);
    };

    useEffect(() => {
        previousCountryRef.current = selectCountryName;
    }, [selectCountryName]);

    useEffect(() => {
        const selectedCountryData = countriesLanguageData.find(
            (c) => c.country === selectedCountry,
        );
        dispatch(setjurisdictionNameRedux(selectedCountryData?.jurisdiction));
        dispatch(setOauthData(selectedCountryData?.oauthServers));
    }, [selectedCountry, countriesLanguageData, dispatch]);

    useEffect(() => {
        const selectedCountryData = countriesLanguageData.find(
            (c) => c.country === selectCountryName,
        );
        const storedJurisdiction = localStorage.getItem("jurisdiction");

        if (selectedCountryData) {
            const newJurisdiction = selectedCountryData.jurisdiction;
            if (storedJurisdiction !== newJurisdiction && isAuthed) {
                localStorage.setItem("jurisdiction", newJurisdiction);
                alert("You are being logged out. Please log in again.");
                setTimeout(() => {
                    handleLogout({ idToken });
                }, 1000);
            }
        }
    }, [countriesLanguageData, selectCountryName]);

    return (
        <>
            <div className="relative">
                <div
                    className="cursor-pointer capitalize flex flex-row items-center"
                    onClick={() => setToggleLogin((s) => !s)}
                >
                    <i
                        className={`fi fi-${selectedCountryCode?.toLowerCase()} mr-2 text-lg rounded-lg`}
                    ></i>
                    {selectedCountry}
                </div>

                {toggleLogin && (
                    <div
                        className="fixed inset-0 overflow-auto bg-white md:absolute md:mt-2 md:inset-auto md:right-0 min-w-72 shadow-lg pt-3 rounded-md z-30 text-base text-accent_color1"
                        ref={dropdownRef}
                    >
                        <div className="pb-3 border-b border-solid border-b-accent_color3 px-4 flex flex-row items-center justify-between">
                            <div>Choose Your Location</div>
                            <div
                                className="block md:hidden cursor-pointer bi bi-x text-2xl"
                                onClick={() => {
                                    setToggleLogin(false);
                                }}
                            ></div>
                        </div>
                        <div className="md:max-h-[400px] md:overflow-auto pt-3 pb-3">
                            <div className="mx-4 mb-2">
                                <input
                                    className="px-3 py-2 bg-[#F6F6F6] w-full text-sm rounded-md"
                                    type="text"
                                    placeholder={t("placeHolder.search")}
                                    title="Enter search keyword"
                                    value={searchKeyword}
                                    onChange={(e) => setSearchKeyword(e.target.value)}
                                />
                            </div>

                            {filteredItems.map((item, i) => {
                                return (
                                    <div
                                        key={i}
                                        onClick={() => {
                                            handleSelect(item.country, item.country_code);
                                            handleSelectCountry(item.country);
                                        }}
                                        className="text-base px-4 py-2 flex flex-row items-start cursor-pointer hover:bg-primary_color hover:text-accent_color4"
                                    >
                                        <div
                                            className={`fi fi-${item.country_code?.toLowerCase()} mt-1 mr-2 flex-shrink-0`}
                                        />
                                        <div>{item.country}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
            <LanguagePicker
                selectedCountry={selectedCountry}
                countriesData={countriesLanguageData}
            />
        </>
    );
};
