import React, { useCallback, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import useCustomTranslation from "../CustomTranslation";
import { swiperCarousel } from "../../utils/carouselFunction";
import "./MediaCardCarousel.css";
import { useSelector } from "react-redux";
import MediaCard from "../Card/MediaCard";

function MediaCardCarousel({ contents: contentToRender = [], heading }) {
    const [mediaContentReferenceData, setMediaContentReferenceData] = useState([]);

    const [tagList, setTagList] = useState([]);

    const filterPopupRef = useRef(null);

    const [mainMediaContentReferenceData, setMainMediaContentReferenceData] = useState([]);

    const [clickOnFliter, setClickOnFliter] = useState(false);

    const [selectedTagIndex, setSelectedTagIndex] = useState(0);

    const [selectedTag, setSelectedTag] = useState("");

    const content = useSelector((state) => state.layout.content);

    const setCurrentContent = useCallback(
        (contentKey) => {
            const respKeys = content[contentKey];
            const resp = respKeys.map((k) => content[k]);

            if (resp.length) {
                setMediaContentReferenceData(resp);
                setMainMediaContentReferenceData(resp);
            }
        },
        [content],
    );

    useEffect(() => {
        setCurrentContent(contentToRender[0].contentKey);
    }, []);

    const { dir } = useCustomTranslation();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                filterPopupRef.current &&
                !filterPopupRef.current.contains(event.target) &&
                event.target.className !==
                    "fa fa-angle-down cursor-pointer ms-2 mt-2 filter-icon" &&
                event.target.className !== "tag-filter cursor-pointer ms-5"
            ) {
                setClickOnFliter(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        let tags = [];
        for (const iterator of mainMediaContentReferenceData) {
            const tagArr = iterator.tags.split(",");
            tags = tags.concat(tagArr);
        }
        tags = tags.filter((v, i, a) => a.findIndex((v2) => v2 === v) === i);
        let array = [];
        array.push({
            tagName: "All",
        });
        tags.forEach((tag) => {
            array.push({
                tagName: tag,
            });
        });
        setTagList(array);
    }, [mainMediaContentReferenceData]);

    useEffect(() => {
        let arr = [];
        if (tagList[selectedTagIndex]?.tagName !== "All") {
            const filteredData = mainMediaContentReferenceData.filter((e) =>
                e.tags.includes(tagList[selectedTagIndex].tagName),
            );

            arr = arr.concat(filteredData);
            arr = Object.values(arr.reduce((acc, obj) => ({ ...acc, [obj.title]: obj }), {}));
            setMediaContentReferenceData(arr);
        } else {
            setMediaContentReferenceData(mainMediaContentReferenceData);
        }
    }, [mainMediaContentReferenceData, tagList, selectedTagIndex]);

    const toggleFilterPopup = () => {
        setClickOnFliter(!clickOnFliter);
    };

    const handleTagClick = (index) => {
        setSelectedTagIndex(index);
        setClickOnFliter(false);
    };

    return (
        <>
            <div className="container">
                <div className="mediaCardWrapper">
                    <div className="row">
                        <div
                            className="col-lg-6 col-md-6 col-12 d-flex position-absolute"
                            style={{ zIndex: 999 }}
                        >
                            <div className="main-heading">{heading}</div>
                            <span
                                className="tag-filter cursor-pointer ms-5"
                                onClick={toggleFilterPopup}
                            >
                                {selectedTag === "" ? tagList[0]?.tagName : selectedTag}
                                <i
                                    className="fa fa-angle-down cursor-pointer ms-2 mt-2 filter-icon"
                                    onClick={toggleFilterPopup}
                                ></i>
                            </span>
                            {clickOnFliter && (
                                <div className="contPopup" ref={filterPopupRef}>
                                    <div className="titleBox">
                                        <div className="contMain">
                                            <ul className="cursor-pointer">
                                                {tagList.map((v, index) => (
                                                    <li
                                                        key={index}
                                                        onClick={() => {
                                                            handleTagClick(index);
                                                            setSelectedTag(v.tagName);
                                                        }}
                                                    >
                                                        {v.tagName}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-12">
                            <Swiper dir={dir} key={dir} {...swiperCarousel} spaceBetween={20}>
                                {mediaContentReferenceData.map((card) => (
                                    <SwiperSlide key={card.title}>
                                        <MediaCard content={card} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MediaCardCarousel;
