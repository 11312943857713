import moment from "moment";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { popularity } from "../../redux/instantSearchSlice";
import { useHits, useStats } from "react-instantsearch";
import { WindowContext } from "../WindowResize/resize";
import useTranslation from "../../utils/useTranslation";
import { useGetLink, useOpenLink } from "../../utils/useOpenLink";
import { articleType } from "../../utils/articleHelpers";
import { Link } from "react-router-dom";

function InstantSearchArticleDetails({ props, onShowMore, color, gridView, onShowLess }) {
    const { hits } = useHits(props);
    const { nbHits } = useStats(props);
    const openLink = useOpenLink();
    const { getLink } = useGetLink();

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isMobile } = useContext(WindowContext);

    const [isExpanded, setIsExpanded] = useState(false);

    const handleClick = (path, nid, doc_id) => {
        if (doc_id) {
            dispatch(popularity(doc_id));
            if (path) {
                setTimeout(() => {
                    openLink(path, articleType, nid);
                }, 200);
            }
        }
    };

    const handleShowMore = () => {
        setIsExpanded(true);
        onShowMore(); // Call the passed onShowMore function
    };

    const handleShowLess = () => {
        setIsExpanded(false);
        onShowLess(); // Call the passed onShowLess function
    };
    return (
        <>
            <div
                className={`${
                    gridView && !isMobile ? "article-list article-grid" : "article-list"
                }`}
            >
                {hits.map((hit, i) => (
                    <div className="article-card" key={i}>
                        <div className="article-img">
                            <Link to={getLink(hit.path)}>
                                <img
                                    src={hit.image}
                                    onClick={() => handleClick(hit.path, hit.nid, hit.doc_id)}
                                />
                            </Link>
                        </div>
                        <div className="article-body">
                            <div className="title-main">
                                <h5>{hit.microsite.split(",")[0]}</h5>
                                <Link to={getLink(hit.path)}>
                                    <h2 className="hover:underline cursor-pointer">{hit.title}</h2>
                                </Link>
                            </div>
                            <div className="d-flex mt-auto">
                                {hit.tags && (
                                    <div
                                        className="article-tags me-1"
                                        style={{ color: color.accent_color1 }}
                                    >
                                        {hit.tags?.split(",")[0]}
                                    </div>
                                )}
                                <div className="date" style={{ color: color?.accent_color3 }}>
                                    {hit.tags && hit.date ? " | " : ""}
                                    {moment(hit.date).fromNow()}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {nbHits > 0 && nbHits > 12 && (
                <>
                    {!isExpanded ? (
                        <div
                            className={`${gridView ? "gridshowMore" : "showMore"}`}
                            onClick={handleShowMore}
                        >
                            <span className="cursor-pointer">
                                {t("btnText.ShowMore")}
                                <i className="bi bi-arrow-down ms-2"></i>
                            </span>
                        </div>
                    ) : (
                        <div
                            className={`${gridView ? "gridshowLess" : "showLess"}`}
                            onClick={handleShowLess}
                        >
                            <span className="cursor-pointer">
                                {t("btnText.ShowLess")}
                                <i className="bi bi-arrow-up ms-2"></i>
                            </span>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default InstantSearchArticleDetails;
