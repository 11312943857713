import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jurisdictionList, selectCountriesLanguage } from "../../redux/authSlice";
import { getBaseUrl, handleOauth } from "../../utils/oauthHandler";
import { DropDownPicker } from "../DropDownPicker";
import { generatePkceCodePair } from "../../utils/oauthHelpers";

export const LoginPanel = ({ Isheader2 }) => {
    const dispatch = useDispatch();

    const countriesLanguageData = useSelector(selectCountriesLanguage);
    const jurisdictions = useSelector(jurisdictionList);

    const [didDispatch, setDidDispatch] = useState(false);

    useEffect(() => {
        if (!didDispatch) {
            setDidDispatch(true);
        }
    }, [dispatch, didDispatch]);

    const handleCountryDropDownSelect = async (item) => {
        const selectedCountry = countriesLanguageData.find((c) => c.country === item.country);
        if (selectedCountry?.oauthServers?.authEndpoint) {
            const codeChallenge = await generatePkceCodePair();
            localStorage.setItem(
                "oauthServersDetails",
                JSON.stringify(selectedCountry?.oauthServers),
            );
            localStorage.setItem("message_api", JSON.stringify(selectedCountry?.message_api));
            handleOauth({
                authEndpoint: selectedCountry?.oauthServers?.authEndpoint,
                clientId: selectedCountry?.oauthServers?.clientId,
                state: { link: getBaseUrl().path },
                codeChallenge,
            });
        }
    };

    return (
        <DropDownPicker
            list={countriesLanguageData}
            jurisdictionList={jurisdictions}
            idKey="id"
            displayKey="name"
            Isheader2={Isheader2}
            onSelect={handleCountryDropDownSelect}
            className="bg-white px-4 py-2.5"
        />
    );
};
