import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getCoutryFromIPAddress,
    selectCountryCodeFromIP,
    selectCountryNameFromIP,
    setCountryInfo,
} from "../redux/authSlice";

export const useCountryInfoWithLocalStorage = (list, jurisdictionList) => {
    const [selectedCountry, setSelectedItem] = useState("");

    const [selectedCountryCode, setSelectedCountryCode] = useState("");

    const [toggleLogin, setToggleLogin] = useState(false);

    const countryName = useSelector(selectCountryNameFromIP);
    const countryCode = useSelector(selectCountryCodeFromIP);

    const dispatch = useDispatch();

    const [didDispatch, setDidDispatch] = useState(false);

    const [jurisdictionName, setJurisdictionName] = useState("");

    useEffect(() => {
        if (localStorage.getItem("country") === null) {
            if (!didDispatch) {
                dispatch(getCoutryFromIPAddress());
                setDidDispatch(true);
            }

            getJurisdictionName(countryName);
            setSelectedItem(countryCode);
            setSelectedCountryCode(countryCode);
        } else {
            const country = localStorage.getItem("country");
            setSelectedItem(country);
            const countryCode = localStorage.getItem("code");
            setSelectedCountryCode(countryCode);
            getJurisdictionName(country);
        }
    }, [didDispatch, dispatch, countryName, countryCode, jurisdictionName, list]);

    const handleSelect = (countryName, countryCode) => {
        setSelectedItem(countryName);
        setSelectedCountryCode(countryCode);

        dispatch(setCountryInfo({ name: countryName, code: countryCode }));

        setToggleLogin(false);
        getJurisdictionName(countryName);
    };

    const getJurisdictionName = (selectedCountryName) => {
        let countryListArr = [...list];
        const filterIndex = countryListArr.findIndex((e) => e.name === selectedCountryName);
        if (filterIndex !== -1) {
            const parentId = list[filterIndex].parentId;
            if (jurisdictionList) {
                const jurisdictionIndex = jurisdictionList.findIndex((e) => e.id === +parentId);
                setJurisdictionName(jurisdictionList[jurisdictionIndex].name);
            }
        }
    };

    return {
        selectedCountry,
        selectedCountryCode,
        handleSelect,
        toggleLogin,
        setToggleLogin,
        jurisdictionName,
    };
};
