import React, { useEffect, useState } from "react";
import "./UpcomingEvents.css";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { useSelector } from "react-redux";
import moment from "moment";
import parse from "html-react-parser";
import { Button } from "../Button";
import { useOpenLink } from "../../utils/useOpenLink";
import Loader from "../Loader/loader";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../utils/useTranslation";

function UpcomingEvents({
    contents: contentsArray = [],
    heading,
    action_button_name,
    action_button_link,
}) {
    const content = useSelector((state) => state.layout.content);

    const [contentReferenceData, setContentReferenceData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const openLink = useOpenLink();

    const navigate = useNavigate();

    useEffect(() => {
        const fetchDefaultData = () => {
            setIsLoading(true);
            const resp = (content[contentsArray[0]?.contentKey] || [])
                .map((k) => content[k])
                .slice(0, 3);

            if (resp.length) {
                setContentReferenceData(resp);
            }

            setIsLoading(false);
        };
        fetchDefaultData();
    }, []);

    const { t } = useTranslation();

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="eventWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>{heading}</h2>
                        </div>
                        <div className="col-12">
                            {contentReferenceData.map((value, index) => (
                                <div className="eventBoxMain" key={index}>
                                    <div className="row no-gutters">
                                        <div className="col-lg-3 col-md-4 col-sm-5 col-5">
                                            <div className="eventImgMain">
                                                <ImageWithObjectFit
                                                    className="cursor-pointer"
                                                    src={
                                                        value.image.src
                                                            ? value.image.src
                                                            : "assets/images/event-default.png"
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-9 col-md-8 col-sm-7 col-7">
                                            <div className="eventTxtMain">
                                                <div className="cateTxt">{value.tags}</div>
                                                <div className="title underline">{value.title}</div>
                                                <div className="decTxt">
                                                    {value.description && parse(value.description)}
                                                </div>
                                                <div className="dateLocaWrapper">
                                                    <div className="dateLocaMain">
                                                        <div className="dateTime">
                                                            <i className="fa fa-calendar"></i>
                                                            {moment(value.start).format(
                                                                "ddd DD MMMM [•] h:mm A",
                                                            )}
                                                        </div>
                                                        {value.venue !== "" && (
                                                            <div className="location">
                                                                <i className="fa fa-map-marker"></i>
                                                                {value.venue}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="regimain">
                                                        <Button
                                                            className="RegisterBtn"
                                                            onClick={() => openLink(value.register)}
                                                        >
                                                            {t("btnText.RegisterNow")}
                                                            <i className="fa fa-angle-right ms-2"></i>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {action_button_link !== "" && (
                            <div className="col-12 d-flex justify-content-center">
                                <Button
                                    onClick={() => navigate(action_button_link)}
                                    className="morebtn"
                                >
                                    {action_button_name}
                                    <i
                                        className="fa fa-long-arrow-right mt-1"
                                        aria-hidden="true"
                                    ></i>
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpcomingEvents;
