import React, { useEffect, useState } from "react";
import { useRefinementList } from "react-instantsearch";
import { Button } from "../Button";
import { MultiSelect } from "primereact/multiselect";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslation from "../../utils/useTranslation";

const GenericAuthorFilter = ({
    attribute,
    placeholder,
    clearFilters,
    setSelectedCBValues,
    passQueryParams,
}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const {
        items: types,
        refine,
        toggleShowMore,
        canToggleShowMore,
        isShowingMore,
    } = useRefinementList({
        attribute,
        sortBy: ["count"],
        limit: 10,
        showMore: true,
    });

    useEffect(() => {
        if (clearFilters && passQueryParams === null) {
            setSelectedItems([]);
        }
    }, [clearFilters, passQueryParams]);

    useEffect(() => {
        if (passQueryParams) {
            const tagsParams = passQueryParams.split("||").filter((tag) => tag.trim() !== "");
            setSelectedItems(tagsParams);
        }
    }, [passQueryParams]);

    useEffect(() => {
        if (selectedItems.length > 0 && types.length > 0 && !clearFilters) {
            selectedItems.forEach((tag) => {
                const type = types.find((item) => item.label === tag);
                if (type && !type.isRefined) {
                    refine(type.value);
                }
            });
        }
    }, [selectedItems, types, refine, clearFilters]);

    useEffect(() => {
        setSelectedCBValues(attribute, selectedItems);
        updateQueryParams();
    }, [selectedItems]);

    const handleFilterChange = (e) => {
        const selected = e.value || [];
        setSelectedItems(selected);

        types.forEach((item) => {
            if (selected.includes(item.label)) {
                if (!item.isRefined) {
                    refine(item.value);
                }
            } else {
                if (item.isRefined) {
                    refine(item.value);
                }
            }
        });
    };

    const updateQueryParams = () => {
        const queryParams = new URLSearchParams(window.location.search);
        if (selectedItems.length > 0) {
            queryParams.set(attribute, selectedItems.join("||"));
        } else {
            queryParams.delete(attribute);
        }
        navigate(`/${pathname.slice(1)}?${queryParams.toString()}`);
    };

    const itemTemplate = (option) => (
        <div className="d-flex count-pill align-items-center genericfilters">
            <div className="name">{option.label}</div>
            <div className="count">{option.count}</div>
        </div>
    );

    const panelFooterTemplate = () => {
        const length = selectedItems.length;
        return (
            <div className="py-2 px-3 d-flex genericfilters">
                <span className="me-1">
                    <b>{length}</b>
                </span>
                {length > 1 ? t("constantString.items") : t("constantString.item")}{" "}
                {t("constantString.selected")}
                {types.length >= 10 && (
                    <Button
                        className="more-button"
                        onClick={toggleShowMore}
                        disabled={!canToggleShowMore}
                    >
                        {isShowingMore ? t("btnText.ShowLess") : t("btnText.ShowMore")}
                    </Button>
                )}
            </div>
        );
    };

    return (
        <>
            {types.length > 0 && (
                <MultiSelect
                    value={selectedItems}
                    options={types}
                    onChange={handleFilterChange}
                    optionLabel="label"
                    placeholder={placeholder}
                    itemTemplate={itemTemplate}
                    panelFooterTemplate={panelFooterTemplate}
                    className="w-full md:w-20rem"
                    display="comma"
                    filter
                />
            )}
        </>
    );
};

export default GenericAuthorFilter;
