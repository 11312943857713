import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { INSTANT_SEARCH_POPULARITY_API_LINK } from "../constants/appLinks";

const SLICE_NAME = "instantSearch";

export const popularity = createAsyncThunk(`${SLICE_NAME}/popularity`, async (doc_id) => {
    const axiosInstance1 = axios.create({
        headers: {
            "content-type": "application/json",
            "X-TYPESENSE-API-KEY": "xyz",
        },
    });

    const req = {
        type: "click",
        name: "article_click_event",
        data: {
            q: "",
            doc_id,
            user_id: "",
        },
    };

    try {
        const response = await axiosInstance1.post(`${INSTANT_SEARCH_POPULARITY_API_LINK}`, req);
        return response.data;
    } catch (err) {
        console.error(err);
    }
});

const initialState = {};

export const instantSearchSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        // Example reducer:
        // fetchContentAndLayout: (state) => {
        //   const contentKeysMappedToLowercase = {}
        //   Object.keys(contentFromII.content).forEach(
        //     (k) => (contentKeysMappedToLowercase[k.toLowerCase()] = contentFromII.content[k]),
        //   )
        //   state.content = contentKeysMappedToLowercase
        //   state.layout = layoutFromII.layout
        // },
    },
});

export default instantSearchSlice.reducer;
