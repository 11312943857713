import React from "react";
import "./InfoPanelTopAlign.css";
import parse from "html-react-parser";
import { useOpenLink } from "../../utils/useOpenLink";

function InfoPanelTopAlign({ body, section_image, colors: colorObj, block_buttons }) {
    const openLink = useOpenLink();

    return (
        <>
            <div className="container py-50">
                <div className="row">
                    <div className="col-12">
                        <div
                            className="InfoPanelBox"
                            style={{ "--accent_color2": colorObj?.accent_color2 }}
                        >
                            <div className="InfologoMain">
                                <img src={section_image?.src} alt=" "></img>
                            </div>
                            <div className="contMain">
                                <p style={{ "--accent_color1": colorObj?.accent_color1 }}>
                                    {body && parse(body)}
                                </p>
                                {block_buttons?.length > 0 &&
                                    block_buttons.map((v) => (
                                        <span
                                            className="infoLink cursor-pointer"
                                            key={v.label}
                                            style={{ "--primary_color": colorObj?.primary_color }}
                                            onClick={() => openLink(v.action)}
                                        >
                                            {v.label} <i className="bi bi-arrow-right"></i>
                                        </span>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InfoPanelTopAlign;
