import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkLanguageInfo } from "../redux/authSlice";

function useLoadLanguage() {
    const dispatch = useDispatch();

    const { languageName } = useSelector((state) => state.auth);

    const { direction } = useSelector((state) => state.auth);

    useEffect(() => {
        if (languageName === null) dispatch(checkLanguageInfo());
    }, []);

    useEffect(() => {
        document.documentElement.lang = languageName;
        document.documentElement.dir = direction;
        localStorage.setItem("selectedLanguage", languageName);
        localStorage.setItem("selectedDirection", direction);
    }, [languageName, direction]);
}

export default useLoadLanguage;
