import { Link } from "react-router-dom";
import moment from "moment";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { decodeHtmlEntities } from "../../utils/urlUtils";
import { useGetLink } from "../../utils/useOpenLink";
import { getTextToDisplay } from "../../utils/getTextToDisplay";

export const Card = ({ content, colorsObj }) => {
    const {
        image,
        title: Title,
        date,
        microsite,
        link,
        posterImage,
        path,
        model_type,
        themes,
    } = content;

    const { getLink } = useGetLink();
    const textToDisplay = getTextToDisplay(model_type, themes);

    return (
        <div className="newsCard card">
            <Link to={getLink(path)}>
                <div className="cardImg">
                    <ImageWithObjectFit
                        className="cursor-pointer"
                        src={link === "" || link === null ? image?.src : posterImage}
                    />
                </div>
            </Link>
            <div className="card-body d-flex flex-column">
                <div className="tag-heading" style={{ color: colorsObj?.primary_color }}>
                    {microsite !== "" ? decodeHtmlEntities(microsite) : <>&nbsp;</>}
                </div>
                <Link to={getLink(path)}>
                    <h3
                        className="hover:underline cursor-pointer"
                        style={{ color: colorsObj?.accent_color1 }}
                    >
                        {Title}
                    </h3>
                </Link>
                <div className="d-flex mt-auto">
                    {textToDisplay && (
                        <div className="author me-1" style={{ color: colorsObj?.accent_color1 }}>
                            {textToDisplay}
                        </div>
                    )}
                    <div className="date" style={{ color: colorsObj?.accent_color3 }}>
                        {textToDisplay && date ? " | " : ""}
                        {moment(date).fromNow()}
                    </div>
                </div>
            </div>
        </div>
    );
};
