import React from "react";
import "./PageNotFound2.css";
import { useOpenLink } from "../../utils/useOpenLink";
import { useDispatch } from "react-redux";
import { setOpenSearchModal } from "../../redux/authSlice";
import useTranslation from "../../utils/useTranslation";
import parse from "html-react-parser";

function PageNotFound2({ section_image, heading, body, block_buttons, colors: colorObj }) {
    const openLink = useOpenLink();

    const { t } = useTranslation();

    const dispatch = useDispatch();

    return (
        <>
            <section className="pagenot-wrapper">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8 col-md-8 col-sm-10 col-10">
                            <div className="pagenot-hldr">
                                <div
                                    className="numberTxt"
                                    style={{
                                        "--accent_color3": colorObj?.accent_color3,
                                    }}
                                >
                                    <img src={section_image?.src} />
                                </div>
                                <div className="notFound-txt">
                                    <h2 style={{ color: colorObj?.accent_color1 }}>{heading}</h2>
                                    <p style={{ color: colorObj?.accent_color1 }}>
                                        {body && parse(body)}
                                    </p>
                                    <div className="searchMain">
                                        <p style={{ color: colorObj?.accent_color1 }}>
                                            {t("placeHolder.trySearch")}
                                        </p>
                                        <div className="searchHldr">
                                            <i
                                                className="fa fa-search"
                                                style={{ color: colorObj?.primary_color }}
                                            ></i>
                                            <input
                                                placeholder={t("constantString.enterKeyWord")}
                                                onClick={() => dispatch(setOpenSearchModal(true))}
                                            />
                                        </div>
                                        {block_buttons.map((v) => (
                                            <span
                                                className="btn"
                                                style={{
                                                    color: colorObj?.accent_color4,
                                                    background: colorObj?.primary_color,
                                                }}
                                                onClick={() => openLink(v.action)}
                                                key={v.label}
                                            >
                                                {v.label}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PageNotFound2;
