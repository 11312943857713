import React from "react";
import FacebookSvg from "../../assets/FacebookSvg";
import TwitterSvg from "../../assets/TwitterSvg";
import InstagramSvg from "../../assets/InstagramSvg";
import YoutubeSvg from "../../assets/YoutubeSvg";
import useTranslation from "../../utils/useTranslation";

function SocialLinks() {
    const { t } = useTranslation();

    return (
        <div className="col-12">
            <div className="SocialLinkMain">
                <h6>{t("footerText.aboutTxt")}</h6>
                <ul>
                    <li>
                        <FacebookSvg />
                    </li>
                    <li>
                        <TwitterSvg />
                    </li>
                    <li>
                        <InstagramSvg />
                    </li>
                    <li>
                        <YoutubeSvg />
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default SocialLinks;
