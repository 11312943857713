export const dirString = {
    ltr: "ltr",
    rtl: "rtl",
};

export const languageString = {
    en: "en",
    ar: "ar",
};

export const ratioString = {
    1.5: "1.5",
    1.3: "1.3",
};

export const instantSearchString = {
    author: "author",
    microsite: "microsite",
    type: "type",
};

export const blockSourceString = {
    block_details: "block_details",
    content_details: "content_details",
    article_details: "article_details",
};

export const allowedExtensionsString = {
    docx: "docx",
    doc: "doc",
    pdf: "pdf",
};
