export const enableDragScroll = (element) => {
    let isDragging = false;
    let startX;
    let scrollStart;

    element.addEventListener("mousedown", (e) => {
        isDragging = true;
        startX = e.pageX;
        scrollStart = element.scrollLeft;
    });

    element.addEventListener("mousemove", (e) => {
        if (!isDragging) return;
        const dx = e.pageX - startX;
        element.scrollLeft = scrollStart - dx;
    });

    element.addEventListener("mouseup", () => {
        isDragging = false;
    });

    element.addEventListener("mouseleave", () => {
        isDragging = false;
    });
};
