import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DIRECTORY_API_LINK } from "../constants/appLinks";
import { axiosInstance } from "../utils/networkRequests";

const SLICE_NAME = "directory";

const INITIAL_REQ_OBJ = {
    type: "committees",
    lastSync: "",
};

export const fetchDirectoryData = createAsyncThunk(`${SLICE_NAME}/fetchDirectoryData`, async () => {
    try {
        const response = await axiosInstance.post(DIRECTORY_API_LINK, INITIAL_REQ_OBJ);
        return response.data;
    } catch (err) {
        console.error(err);
    }
});

export const fetchDirectoryById = createAsyncThunk(
    `${SLICE_NAME}/fetchDirectoryById`,
    async ({ apiLink, id }) => {
        try {
            const req = {
                type: "directory",
                id,
                token: "123456",
                lastSync: "",
            };

            const response = await axiosInstance.post(apiLink, req);
            return response.data;
        } catch (err) {
            console.error(err);
        }
    },
);

export const directoryDataUsingSearch = createAsyncThunk(
    `${SLICE_NAME}/directoryDataUsingSearch`,
    async (search) => {
        try {
            const req = {
                type: "committees_search",
                token: "123456",
                lastSync: "",
                search,
            };

            const response = await axiosInstance.post(DIRECTORY_API_LINK, req);
            return response.data;
        } catch (err) {
            console.error(err);
        }
    },
);

export const APISearchDirectory = async (search) => {
    const req = {
        type: "committees_search",
        token: "123456",
        lastSync: "",
        search,
    };
    return await axiosInstance.post(
        `https://www.iiuk.org/Pages_Scripts/webservices/globalapp_V3.php`,
        req,
    );
};

const initialState = {
    contents: {
        institutions: [],
        committees: [],
        jamatkhana: [],
    },
    categoryArr: [],
    isLoading: true,
    activeDirectory: {},
    roleArr: [],
    searchDirectoryData: {},
    searchContentData: [],
    mainContents: [],
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const directorySlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        // Example reducer:
        // fetchContentAndLayout: (state) => {
        //   const contentKeysMappedToLowercase = {}
        //   Object.keys(contentFromII.content).forEach(
        //     (k) => (contentKeysMappedToLowercase[k.toLowerCase()] = contentFromII.content[k]),
        //   )
        //   state.content = contentKeysMappedToLowercase
        //   state.layout = layoutFromII.layout
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDirectoryData.fulfilled, (state, action) => {
                const data = action.payload;
                state.content = data;
                const newContents = { ...state.contents };

                let categoryArr = [];

                if (data) {
                    data.content.forEach((c) => {
                        const { category, ...rest } = c;
                        const categoryLowerCase = category.toLowerCase();

                        const temp = [...newContents[categoryLowerCase], rest];
                        newContents[categoryLowerCase] = temp;

                        const index = categoryArr.findIndex((e) => e === category);
                        if (index === -1) {
                            categoryArr.push(category);
                        }
                    });
                }

                if (data) {
                    state.mainContents = data.content;
                }
                state.contents = newContents;
                state.categoryArr = categoryArr;
                state.isLoading = false;
            })
            .addCase(fetchDirectoryById.fulfilled, (state, action) => {
                const data = action.payload;
                state.activeDirectory = data;
                let roleArr = [];
                let roleDropdownArr = [];
                data.content.forEach((c) => {
                    const { role } = c;
                    const newFirstElement = "All";
                    roleArr.push(role);
                    roleDropdownArr = [newFirstElement].concat(roleArr);
                });
                state.roleArr = roleDropdownArr;
            })
            .addCase(directoryDataUsingSearch.fulfilled, (state, action) => {
                const data = action.payload;
                state.searchDirectoryData = data;

                let searchContentData = [];

                if (data.content && data.response === 1) {
                    data.content.forEach((c) => {
                        const data = c;
                        searchContentData = searchContentData.concat(data);
                    });
                } else {
                    searchContentData = [];
                }

                state.searchContentData = searchContentData;

                let roleDropdownArr = [];
                let roleArr = [];

                for (const iterator of searchContentData) {
                    for (const i of iterator.people) {
                        if (i.role !== "") {
                            const index = roleArr.findIndex((e) => e === i.role);

                            if (index === -1) {
                                const newFirstElement = "All";
                                roleArr.push(i.role);
                                roleDropdownArr = [newFirstElement].concat(roleArr);
                            }
                        }
                    }
                }
                state.roleArr = roleDropdownArr;
            })
            .addMatcher(
                (action) =>
                    action.type.startsWith(`${SLICE_NAME}/fetchDirectoryData`) &&
                    action.type.endsWith("pending"),
                (state) => {
                    state.isLoading = true;
                },
            )
            .addMatcher(
                (action) =>
                    action.type.startsWith(`${SLICE_NAME}/fetchDirectoryData`) &&
                    action.type.endsWith("fulfilled"),
                (state) => {
                    state.isLoading = false;
                },
            )
            .addMatcher(
                (action) =>
                    action.type.startsWith(`${SLICE_NAME}/fetchDirectoryData`) &&
                    action.type.endsWith("rejected"),
                (state) => {
                    state.isLoading = false;
                },
            );
    },
});

// Action creators are generated for each case reducer function
// export const { fetchContentAndLayout } = layoutSlice.actions

export default directorySlice.reducer;
