import React, { useCallback, useEffect, useState } from "react";
import "./TextList.css";
import { useSelector } from "react-redux";
import moment from "moment";
import { decodeHtmlEntities } from "../../utils/urlUtils";
import { useOpenLink } from "../../utils/useOpenLink";
import { articleType } from "../../utils/articleHelpers";

function TextList({ heading, contents: contentToRender = [], colors: colorObj }) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const openLink = useOpenLink();

    const setCurrentContent = useCallback(
        (contentKey) => {
            const respKeys = content[contentKey];
            const resp = respKeys.map((k) => content[k]).slice(0, 3);

            if (resp.length) {
                setContentReferenceData(resp);
            }
        },
        [content],
    );

    useEffect(() => {
        setCurrentContent(contentToRender[0].contentKey);
    }, []);

    const handleClick = (path, nid) => {
        openLink(path, articleType, nid);
    };

    return (
        <>
            <section className="txtLinkWrapper py-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <h2 style={{ "--accent_color1": colorObj?.accent_color1 }}>
                                {heading}
                            </h2>
                            <div
                                className="line"
                                style={{ "--primary_color": colorObj?.primary_color }}
                            ></div>
                        </div>
                        <div className="col-12">
                            {contentReferenceData.map((card) => (
                                <div
                                    className="txtLinkHldr"
                                    key={card.title}
                                    style={{
                                        "--accent_color2": colorObj?.accent_color2,
                                    }}
                                >
                                    <h3 style={{ "--primary_color": colorObj?.primary_color }}>
                                        {card.microsite !== "" ? (
                                            decodeHtmlEntities(card.microsite)
                                        ) : (
                                            <>&nbsp;</>
                                        )}
                                    </h3>
                                    <h2
                                        className="underline"
                                        style={{ "--accent_color1": colorObj?.accent_color1 }}
                                        onClick={() => handleClick(card.path, card.nid)}
                                    >
                                        {card.title}
                                    </h2>
                                    <div
                                        className="minut"
                                        style={{
                                            "--accent_color3": colorObj?.accent_color3,
                                            "--accent_color1": colorObj?.accent_color1,
                                        }}
                                    >
                                        {card.tags?.split(",")[0]}{" "}
                                        <span>| {moment(card.date).fromNow()}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TextList;
