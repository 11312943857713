import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchFromApi, fetchPages } from "../../redux/layoutSlice";
import layoutTypeLookup from "./layoutTypes";
import { useLocation } from "react-router-dom";
import { useSetTitle, convertStringToKabobCase, checkValidURL } from "../../utils/urlUtils";
import { hash } from "../../utils/hashFunctions";
import { INITIAL_PAGE_NAME } from "../../constants/appLinks";
import { useCheckOauthCode } from "../../utils/useCheckOauthCode";
import { checkCountryInfo, checkLanguageInfo } from "../../redux/authSlice";
import Loader from "../../components/Loader/loader";
import { useCountryLanguageExtrator } from "../../utils/useCountryLanguageExtrator";

const ComponentWrapper = ({ contentReference, contents = [], layoutType, ...rest }) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [didDispatch, setDidDispatch] = useState([]);

    const Component = layoutTypeLookup[layoutType] || null;
    const content = useSelector((state) => state.layout.content);

    const apiLinkKeyMap = {};
    contents
        .filter(({ type, content_reference }) => {
            return (
                type !== "link" &&
                type !== "link_new" &&
                type !== "page" &&
                type !== null &&
                checkValidURL(content_reference)
            );
        })
        .forEach((c) => {
            if (c.content_reference) apiLinkKeyMap[c.content_reference] = hash(c.content_reference);
        });
    const keyArray = Object.keys(apiLinkKeyMap) || [];

    useEffect(() => {
        // make sure all content is loaded before rendering component
        const isContentFetched = keyArray.every((key) => content[apiLinkKeyMap[key]]);

        if (!isContentFetched) {
            keyArray
                .filter((k) => !didDispatch.includes(k))
                .forEach((link) => {
                    dispatch(fetchFromApi({ link }));
                    setDidDispatch((s) => [...s, link]);
                });
        } else {
            setIsLoading(false);
        }
    }, [dispatch, keyArray, didDispatch, content, apiLinkKeyMap, contentReference]);

    // need to get a unique key if contents change but component type does not.
    //  header is a special component that doesn't use "contents" (always [])
    //    header has all content split into two object, standard header and subheader
    const contentsToMap = layoutType === "header" ? rest.subHeader?.contents : contents;
    const key = contentsToMap.reduce((acc, cur) => `${acc}${cur?.id?.toString()}`, "");

    return !isLoading && !!Component ? (
        <Component
            key={key}
            content_reference={content[apiLinkKeyMap[contentReference]]}
            contents={contentsToMap.map(({ content_reference, type, ...rest }) => ({
                ...rest,
                type,
                contentKey:
                    type !== "link" &&
                    type !== "link_new" &&
                    type !== "page" &&
                    type !== null &&
                    checkValidURL(content_reference) &&
                    content_reference
                        ? hash(content_reference)
                        : content_reference,
            }))} // replace link with key for content array
            {...rest}
        />
    ) : (
        <Loader isLoading={isLoading} />
    );
};

const RenderPage = () => {
    const { pathname } = useLocation();

    const isLoading = useSelector((state) => state.auth.isLoading);

    const [countryCode, languageName, pageKey] = useCountryLanguageExtrator(pathname.slice(1));

    // check if this particular render is the first page load after a successful
    //  oauth login. If so, check the code and redirect the user back to where they
    //  were while trying to login
    // this only applies if url param has ?code=xxx&state=xxx
    const { codeCheckReady } = useCheckOauthCode();

    const dispatch = useDispatch();

    useEffect(() => {
        const _fetchPages = () => {
            if (codeCheckReady && countryCode !== null && languageName !== null) {
                dispatch(
                    fetchPages({
                        pageKey,
                        country: countryCode,
                        lang: languageName,
                    }),
                );
            }
        };

        _fetchPages();
    }, [dispatch, pageKey, countryCode, codeCheckReady, languageName]);

    useEffect(() => {
        if (countryCode === null) dispatch(checkCountryInfo());
    }, [countryCode]);

    useEffect(() => {
        if (languageName === null) dispatch(checkLanguageInfo());
    }, [languageName]);

    const pages = useSelector((state) => state.layout.pages);
    const pageToRender = pages?.[convertStringToKabobCase(pageKey || INITIAL_PAGE_NAME)] || {};

    useSetTitle(pageToRender);

    if (!codeCheckReady) return null;

    if (Object.keys(pages).length && !pageToRender) return <h1>Not found</h1>;
    return (
        <div key={`${pageKey}-${countryCode}-${languageName}`}>
            {(pageToRender?.section || []).map((section) => {
                // check content_reference and fetch from API if needed.
                const { contents, layout_type: layoutType, ...rest } = section;
                return (
                    <div key={`${layoutType}-${section.id}-${countryCode}-${languageName}`}>
                        <Loader isLoading={isLoading} />

                        <ComponentWrapper contents={contents} layoutType={layoutType} {...rest} />
                    </div>
                );
            })}
        </div>
    );
};

export default RenderPage;
