import React, { useEffect, useState } from "react";
import "./HeroFocus.css";
import { useSelector } from "react-redux";
import Loader from "../Loader/loader";
import parse from "html-react-parser";
import { useNavigate } from "react-router";
import ImageWithObjectFit from "../ImageWrapperComponent";
import useTranslation from "../../utils/useTranslation";
import * as posterImage from "../../utils/usePosterImage";

function HeroFocus({ contents: contentsArray = [] }) {
    const navigate = useNavigate();

    const content = useSelector((state) => state.layout.content);

    const [contentsData, setContentsData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchDefaultData = async () => {
            setIsLoading(true);
            let mainContent = content[contentsArray[0].contentKey]
                .map((k) => content[k])
                .slice(0, 1);
            mainContent = JSON.parse(JSON.stringify(mainContent));
            for await (const element of mainContent) {
                if (element && element.link) {
                    element.posterImage = await posterImage.usePosterImage(
                        element?.link,
                        element?.image,
                    );
                }
            }
            if (mainContent.length) setContentsData(mainContent);
            setIsLoading(false);
        };
        fetchDefaultData();
    }, []);

    const handleClick = (nid) => navigate(`/articles/${nid}`);

    const { t } = useTranslation();

    return (
        <>
            <Loader isLoading={isLoading} />

            <section className="GlimpseSecation py-50">
                <div className="container-fuild">
                    {contentsData.map((value) => (
                        <div className="row" key={value.title}>
                            <div className="col-lg-8 col-md-8 col-12">
                                <div className="videoImg">
                                    <ImageWithObjectFit
                                        className="cursor-pointer"
                                        src={
                                            value?.link === "" || value?.link === null
                                                ? value?.image?.src
                                                : value?.posterImage
                                        }
                                        onClick={() => handleClick(value.nid)}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="textMain">
                                    <h3
                                        className="underline"
                                        onClick={() => handleClick(value.nid)}
                                    >
                                        {value.title}
                                    </h3>
                                    <p>{parse(value.summary)}</p>
                                    <div
                                        className="btn btn-link"
                                        onClick={() => handleClick(value.nid)}
                                    >
                                        {t("btnText.learnMore")}
                                        <i
                                            className="fa fa-long-arrow-right ms-2"
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
}

export default HeroFocus;
