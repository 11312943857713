import React from "react";
import { useOpenLink } from "../../utils/useOpenLink";
import ImageWithObjectFit from "../ImageWrapperComponent";

function ImageOnlyCard({ content }) {
    const { content_image, contentKey, type } = content;

    const openLink = useOpenLink();

    return (
        <>
            <div className="card">
                <div
                    className="cardImg cursor-pointer underline"
                    onClick={() => openLink(contentKey, type)}
                >
                    <ImageWithObjectFit className="cursor-pointer" src={content_image?.src} />
                </div>
            </div>
        </>
    );
}

export default ImageOnlyCard;
