import React from "react";
import "./HeroStatic3.css";

function HeroStatic3({ contents: contentToRender = [], section_image }) {
    return (
        <>
            <div className="row mb-15">
                <div className="col-12">
                    <div className="HeroStaticWrapper2">
                        <img src={contentToRender[0].content_image?.src}></img>
                        <div className="contMain container">
                            <div className="logoHldr">
                                <img src={section_image?.src}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroStatic3;
