import { useSelector } from "react-redux";

export const useCountryLanguageExtrator = (link) => {
    const { countryCode, languageName, allCountries, allLanguages } = useSelector(
        (state) => state.auth,
    );

    const linkParts = (link.startsWith("/") ? link.slice(1) : link).split("/");

    if (
        linkParts[0].length === 2 &&
        linkParts[1].length === 2 &&
        allCountries.includes(linkParts[0]) &&
        allLanguages.includes(linkParts[1])
    )
        return [linkParts[0], linkParts[1], linkParts.slice(2).join("/")];
    return [countryCode, languageName, link];
};
