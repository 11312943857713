import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./BreadCrumbs.css";
import HomeSvg from "../../assets/HomeSvg";
import { useTranslation } from "react-i18next";

function BreadCrumbs({ model_type, themes }) {
    const navigate = useNavigate();
    const [breadcrumbName, setBreadcrumbName] = useState("");
    const [showWhatsNew, setShowWhatsNew] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        let name = "";
        if (model_type && themes) {
            if (model_type.toLowerCase() === "news") {
                const theme = themes && JSON.parse(themes);
                name = theme.find((t) => t.theme_type === "Section")?.theme || "";
                setShowWhatsNew(true);
            } else if (model_type.toLowerCase() === "speech") {
                name = "Speeches";
                setShowWhatsNew(true);
            } else {
                name = model_type;
                setShowWhatsNew(false);
            }
        }
        setBreadcrumbName(name);
    }, [model_type, themes]);

    return (
        <nav>
            <ol className="breadcrumb">
                <li className="breadcrumb-item cursor-pointer margin-top">
                    <span onClick={() => navigate("/")}>{<HomeSvg />}</span>
                </li>
                <div className="icon-fa-fa">
                    <i className="fa fa-angle-right"></i>
                </div>
                {showWhatsNew && (
                    <>
                        <li className="breadcrumb-item">{t("constantString.breadcrumbString")}</li>
                        <div className="icon-fa-fa">
                            <i className="fa fa-angle-right"></i>
                        </div>
                    </>
                )}
                <li className="breadcrumb-item">{breadcrumbName}</li>
            </ol>
        </nav>
    );
}

export default BreadCrumbs;
