import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./translations/en.json";
import translationAR from "./translations/ar.json";
import { dirString, languageString } from "./utils/string";

const resources = {
    en: {
        translation: translationEN,
        displayName: "English",
        dir: dirString.ltr,
    },
    ar: {
        translation: translationAR,
        displayName: "عربي",
        dir: dirString.rtl,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: languageString.en,
    fallbackLng: languageString.en,
});

export default i18n;
