import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { APP_COLORS } from "../../constants/styles";
import SubMenuStyle1 from "./SubMenuStyle1";
import SubMenuStyle2 from "./SubMenuStyle2";
import SubMenuStyle3 from "./SubMenuStyle3";
import SubMenuStyle4 from "./SubMenuStyle4";
import scroll from "../Header/scroll";

function MegaMenu({ subMenuHeader, style }) {
    const timeoutOnMouseEnter = useRef(null);

    const [activeSubMenuId, setActiveSubMenuId] = useState(null);

    const [subMenuContent, setSubMenuContent] = useState(null);

    const sections = useSelector((state) => state.layout.sections);

    const [navBarVisible, setNavBarVisible] = useState(true);

    const scrollTop = scroll();

    useEffect(() => {
        if (scrollTop.y > 400 && scrollTop.y - scrollTop.lastY > 0 && activeSubMenuId == null) {
            setNavBarVisible(false);
        } else {
            setNavBarVisible(true);
        }
    }, [scrollTop.y, scrollTop.lastY]);

    useEffect(() => {
        const contentIds = subMenuHeader.map((value) => parseInt(value.content_reference));
        const subMenuContentItems = new Map(
            sections.filter((item) => contentIds.includes(item.id)).map((item) => [item.id, item]),
        );
        setSubMenuContent(subMenuContentItems);
    }, [subMenuHeader]);

    const renderSubMenu = (data) => {
        if (!data) {
            return null;
        }

        let innerMenu;
        switch (data.layout_type) {
            case "sub_menu_style1":
                innerMenu = <SubMenuStyle1 data={data} />;
                break;
            case "sub_menu_style2":
                innerMenu = <SubMenuStyle2 data={data} />;
                break;
            case "sub_menu_style3":
                innerMenu = <SubMenuStyle3 data={data} />;
                break;
            case "sub_menu_style4":
                innerMenu = <SubMenuStyle4 data={data} />;
                break;
            default:
                return null;
        }

        return (
            <div
                style={APP_COLORS(data.colors)}
                className={"absolute flex left-0 right-0 pt-3 justify-center container"}
            >
                <div
                    onMouseLeave={() => {
                        hideSubMenu();
                    }}
                    className="relative bg-white rounded-2xl overflow-clip flex flex-col md:flex-row z-20 animate-fadeInDown"
                >
                    {innerMenu}
                </div>
            </div>
        );
    };

    const hideSubMenu = () => {
        setActiveSubMenuId(null);
        clearTimeout(timeoutOnMouseEnter.current);
    };

    return (
        <div style={{ ...style }}>
            {activeSubMenuId && (
                <div className="hidden md:block bg-black fixed inset-0 opacity-70" />
            )}
            <nav
                onMouseLeave={() => {
                    clearTimeout(timeoutOnMouseEnter.current);
                }}
                className={clsx(
                    "hidden md:flex flex-row justify-center items-center transition-transform ease-linear duration-300 relative z-10 bg-primary_color",
                    !navBarVisible && "slide-up",
                )}
            >
                {subMenuHeader.map((value) => {
                    return (
                        <div className="relative" key={value.heading}>
                            <div
                                className="cursor-pointer text-accent_color4 px-4 py-[0.66rem] text-base hover:underline hover:text-accent_color4 z-20"
                                onClick={() => {
                                    if (activeSubMenuId != parseInt(value.content_reference)) {
                                        clearTimeout(timeoutOnMouseEnter.current);
                                        setActiveSubMenuId(parseInt(value.content_reference));
                                    } else {
                                        hideSubMenu();
                                    }
                                }}
                                onMouseEnter={() => {
                                    clearTimeout(timeoutOnMouseEnter.current);

                                    if (activeSubMenuId != parseInt(value.content_reference)) {
                                        hideSubMenu();
                                    }

                                    timeoutOnMouseEnter.current = setTimeout(() => {
                                        setActiveSubMenuId(parseInt(value.content_reference));
                                        clearTimeout(timeoutOnMouseEnter.current);
                                    }, 2000);
                                }}
                            >
                                {value.heading}
                            </div>
                            {activeSubMenuId == parseInt(value.content_reference) && (
                                <div className="absolute left-0 right-0 justify-center flex animate-fadeInDownBehind z-10">
                                    <img className="h-6" src="/assets/images/triangle.svg" />
                                </div>
                            )}
                        </div>
                    );
                })}
            </nav>

            {renderSubMenu(subMenuContent && subMenuContent.get(activeSubMenuId))}
        </div>
    );
}

export default MegaMenu;
