import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useOpenLink = () => {
    const navigate = useNavigate();

    const openLink = (link, type, nid) => {
        const finalUrl =
            (type === "link_new" || type === "link") && link.startsWith("http")
                ? link
                : type === "link_new" || type === "link"
                ? `https://${link}`
                : link;
        const destinationPath = link || `/${nid}`;
        switch (type?.toLowerCase()) {
            case "article":
                navigate(`${destinationPath}`);
                break;
            case "speech":
                navigate(`/speech`, { state: { id: link } });
                break;
            default:
                finalUrl.startsWith("http")
                    ? window.open(finalUrl, type === "link_new" ? "_blank" : "_self")
                    : navigate(finalUrl);
        }
    };

    return openLink;
};

export const useGetLink = () => {
    const { countryCode, languageName, allCountries, allLanguages } = useSelector(
        (state) => state.auth,
    );

    const getLink = (link = "") => {
        // 1. check if link already has a country and/or language
        // 1a. If yes, return link as is
        // 1b. If no, continue
        // 2. Add country code and language from redux
        if (link?.startsWith("http") || !link) {
            // link is an absolute link, no need to do anything
            return link;
        }
        const linkParts = link.split("/");

        if (
            linkParts[0].length === 2 &&
            linkParts[1].length === 2 &&
            allCountries.includes(linkParts[0]) &&
            allLanguages.includes(linkParts[1])
        ) {
            // link is "fully qualified" with country and language
            return link;
        }

        return `${countryCode.toLowerCase()}/${languageName.toLowerCase()}/${link}`;
    };
    return { getLink };
};
