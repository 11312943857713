import React from "react";
import "./IconLink.css";
import { useOpenLink } from "../../utils/useOpenLink";

function IconLinks({ heading, contents: contentsArray, colors: colorObj }) {
    const openLink = useOpenLink();

    return (
        <>
            <div
                className="importantLinksWrapper py-50"
                style={{ background: colorObj?.background_color }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="titleMain"
                                style={{ "--primary_color": colorObj?.primary_color }}
                            >
                                <h3>{heading}</h3>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-4">
                            {contentsArray.map((value) => (
                                <div
                                    className="col-lg-3 col-md-6 col-sm-6 col-12"
                                    key={value.heading}
                                >
                                    <div
                                        className="newsCard"
                                        style={{ "--background_color": colorObj?.background_color }}
                                    >
                                        <div className="cardImg">
                                            <img
                                                src={
                                                    value.content_image.src &&
                                                    value.content_image.src
                                                        ? value.content_image.src
                                                        : ""
                                                }
                                            />
                                        </div>
                                        <div
                                            className="text cursor-pointer"
                                            style={{ "--accent_color1": colorObj?.accent_color1 }}
                                            onClick={() => openLink(value.contentKey, value.type)}
                                        >
                                            {" "}
                                            {value.heading}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IconLinks;
