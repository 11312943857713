import React, { useEffect, useState, useContext, useRef } from "react";
import "../Header/Header.css";
import { WindowContext } from "../WindowResize/resize";
import { useDispatch, useSelector } from "react-redux";
import {
    openLoginDropdown,
    selectIsAuthed,
    selectUserInfo,
    setjurisdictionNameRedux,
    setOauthData,
} from "../../redux/authSlice";
import { handleLogout } from "../../utils/oauthHandler";
import { useCountryInfoWithLocalStorage } from "../../utils/getCountryInfoFunction";
import useCustomTranslation from "../CustomTranslation";
import { setShowDropDownPicker } from "../../redux/layoutSlice";
import LanguageSelector from "../LanguageSelection";
import { useClickOutside } from "../../utils/useClickOutside";

export const DropDownPicker = ({ list, onSelect, jurisdictionList, Isheader2 }) => {
    const isAuthed = useSelector(selectIsAuthed);

    const dispatch = useDispatch();

    const { idToken } = useSelector(selectUserInfo) || {};

    const { isDesktop } = useContext(WindowContext);

    const userInfo = useSelector(selectUserInfo);

    const [selectCountryName, setSelectCountryName] = useState("");

    const {
        selectedCountry,
        selectedCountryCode,
        handleSelect,
        toggleLogin,
        setToggleLogin,
        jurisdictionName,
    } = useCountryInfoWithLocalStorage(list, jurisdictionList);

    const [searchKeyword, setSearchKeyword] = useState("");

    const [jurisdiction, setjurisdiction] = useState("");

    const [isLogin, setIsLogin] = useState(-1);

    const dropdownRef = useRef(null);

    const [filteredItems, setFilteredItems] = useState([]);

    const previousCountryRef = useRef();

    const [toggleSelectLogin, setToggleSelectLogin] = useState(false);

    const openLoginOption = useSelector(openLoginDropdown);

    useEffect(() => {
        dispatch(setShowDropDownPicker(true));
    }, []);

    useEffect(() => {
        if (list.length > 0) {
            const filteredList = list.filter((o) =>
                Object.keys(o).some(() =>
                    o.country.toLowerCase().includes(searchKeyword.toLowerCase()),
                ),
            );
            setFilteredItems(filteredList);
        }
    }, [list, searchKeyword, jurisdictionName, selectedCountry]);

    const getselectedCountryObj = (countryName) => {
        const index = list.findIndex((e) => e.country === countryName);
        const selectedCoutryItem = list[index];
        onSelect(selectedCoutryItem);
    };

    const { t } = useCustomTranslation();

    useEffect(() => {
        if (openLoginOption) {
            setToggleSelectLogin(true);
        }
    }, [openLoginOption]);

    useClickOutside(dropdownRef, () => {
        setToggleLogin(false);
        setToggleSelectLogin(false);
    });

    const handleSelectCountry = (country) => {
        setSelectCountryName(country);
    };

    useEffect(() => {
        previousCountryRef.current = selectCountryName;
    }, [selectCountryName]);

    useEffect(() => {
        const selectedCountryData = list.find((c) => c.country === selectedCountry);
        setjurisdiction(selectedCountryData?.jurisdiction);
        setIsLogin(selectedCountryData?.login);
        dispatch(setjurisdictionNameRedux(selectedCountryData?.jurisdiction));
        dispatch(setOauthData(selectedCountryData?.oauthServers));
    }, [selectedCountry, list, dispatch]);

    useEffect(() => {
        const selectedCountryData = list.find((c) => c.country === selectCountryName);
        const storedJurisdiction = localStorage.getItem("jurisdiction");

        if (selectedCountryData) {
            const newJurisdiction = selectedCountryData.jurisdiction;
            if (storedJurisdiction !== newJurisdiction && isAuthed) {
                localStorage.setItem("jurisdiction", newJurisdiction);
                alert("You are being logged out. Please log in again.");
                setTimeout(() => {
                    handleLogout({ idToken });
                }, 1000);
            }
        }
    }, [list, selectCountryName]);

    return (
        <>
            {Isheader2 && isDesktop ? (
                <>
                    <span className="cursor-pointer" onClick={() => setToggleLogin((s) => !s)}>
                        <i className={`fi fi-${selectedCountryCode?.toLowerCase()} me-2`}></i>
                        {isDesktop ? selectedCountry : selectedCountryCode?.toLowerCase()} -{" "}
                    </span>
                    <LanguageSelector
                        selectedCountry={selectedCountry}
                        countriesData={list}
                        Isheader2={Isheader2}
                    />
                    {toggleLogin && (
                        <div className="searchMain" ref={dropdownRef}>
                            <div className="userMain">
                                <div className="contPopup">
                                    <div className="titleBox">
                                        <div className="d-flex">
                                            <h3 className="heading mb-2">
                                                Select country to login
                                            </h3>
                                            <i
                                                className="fa fa-times close-icon"
                                                style={{ display: isDesktop ? "none" : "block" }}
                                                onClick={() => setToggleLogin((s) => !s)}
                                                aria-hidden="true"
                                            ></i>
                                        </div>

                                        <div className="headingLine mb-2"></div>

                                        <form className="search-country">
                                            <input
                                                type="text"
                                                name="searchKeyword"
                                                placeholder={t("placeHolder.search")}
                                                title="Enter search keyword"
                                                value={searchKeyword}
                                                onChange={(e) => setSearchKeyword(e.target.value)}
                                            />
                                        </form>
                                    </div>

                                    <div className="contMain">
                                        <ul className="cursor-pointer">
                                            {filteredItems.map((item, i) => {
                                                return (
                                                    <li
                                                        key={i}
                                                        onClick={() => {
                                                            handleSelect(
                                                                item.country,
                                                                item.country_code,
                                                            );
                                                            handleSelectCountry(item.country);
                                                        }}
                                                    >
                                                        <div>
                                                            <label className="form-check-label country">
                                                                <span
                                                                    className={`fi fi-${item.country_code?.toLowerCase()} me-2`}
                                                                ></span>
                                                                {item.country}
                                                            </label>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <span className={`fi fi-${selectedCountryCode?.toLowerCase()} me-2`}></span>
                    <span
                        className="cursor-pointer"
                        id="ismailiCountryCode"
                        onClick={() => setToggleLogin((s) => !s)}
                    >
                        {isDesktop ? selectedCountry : selectedCountryCode?.toLowerCase()}

                        {isDesktop && <i className="fa fa-angle-down cursor-pointer ms-2"></i>}
                    </span>

                    {toggleLogin && (
                        <div className="contPopup" ref={dropdownRef}>
                            <div className="titleBox">
                                <div className="d-flex">
                                    <h3 className="heading mb-2">Select country to login</h3>
                                    <i
                                        className="fa fa-times close-icon"
                                        style={{ display: isDesktop ? "none" : "block" }}
                                        onClick={() => setToggleLogin((s) => !s)}
                                        aria-hidden="true"
                                    ></i>
                                </div>

                                <div className="headingLine mb-2"></div>

                                <form className="search-country">
                                    <input
                                        type="text"
                                        name="searchKeyword"
                                        placeholder={t("placeHolder.search")}
                                        title="Enter search keyword"
                                        value={searchKeyword}
                                        onChange={(e) => setSearchKeyword(e.target.value)}
                                    />
                                </form>
                            </div>

                            <div className="contMain">
                                <ul className="cursor-pointer">
                                    {filteredItems.map((item, i) => {
                                        return (
                                            <li
                                                key={i}
                                                onClick={() => {
                                                    handleSelect(item.country, item.country_code);
                                                    handleSelectCountry(item.country);
                                                }}
                                            >
                                                <div>
                                                    <label className="form-check-label country">
                                                        <span
                                                            className={`fi fi-${item.country_code?.toLowerCase()} me-2`}
                                                        ></span>
                                                        {item.country}
                                                    </label>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    )}
                    <LanguageSelector
                        selectedCountry={selectedCountry}
                        countriesData={list}
                        Isheader2={Isheader2}
                    />

                    {isLogin === 1 && (
                        <i
                            className="fa fa-user-o ms-3 cursor-pointer"
                            onClick={() => setToggleSelectLogin((s) => !s)}
                        ></i>
                    )}

                    {toggleSelectLogin && (
                        <div className="contPopup1" ref={dropdownRef}>
                            <div className="contMain1">
                                <ul>
                                    {!isAuthed ? (
                                        <li>
                                            <div
                                                onClick={() =>
                                                    getselectedCountryObj(selectedCountry)
                                                }
                                            >
                                                <label className="form-check-label cursor-pointer country pb-2 pt-2">
                                                    <i
                                                        className="fa fa-sign-in me-2"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {t("loginText")} {jurisdiction}
                                                </label>
                                            </div>
                                        </li>
                                    ) : (
                                        <>
                                            <li className="divider-line">{userInfo.name}</li>
                                            <li>
                                                <div onClick={() => handleLogout({ idToken })}>
                                                    <label className="form-check-label cursor-pointer country pb-2 pt-2">
                                                        <i
                                                            className="fa fa-sign-out me-2"
                                                            aria-hidden="true"
                                                        ></i>
                                                        {t("logoutText")}
                                                    </label>
                                                </div>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};
