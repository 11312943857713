import React from "react";
import { useStats } from "react-instantsearch";
import useTranslation from "../../utils/useTranslation";

function TotalRecords() {
    const { nbHits } = useStats();

    const { t } = useTranslation();

    return (
        <div className="total-records">
            {nbHits} {t("constantString.resultsString")}
        </div>
    );
}

export default TotalRecords;
