import React from "react";

function DesktopLinkGroup({ index, value, openLink }) {
    return (
        <div key={index}>
            <h6
                className="text-primary_color text-sm uppercase cursor-pointer mb-6"
                onClick={() => openLink(value.contentKey)}
            >
                {value.heading}
            </h6>

            <div className="w-14 h-[1px] bg-primary_color mb-6" />

            <ul className="flex flex-col flex-wrap max-h-48 gap-x-8">
                {value.contents &&
                    value.contents.map((v) => (
                        <li
                            key={v.heading}
                            className="cursor-pointer text-primary_color text-xs py-2 uppercase"
                            onClick={() => openLink(v.content_reference)}
                        >
                            {v.heading}
                        </li>
                    ))}
            </ul>
        </div>
    );
}

export default DesktopLinkGroup;
