import React from "react";
import { Helmet } from "react-helmet-async";

function MetaTags({ metaTags }) {
    return (
        <div>
            <Helmet>
                {metaTags.map((v, i) => (
                    <meta name={v.meta_tag} content={v.meta_contnet} key={i} />
                ))}
            </Helmet>
        </div>
    );
}

export default MetaTags;
