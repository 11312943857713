import React, { useEffect, useState } from "react";
import "./ProgrammeStdMenu.css";
import { useOpenLink } from "../../utils/useOpenLink";
import { enableDragScroll } from "../../utils/scrollLinks";

function ProgrammeStdMenu({ contents: contentsArray = [], colors: colorObj }) {
    const openLink = useOpenLink();

    const [activeIndex, setActiveIndex] = useState();

    const [collapsedIndexes, setCollapsedIndexes] = useState([]);

    useEffect(() => {
        const menu = document.querySelector(".desktop-std-menu1");
        enableDragScroll(menu);
    }, []);

    const handleClick = (index) => {
        setActiveIndex(index);
    };

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const handleToggleCollapse = (index) => {
        setCollapsedIndexes((prevState) =>
            prevState.includes(index)
                ? prevState.filter((i) => i !== index)
                : [...prevState, index],
        );
    };

    return (
        <section
            className="prog-link-wrapper my-30"
            style={{
                borderTop: `1px solid ${colorObj?.accent_color2}`,
                borderBottom: `1px solid ${colorObj?.accent_color2}`,
            }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="desktop-std-menu1">
                            {contentsArray &&
                                contentsArray.map((value, index) => (
                                    <span
                                        key={value.heading}
                                        className="nav-item cursor-pointer"
                                        onClick={handleClick}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <span
                                            className={activeIndex === index ? "active" : ""}
                                            onClick={() => openLink(value.contentKey, value.type)}
                                            style={{
                                                color: colorObj?.primary_color,
                                                "--link-hover-color": colorObj?.primary_color,
                                                "--primary_color": colorObj?.primary_color,
                                            }}
                                        >
                                            {value.heading}
                                            {value.contents?.length > 0 && (
                                                <i
                                                    className={`${
                                                        !value.isActive
                                                            ? "fa fa-angle-down ms-2"
                                                            : "fa fa-angle-down up-arrow"
                                                    }`}
                                                    data-bs-toggle="dropdown"
                                                    id="MainNav1"
                                                    aria-expanded="false"
                                                ></i>
                                            )}
                                        </span>

                                        {value.contents?.length > 0 && hoveredIndex === index && (
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="MainNav1"
                                                style={{
                                                    "--primary_color": colorObj?.primary_color,
                                                    "--accent_color2": colorObj?.accent_color2,
                                                }}
                                            >
                                                {value.contents.map((subValue, i) => (
                                                    <li key={i}>
                                                        <div
                                                            className="dropdown-item"
                                                            onClick={() =>
                                                                openLink(
                                                                    subValue.content_reference,
                                                                    subValue.type,
                                                                )
                                                            }
                                                        >
                                                            {subValue.heading}
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </span>
                                ))}
                        </div>

                        <div className="mobile-std-menu">
                            <div className="accordion navMainWrapper" id="navigationMain">
                                {contentsArray.map((value, index) => (
                                    <div className="accordion-item" key={index}>
                                        <h2 className="accordion-header" id={`${index}`}>
                                            <span
                                                className="accordion-button collapsed"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapse${index}`}
                                                aria-expanded={
                                                    collapsedIndexes.includes(index)
                                                        ? "true"
                                                        : "false"
                                                }
                                                aria-controls={`collapse${index}`}
                                                onClick={() => handleToggleCollapse(index)}
                                                style={{
                                                    color: colorObj?.primary_color,
                                                    "--primary_color": colorObj?.primary_color,
                                                    display:
                                                        value.contents?.length > 0
                                                            ? "flex"
                                                            : "block",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                {value.heading}
                                                {value.contents?.length > 0 && (
                                                    <i
                                                        className={`fa ${
                                                            collapsedIndexes.includes(index)
                                                                ? "fa-angle-up arrow-icon"
                                                                : "fa-angle-down arrow-icon"
                                                        } ms-2`}
                                                        style={{ color: colorObj?.primary_color }}
                                                    ></i>
                                                )}
                                            </span>
                                        </h2>
                                        {value.contents?.length > 0 && (
                                            <div
                                                id={`collapse${index}`}
                                                className={`accordion-collapse hide ${
                                                    collapsedIndexes.includes(index) ? "show" : ""
                                                }`}
                                                aria-labelledby={`collapse${index}`}
                                                data-bs-parent="#navigationMain"
                                            >
                                                <div className="accordion-body">
                                                    <ul>
                                                        {value.contents &&
                                                            value.contents.map((v) => (
                                                                <li key={v.heading}>
                                                                    <span
                                                                        className="cursor-pointer"
                                                                        onClick={() =>
                                                                            openLink(v.contentKey)
                                                                        }
                                                                        style={{
                                                                            color: colorObj?.accent_color3,
                                                                        }}
                                                                    >
                                                                        {v.heading}
                                                                    </span>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProgrammeStdMenu;
