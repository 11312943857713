import React from "react";
import ImageWithObjectFit from "../ImageWrapperComponent";
import moment from "moment";
import parse from "html-react-parser";
import { useNavigate } from "react-router";

function MediaFeaturedCard({ content }) {
    const navigate = useNavigate();

    const { image, title: Title, date, summary: ArticleSummary, tags: Tags, nid } = content;

    const tags = Tags.split(",")[0];

    const handleClick = () => {
        navigate(`/articles`, { state: { nid: nid } });
    };

    return (
        <div className="newsCard card">
            <div className="cardImg">
                <ImageWithObjectFit
                    className="cursor-pointer"
                    src={image?.src}
                    onClick={() => handleClick()}
                />
            </div>

            <div className="card-body d-flex flex-column">
                <div className="date">
                    {moment(date).format(`MMM DD${","} YYYY`)}
                    {tags && date ? " | " : ""}
                    {tags}
                </div>
                <h3 className="underline" onClick={() => handleClick()}>
                    {Title}
                </h3>
                <div className="textMain">{parse(ArticleSummary)}</div>
            </div>
        </div>
    );
}

export default MediaFeaturedCard;
