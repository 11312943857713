import React, { useEffect, useState } from "react";
import "./FeaturedCardCarousel2.css";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import FeaturedCard2 from "../Card/FeaturedCard2";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import { swiperCarousel } from "../../utils/carouselFunction";
import useCustomTranslation from "../CustomTranslation";
import * as posterImage from "../../utils/usePosterImage";

function FeaturedCardCarousel2({ contents: contentToRender = [], heading, background }) {
    const content = useSelector((state) => state.layout.content);

    const [featuredContentsData, setFeaturedContentsData] = useState([]);

    const [cardCarouselContentsData, setCardCarouselContentsData] = useState([]);

    const { dir } = useCustomTranslation();

    useEffect(() => {
        const fetchDefaultData = async () => {
            // Helper function to process content arrays
            const processContentArray = async (contentArray) => {
                const processedContent = JSON.parse(JSON.stringify(contentArray));
                for (const element of processedContent) {
                    if (element?.link) {
                        element.posterImage = await posterImage.usePosterImage(
                            element?.link,
                            element?.image,
                        );
                    }
                }
                return processedContent;
            };

            // Fetch and process featured content and card content
            const featuredContent = await processContentArray(
                content[contentToRender[0].contentKey].map((k) => content[k]).slice(0, 1),
            );
            const cardContent = await processContentArray(
                content[contentToRender[1].contentKey].map((k) => content[k]),
            );

            if (featuredContent.length) setFeaturedContentsData(featuredContent);
            if (cardContent.length) setCardCarouselContentsData(cardContent);
        };

        fetchDefaultData();
    }, []);

    return (
        <>
            <div className="ismailiTVWrapper" style={{ background: background }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="titleMain">
                                <h3>{heading}</h3>
                            </div>
                        </div>

                        <div className="col-12 mb-2">
                            {featuredContentsData.map((value) => (
                                <div className="newsCardBig" key={value.title}>
                                    <div className="row">
                                        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                            <div className="NewsImg">
                                                <ImageWithObjectFit
                                                    className="cursor-pointer"
                                                    src={value.image.src}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                            <div className="card-body">
                                                <h3 className="card-title">{value.title}</h3>
                                                <p className="card-text mt-3">
                                                    {parse(value.summary)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="col-12">
                            <Swiper dir={dir} key={dir} {...swiperCarousel}>
                                {cardCarouselContentsData.map((card) => (
                                    <SwiperSlide key={card.title}>
                                        <FeaturedCard2 content={card} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FeaturedCardCarousel2;
